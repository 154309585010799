import { Injectable } from '@angular/core';
import { AnalysisModel } from '../schemes/models/analysis.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class AnalysisProvider extends BaseProvider<AnalysisModel> {
	constructor(http: HttpService) {
		super('analysis', http);
	}

	async basicImport(analysis: AnalysisModel[]): Promise<void> {
		await this.import(
			analysis.filter(({ type }) => type === 'genotype'),
			'basic',
		);
		await this.import(
			analysis.filter(({ type }) => type.includes('filiation')),
			'basic',
		);
	}

	async createFull(analysis: AnalysisModel): Promise<ResponseModel<AnalysisModel>> {
		return this.http.post<ResponseModel<AnalysisModel>>(
			'analysis/app',
			{ data: analysis },
			{ database: this.database || HttpService.currentDb },
		);
	}

	async importResults(results, mode) {
		return this.http.post<ResponseModel<AnalysisModel>>(
			'analysis/import-results',
			{ data: results },
			{ database: this.database || HttpService.currentDb },
			{ mode },
		);
	}

	async importResultsHistory(results, mode) {
		return this.http.post<ResponseModel<AnalysisModel>>(
			'analysis/import-results-history',
			{ data: results },
			{ database: this.database || HttpService.currentDb },
			{ mode },
		);
	}
}
