import { BirthModel } from '../models/birth.model';
import { BreedModel } from '../models/breed.model';

export enum ProcessTypesEnum {
	newAnimalBirth = 'newAnimalBirth',
}

export type ProcessType = keyof typeof ProcessTypesEnum;

interface NewBirthLinkOptions {
	birthFormLinked?: boolean;
	breedingId?: string;
	proposalBreed?: BreedModel;
}

interface NewAnimalLinkOptions {
	animalFormLinked?: boolean;
	birthId?: string;
	proposalBirth?: BirthModel;
	animalId?: string;
	animalOfficialId?: string;
}

export interface NewAnimalBirthLinkOptions extends NewBirthLinkOptions, NewAnimalLinkOptions {
	animalBirthFormLinked?: boolean;
}

/** General way of referring to specific link options interfaces  */
export type ProcessLinkerInterface = NewAnimalBirthLinkOptions | null;
