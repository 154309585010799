import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ISelectData } from 'src/app/schemes/types/form-inputs';
import { FormUtilsService } from 'src/app/services/form-utils.service';
import { PlacesService } from 'src/app/services/places.service';
import { CustomFormPlacesValidator } from 'src/app/validations/form-validations/place.validator';
import { CustomFormStatusValidator } from 'src/app/validations/form-validations/status.validator';
import { UserFeedbackService } from '../../services/user-feedback.service';

@Component({
	selector: 'app-input-places',
	templateUrl: './input-places.component.html',
	styleUrls: ['./input-places.component.scss'],
})
export class InputPlacesComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() refForm: FormGroup;
	// In case you're initializing a form with values (e.g: edit form), to properly change the selectors value,
	// you need to emit an event using "refreshSelectorsEvent" input.
	@Input() refreshSelectorsEvent: EventEmitter<boolean>;
	placesForm: FormGroup = this.fb.group({
		community: ['', [Validators.required]],
		province: ['', [Validators.required]],
		island: ['', []],
		municipality: ['', [Validators.required]],
		cp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
	});

	communityView: ISelectData[] = [];
	provinceView: ISelectData[] = [];
	municipalityView: ISelectData[] = [];
	islandView: ISelectData[] = [];
	refreshSelectorsSubscription: Subscription;

	private readonly placesValidators = [
		this.customFormStatus.disableFieldsOnDependencies([
			{
				field: 'province',
				dependsOn: 'community',
			},
			{
				field: 'municipality',
				dependsOn: 'province',
			},
			{
				field: 'island',
				dependsOn: 'province',
			},
			{
				field: 'cp',
				dependsOn: 'province',
			},
		]),
		this.customFormPlacesValidator.validCPGivenProvince('cp', 'province', true),
	];

	constructor(
		private fb: FormBuilder,
		public formUtils: FormUtilsService,
		private placeService: PlacesService,
		private customFormPlacesValidator: CustomFormPlacesValidator,
		private customFormStatus: CustomFormStatusValidator,
		private userFeedbackService: UserFeedbackService,
		private cd: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		this.onPlacesFormInit();
		this.cd.detectChanges();
	}

	ngOnDestroy(): void {
		this.unsubscribeSubscriptions();
	}

	ngOnInit(): void {
		this.loadData();
		this.initSubscriptions();
	}

	async loadData(): Promise<void> {
		try {
			this.communityView = this.placeService.allCommunities.map(({ name }) => ({ value: name }));
			this.provinceView = this.placeService.allProvinces.map(({ name }) => ({ value: name }));
		} catch (error) {
			this.userFeedbackService.showErrorMessage('onLoad');
		}
	}

	onPlacesFormInit(): void {
		Object.keys(this.placesForm.controls).forEach(key => {
			this.refForm.addControl(key, this.placesForm.controls[key]);
		});
		this.refForm.setValidators(this.placesValidators);
		this.refForm.updateValueAndValidity();
	}

	onSelectCommunity(event: boolean): void {
		if (!event) return;
		const communityName = this.placesForm.get('community').value;
		this.provinceView = this.placeService.getProvincesByCommunityName(communityName).map(name => ({
			value: name,
		}));
	}

	onSelectProvince(event: boolean): void {
		if (!event) return;
		const provinceName = this.placesForm.get('province').value;
		this.municipalityView = this.placeService.getMunicipalitiesByProvinceName(provinceName).map(name => ({
			value: name,
		}));
		this.islandView = this.placeService
			.getIslandsByProvinceNameOrMunicipalityName(provinceName)
			.map(name => ({
				value: name,
			}));
	}

	onSelectIsland(event: boolean): void {
		if (!event) {
			this.onSelectProvince(true);
			return;
		}
		const provinceName = this.placesForm.get('province').value;
		const islandName = this.placesForm.get('island').value;
		this.municipalityView = this.placeService
			.getMunicipalitiesByIslandNameAndProvinceName(islandName, provinceName)
			.map(name => ({
				value: name,
			}));
	}

	onSelectMunicipality(event: boolean): void {
		if (!event) return;
		const provinceName = this.placesForm.get('province').value;
		const municipalityName = this.placesForm.get('municipality').value;
		this.islandView = this.placeService
			.getIslandsByProvinceNameOrMunicipalityName(provinceName, municipalityName)
			.map(name => ({
				value: name,
			}));
	}

	getFormControl(name: string): AbstractControl {
		return this.placesForm.get(name);
	}

	private initSubscriptions(): void {
		if (this.refreshSelectorsEvent) {
			this.refreshSelectorsSubscription = this.refreshSelectorsEvent.subscribe((valuesSet: boolean) => {
				if (!valuesSet) return;
				this.refreshPlacesSelectors();
			});
		}
	}

	private unsubscribeSubscriptions(): void {
		if (this.refreshSelectorsSubscription) this.refreshSelectorsSubscription.unsubscribe();
	}

	private refreshPlacesSelectors(): void {
		if (this.placesForm.get('community').value) this.onSelectCommunity(true);
		if (this.placesForm.get('province').value) this.onSelectProvince(true);
		if (this.placesForm.get('municipality').value) this.onSelectMunicipality(true);
		if (this.placesForm.get('island').value) this.onSelectIsland(true);
	}
}
