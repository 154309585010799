import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng';

const BYTE = 1;
const KILOBYTE = 1000 * BYTE;
const MEGABYTE = 1000 * KILOBYTE;

@Component({
	selector: 'app-image-upload',
	templateUrl: './image-upload.component.html',
	styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
	@Input() placeholder: string;
	@Input() isUpload: boolean = true;

	@Output() fileEvent: EventEmitter<{ file?: File; save?: boolean }> = new EventEmitter<{
		file?: File;
		save?: boolean;
	}>();

	@ViewChild('fileUploader') fileUploader: FileUpload;

	fileSizeLimit = 10 * MEGABYTE;
	name: string = Date.now().toString();
	constructor() {}

	ngOnInit(): void {}

	onSelect(event): void {
		this.fileEvent.emit({ file: event.currentFiles[0] });
	}

	onSave(): void {
		this.fileEvent.emit({ save: true });
	}

	onClearFile(): void {
		this.fileUploader.clear();
		this.fileEvent.emit(null);
	}
}
