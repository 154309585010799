import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RendererService {
	private renderer: Renderer2;
	constructor(private rendererFactory: RendererFactory2) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	renderProperty(element: ElementRef, name: string, value: string): void {
		this.renderer.setProperty(element.nativeElement, name, value);
	}
}
