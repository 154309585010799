<div class="redil-info">
	<h2>Rellena los datos de la transferencia</h2>
	<div class="body" *ngIf="dataLoaded">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-drop-reason">Finca de origen</label>
					<input pInputText [(ngModel)]="originRanchName" [disabled]="true" />
				</div>

				<div class="info-row">
					<label for="input-drop-reason">Finca destino</label>
					<p-autoComplete
						[suggestions]="ranches"
						[(ngModel)]="transference.destinationRanch"
						placeholder="Selecciona una opción"
						field="name"
						[dropdown]="true"
						(completeMethod)="onSelectComplete($event)"
						[isRequired]="true"
						[forceSelection]="true"
					></p-autoComplete>
				</div>

				<p-message
					*ngIf="showErrors && !transference.destinationRanch"
					severity="error"
					text="Valor necesario"
				>
				</p-message>

				<div class="info-row">
					<label for="drop-date">Fecha de transferencia</label>
					<p-calendar
						id="drop-date"
						appUseUtc
						[(ngModel)]="transference.date"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					>
					</p-calendar>
				</div>
				<p-message
					*ngIf="
						this.transference.date &&
						this.dropDate &&
						this.transference.date.getTime() > this.dropDate.getTime()
					"
					severity="error"
					text="La fecha debe ser anterior a la fecha de baja"
				></p-message>
				<p-message
					*ngIf="this.transference.date && this.transference.date.getTime() < this.birthDate.getTime()"
					severity="error"
					text="La fecha debe ser posterior a la fecha de nacimiento"
				></p-message>

				<p-message *ngIf="showErrors && !transference.date" severity="error" text="Fecha necesaria">
				</p-message>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(transference)" class="app-btn primary">Aceptar</button>
				<button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button>
			</div>
		</section>
	</div>
</div>
