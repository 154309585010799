<h2>Filtrar Cubriciones</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info" *ngIf="farms.length > 0">

				<!-- <div class="info-row">
                    <label>Fecha</label>
                    <p>Suelta (desde):</p>
                    <p-calendar [(ngModel)]="filters.birthDate.start" [locale]="es.calendar_es" dateFormat="dd/mm/yy"
                        [showIcon]="true"></p-calendar>
                    <p>Recogida (hasta):</p>
                    <p-calendar [(ngModel)]="filters.birthDate.end" [locale]="es.calendar_es" dateFormat="dd/mm/yy"
                        [showIcon]="true"></p-calendar>
                </div> -->

				<div class="info-row">
					<label for="input-farm">Ganadería</label>
					<p-dropdown [options]="farms" [(ngModel)]="filters.farm" placeholder="Selecciona una opción"
						(onChange)="change($event)" optionLabel="label"></p-dropdown>
				</div>

			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>