import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropReasonEnum } from 'src/app/schemes/enums/drop-reason.enum';
import { AnimalPipe } from 'src/app/shared/pipes/animal.pipe';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import reasonsJson from 'src/assets/translate/dropReasonsTranslate.json';
import { TimeService } from '../../../services/time.service';

@Component({
	selector: 'app-death-form',
	templateUrl: './death-form.component.html',
	styleUrls: ['./death-form.component.scss'],
})
export class DeathFormComponent implements OnInit {
	death = {
		dropDate: undefined,
		dropReason: undefined,
	};

	es = ngPrimeLocale;
	filteredReasons;
	dropReasons: any;
	qualificationDate: Date;
	transferenceDate: Date;
	showErrors = false;
	limitDateToday = new Date();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DeathFormComponent>,
		private animalPipe: AnimalPipe,
		private titlecase: TitleCasePipe
	) {}

	ngOnInit(): void {
		this.dropReasons = Object.entries(reasonsJson)
			.map(([value, name]) => ({
				value,
				name: this.titlecase.transform(name),
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
		this.qualificationDate = TimeService.parseDate(this.data.qualificationDate);

		this.transferenceDate = TimeService.parseDate(this.data.transferenceDate);
		this.filteredReasons = this.dropReasons;
	}

	onDialogClose(res): void {
		if (res) {
			this.showErrors = true;

			if (
				!this.death.dropReason ||
				!this.death.dropReason ||
				!this.validateAgainstQualificationDate() ||
				!this.validateAgainstTransferenceDate ||
				!this.death.dropDate
			) {
				return;
			}
		}
		this.dialogRef.close(res);
	}

	revertTranslation($event: any): void {
		this.death.dropReason = this.animalPipe.revertTransformation($event, 'dropReason') as DropReasonEnum;
	}

	searchReason($event): void {
		this.filteredReasons = this.dropReasons.filter(({ name }) =>
			name.toLowerCase().includes($event.query.toString().toLowerCase())
		);
	}

	validateAgainstQualificationDate(): boolean {
		return !(
			this.death.dropDate &&
			this.qualificationDate &&
			this.death.dropDate.getTime() < this.qualificationDate.getTime()
		);
	}

	validateAgainstTransferenceDate(): boolean {
		return !(
			this.death.dropDate &&
			this.transferenceDate &&
			this.death.dropDate.getTime() < this.transferenceDate.getTime()
		);
	}

	changeSelectedDropReason(selectedDropReasonValue: string): void {
		this.death.dropReason = selectedDropReasonValue;
	}
}
