import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
	CUSTOM_FORM_ERRORS_ID,
	EPlacesValidationMessages,
	IPlacesValidatorDetails,
} from '../../schemes/types/form-validation';
import { PlacesService } from '../../services/places.service';

export interface IStatusError {
	disableField: boolean | 'preventDefault';
}
@Injectable({
	providedIn: 'root',
})
export class CustomFormPlacesValidator {
	constructor(private placesService: PlacesService) {}

	validCPGivenProvince(
		cpField: string,
		provinceField: string,
		showErrorOnInput: boolean = false
	): ValidatorFn {
		return (formGroup: FormGroup): ValidationErrors => {
			const cpFieldController = formGroup.get(cpField) as FormControl;
			const provinceFieldController = formGroup.get(provinceField) as FormControl;
			if (!cpFieldController.value || !provinceFieldController.value) return null;

			const provinceCode = this.placesService.getProvinceCodeByProvinceName(provinceFieldController.value);

			if (!provinceCode || cpFieldController.value.length < 5) return null;

			const regex = new RegExp(`^${provinceCode}[0-9]{3}$`);
			const cpBelongsToProvince = regex.test(cpFieldController.value);

			if (!cpBelongsToProvince) {
				const error: { [CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_FORMAT_ID]: IPlacesValidatorDetails } = {
					[CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_FORMAT_ID]: {
						customMessage: EPlacesValidationMessages.cpDoesNotBelongToProvince,
					},
				};
				if (showErrorOnInput) {
					cpFieldController.setErrors(error);
				}
				return error;
			}

			if (cpFieldController.hasError(CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_FORMAT_ID)) {
				delete cpFieldController.errors[CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_FORMAT_ID];
				cpFieldController.updateValueAndValidity({ onlySelf: true });
			}
			return null;
		};
	}
}
