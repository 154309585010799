import { Injectable } from '@angular/core';
import { ProcessLinkerInterface, ProcessType, ProcessTypesEnum } from '../schemes/types/process-linker';

/**
 * ProcessLinkerService
 * Service is meant to be used to link two or more aplicacions processes or flows.
 * Mainly used to allow the user navigate through different form pages sharing some relevant data.
 */
@Injectable({
	providedIn: 'root',
})
export class ProcessLinkerService {
	private readonly localStorageKey = 'processLinker';

	constructor() {}

	/**
	 * Sets the session data given a specfic process type.
	 * @param process
	 * @param data
	 */
	setProcessData(process: ProcessType, data: ProcessLinkerInterface): void {
		if (process === ProcessTypesEnum.newAnimalBirth) {
			this.setSessionStorage({ [ProcessTypesEnum.newAnimalBirth]: data });
		}
	}

	/**
	 * Gets the session data given a specfic process type. Returns null if no data is found or the linker param is not set.
	 * @param process
	 * @returns
	 */
	getProcessData(process: ProcessType): ProcessLinkerInterface | null {
		const data = JSON.parse(this.getProcessLinkData())?.[process] as ProcessLinkerInterface;
		if (!data) return null;
		return data;
	}

	/**
	 * Removes a process data from the session storage if it exists. Keeps the other processes data.
	 * @param process
	 * @returns
	 */
	removeProcessData(process: ProcessType): void {
		const data = this.getProcessLinkData();
		if (!data) return;
		const parsedData = JSON.parse(data);
		delete parsedData[process];
		sessionStorage.setItem(this.localStorageKey, JSON.stringify(parsedData));
	}

	/**
	 * Clears all session data contain in the process linker.
	 */
	clearRouteLinkerData(): void {
		sessionStorage.removeItem(this.localStorageKey);
	}

	private getProcessLinkData(): string | null {
		return sessionStorage.getItem(this.localStorageKey);
	}

	/**
	 * Sets the data of a specific process. If process data already exists it will be overwritten.
	 * Data from other processes will be kept.
	 * @param data
	 */
	private setSessionStorage(data: any): void {
		const currentData = JSON.parse(localStorage.getItem(this.localStorageKey));
		sessionStorage.setItem(this.localStorageKey, JSON.stringify({ ...currentData, ...data }));
	}
}
