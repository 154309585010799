import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimalProvider } from 'src/app/providers/animal.provider';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { AnalysisTypeEnum } from 'src/app/schemes/enums/analysis.enum';
import { AnalysisPipe } from 'src/app/shared/pipes/analysis.pipe';
import { TimeService } from '../../../../../services/time.service';
import { FilterAnalysesModalComponent } from '../../filter-analyses-modal/filter-analyses-modal.component';

@Component({
	selector: 'app-filter-samples-modal',
	templateUrl: './filter-samples-modal.component.html',
	styleUrls: ['./filter-samples-modal.component.scss'],
})
export class FilterSamplesModalComponent implements OnInit {
	currentPage = '/dna/samples';
	filteredFarms: { name: string; value: string }[];
	indexedFarms: { [key: string]: string } = {};
	animals: { name: string; value: string }[];
	filteredAnimals: { name: string; value: string }[];
	indexedAnimals: { [key: string]: string } = {};
	es = ngPrimeLocale;
	filters;
	types: { name: string; value: AnalysisTypeEnum }[] = [];
	originalFilters: any;

	constructor(
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterAnalysesModalComponent>,
		private animalProvider: AnimalProvider,
		private analysisPipe: AnalysisPipe,
		private titleCase: TitleCasePipe,
	) {
		this.filters = {
			animal_like: '',
			analysisType_like: null,
			createdAt: {
				start: null,
				end: null,
			},
		};
	}

	async ngOnInit() {
		try {
			this.types = Object.entries(AnalysisTypeEnum)
				.map(([key, value]) => ({
					name: this.titleCase.transform(this.analysisPipe.transform(value) as string),
					value,
				}))
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			await this.setFiltersValues(this.data);
		} catch (error) {
			console.error(error);
		}
	}

	async onSaveFilters() {
		const filtersResult: { [key: string]: any } = {};
		if (this.filters.animal_like) {
			filtersResult.animal_like = this.filters.animal_like;
		}
		if (this.filters.analysisType_like) {
			filtersResult.analysisType_like = this.filters.analysisType_like.value;
		}
		if (this.filters.createdAt) {
			if (this.filters.createdAt.start) filtersResult.createdAt_gte = this.filters.createdAt.start;
			if (this.filters.createdAt.end) {
				filtersResult.createdAt_lte = this.filters.createdAt.end;
				filtersResult.createdAt_lte as Date;
			}
		}
		this.onCloseDialog(filtersResult);
	}

	onCloseDialog(queryParams) {
		this.dialogRef.close(queryParams);
		queryParams ? this.router.navigate([this.data.url], { queryParams }) : this.router.navigate([this.data.url]);
	}

	// Parses start and end dates, value on object types ($eq) and ($like) on string types
	parseFilters(filters: Object) {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (value !== false && !value) {
				return newFilters;
			}
			return newFilters;
		}, {});
	}

	async setFiltersValues(data) {
		this.originalFilters = JSON.parse(JSON.stringify(data.filters));
		for (const key of Object.keys(data.filters)) {
			const keys = key.split('_');
			if (keys[0] === 'analysisType') { this.filters.analysisType_like = this.types.find(type => type.value === data.filters.analysisType_like); } else if (keys[0] == 'animal') {
				this.filters.animal_like = data.filters.animal_like || '';
			}
			if (keys[1] == 'gte') this.filters[keys[0]].start = TimeService.parseDate(data.filters[key]);
			else if (keys[1] == 'lte') this.filters[keys[0]].end = TimeService.parseDate(data.filters[key]);
			else if (keys[0] != '') this.filters[keys[0]] = data.filters[key];
		}
	}

	onClearFilters() {
		this.filters = {
			animal_like: '',
			analysisType_like: null,
			createdAt: {
				start: null,
				end: null,
			},
		};
	}
}
