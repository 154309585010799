<div class="page centered">
	<!-- <button (click)="bug.updateAnimals()" class="app-btn primary">hola</button> -->
	<h2>Selecciona un módulo para ver sus acciones</h2>
	<div class="module-selector">
		<div class="app-select-btn" *ngFor="let mod of modules" (click)="selectAssociation(mod.id)"
			[ngClass]="{ icon: mod.id }">
			<div *ngIf="mod?.id" class="bg module-logo"
				[ngStyle]="{ 'background-image': 'url(/assets/images/icons/modules/' + mod.id + '.svg)' }"></div>
			<p>
				{{ mod.name }}
			</p>
		</div>
	</div>
</div>