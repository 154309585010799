const prepositions = [
	'a',
	'durante',
	'según',
	'ante',
	'en',
	'sin',
	'bajo',
	'entre',
	'so',
	'cabe',
	'hacia',
	'sobre',
	'con',
	'hasta',
	'tras',
	'contra',
	'mediante',
	'versus',
	'de',
	'para',
	'vía',
	'desde',
	'por',
];

const articles = ['el', 'la', 'los', 'las'];

const contractions = ['del', 'al'];

const conjunctions = ['y', 'e', 'o', 'u'];

const global = prepositions.concat(articles, contractions, conjunctions);

export const SpanishLanguageElements = {
	prepositions,
	articles,
	contractions,
	conjunctions,
	global,
};
