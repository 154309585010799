import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { RaceProvider } from '../../../providers/race.provider';
import { RaceModel } from '../../../schemes/models/race.model';
import { TimeService } from '../../../services/time.service';

@Component({
	selector: 'app-arca-form',
	templateUrl: './arca-form.component.html',
	styleUrls: ['./arca-form.component.scss'],
})
export class ArcaFormComponent implements OnInit {
	es = ngPrimeLocale;
	date = null;
	race = null;
	races: RaceModel[] = [];
	showErrors = false;
	maxDate: Date = new Date();

	constructor(private raceProvider: RaceProvider, public dialogRef: MatDialogRef<ArcaFormComponent>) {}

	async ngOnInit(): Promise<void> {
		this.races = ((await this.raceProvider.getAll())?.data || []).sort((raceA, raceB) =>
			raceA.name.localeCompare(raceB.name)
		);
	}

	onDialogClose(res): void {
		if (res) {
			this.showErrors = true;
			if (!this.race) {
				return;
			}
		}
		this.showErrors = false;
		this.dialogRef.close(res ? { race: this.race, date: this.date || TimeService.getActualDate() } : false);
	}
}
