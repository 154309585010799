import { Injectable } from '@angular/core';
import { BaseModel } from '../schemes/models/base.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';

@Injectable({
	providedIn: 'root',
})
export class BaseProvider<T extends BaseModel> {
	collection: string;
	database: string;

	constructor(collection: string, protected http: HttpService, db?: string) {
		this.database = db;
		this.collection = collection;
	}

	getAll(params?): Promise<ResponseModel<T[]>> {
		return this.http.get<ResponseModel<T[]>>(
			`${this.collection}`,
			{ database: this.database || HttpService.currentDb },
			params
		);
	}

	// Cuando los datos de Mongo entre Samples y Analysis esten conectados correctamente, el "enableNotFoundInterceptor" se puede quitar y dejar solo errorHandlerHttpInterceptor: 'true'
	getById(id: string, params?, enableNotFoundInterceptor: boolean = true): Promise<ResponseModel<T>> {
		return this.http.get<ResponseModel<T>>(
			`${this.collection}/${id}`,
			{
				database: this.database || HttpService.currentDb,
				errorHandlerHttpInterceptor: enableNotFoundInterceptor ? 'true' : 'false',
			},
			params
		);
	}

	save(entity: T): Promise<ResponseModel<T>> {
		return this.http.post<ResponseModel<T>>(
			`${this.collection}`,
			{ data: entity },
			{ database: this.database || HttpService.currentDb }
		);
	}

	update(entity: Partial<T>): Promise<ResponseModel<T>> {
		return this.http.patch<ResponseModel<T>>(
			`${this.collection}/${entity._id}`,
			{ id: entity._id, data: entity },
			{ database: this.database || HttpService.currentDb }
		);
	}

	async updateBatch(entities: T[]): Promise<ResponseModel<T[]>> {
		const batchSize = 100;
		const res: ResponseModel<T[]> = { message: '', data: [] };
		for (let batchStart = 0; batchStart < entities.length; batchStart += batchSize) {
			const batch = entities.slice(batchStart, batchStart + batchSize);
			const batchRes = await this.http.patch<ResponseModel<T[]>>(
				`${this.collection}/many`,
				{ data: batch },
				{ database: this.database || HttpService.currentDb }
			);

			if (Array.isArray(batchRes.data)) {
				res.data.concat(batchRes.data);
			}

			console.log(`Processed ${batchStart + batchSize} of ${entities.length}`);
		}
		return res;
	}

	put(entity: T): Promise<ResponseModel<T>> {
		return this.http.put<ResponseModel<T>>(
			`${this.collection}/${entity._id}`,
			{ id: entity._id, data: entity },
			{ database: this.database || HttpService.currentDb }
		);
	}

	delete(id: string): Promise<void> {
		return this.http.delete(`${this.collection}/${id}`, { database: this.database || HttpService.currentDb });
	}

	count(filters: Record<string, unknown>): Promise<ResponseModel<{ count: number }>> {
		return this.http.get<ResponseModel<{ count: number }>>(
			`${this.collection}/count`,
			{ database: this.database || HttpService.currentDb },
			filters
		);
	}

	async import(entities: T[], mode: string): Promise<ResponseModel<any> | ResponseModel<any>[]> {
		await Promise.resolve();
		const batchSize = 100;
		const res: ResponseModel<any[]> = { message: '', data: [] };
		const importPromises = [];
		for (let batchStart = 0; batchStart < entities.length; batchStart += batchSize) {
			const batch = entities.slice(batchStart, batchStart + batchSize);
			importPromises.push(
				this.http.post<ResponseModel<any>>(
					`${this.collection}/import`,
					{ data: batch },
					{ database: this.database || HttpService.currentDb },
					{ mode }
				)
			);
		}
		const responses = await Promise.all(importPromises);
		responses.forEach(response => {
			if (Array.isArray(response.data)) {
				res.data.push(response.data);
			}
		});
		return res;
	}

	export(filters?): Promise<ResponseModel<T[]>> {
		return this.http.get<ResponseModel<T[]>>(
			`${this.collection}/export`,
			{ database: this.database || HttpService.currentDb },
			filters
		);
	}

	validate(entity: T): Promise<ResponseModel<{ res: boolean; message: string }>> {
		return this.http.post<ResponseModel<{ res: boolean; message: string }>>(
			`${this.collection}/validate`,
			{ data: entity },
			{ database: this.database || HttpService.currentDb }
		);
	}

	firstImport(data: T[], mode: string): Promise<ResponseModel<any> | ResponseModel<any>[]> {
		return this.http.post<ResponseModel<any>>(
			`${this.collection}/first-import`,
			{ data },
			{ database: this.database || HttpService.currentDb },
			{ mode }
		);
	}
}
