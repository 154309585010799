import { Injectable } from '@angular/core';
import { FarmModel } from '../schemes/models/farm.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class FarmProvider extends BaseProvider<FarmModel> {
	constructor(http: HttpService) {
		super('farms', http);
	}

	getFarmRanchs(id: string): Promise<ResponseModel<FarmModel[]>> {
		return this.http.get<ResponseModel<FarmModel[]>>(`farms${id}/ranchs`, {
			database: HttpService.currentDb,
		});
	}

	getActiveFarms(params?): Promise<ResponseModel<FarmModel[]>> {
		return this.getAll({ ...params, dropDate: null });
	}

	getFarms(params?): Promise<ResponseModel<FarmModel[]>> {
		return this.getAll({ ...params });
	}

	getAssociationFarms(database: string, params?): Promise<ResponseModel<FarmModel[]>> {
		return this.http.get<ResponseModel<FarmModel[]>>('farms', { database }, params);
	}

	getAssociationFarmById(database: string, id: string): Promise<ResponseModel<FarmModel>> {
		return this.http.get<ResponseModel<FarmModel>>(`farms/${id}`, { database });
	}
}
