import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) { }

	async canActivate(): Promise<boolean> {
		const user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();

		if (user) {
			void this.router.navigateByUrl('/');
			return false;
		}
		return true;
	}
}
