import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { TimeService } from '../../../services/time.service';

@Component({
	selector: 'app-qualification-form',
	templateUrl: './qualification-form.component.html',
	styleUrls: ['./qualification-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class QualificationFormComponent implements OnInit {
	qualification = {
		date: TimeService.getActualDate(),
		value: '0',
	};

	dropDate: Date;
	birthDate: Date;
	showErrors = false;
	es = ngPrimeLocale;
	actualDate: Date;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Record<string, unknown>,
		public dialogRef: MatDialogRef<QualificationFormComponent>
	) {}

	ngOnInit(): void {
		this.actualDate = TimeService.parseDate(new Date());

		if (this.data?.birthDate) {
			this.birthDate = new Date(this.data.birthDate as string);
		} else {
			this.birthDate = null;
		}

		if (this.data?.dropDate) {
			this.dropDate = new Date(this.data.dropDate as Date);
		} else {
			this.dropDate = null;
		}
	}

	onDialogClose(res) {
		this.showErrors = true;
		if (res && !this.validateQualification()) {
			return;
		}
		this.dialogRef.close(res);
	}

	private validateQualification() {
		return this.validateQualificationDate();
	}

	private validateQualificationDate() {
		if (this.dropDate) {
			return (
				this.qualification.date &&
				this.dropDate &&
				this.qualification.date.getTime() <= this.dropDate.getTime() &&
				this.qualification.date.getTime() <= this.actualDate.getTime() &&
				this.qualification.date.getTime() >= this.birthDate.getTime()
			);
		}
		return (
			this.qualification.date &&
			this.qualification.date.getTime() <= this.actualDate.getTime() &&
			this.qualification.date.getTime() >= this.birthDate.getTime()
		);
	}
}
