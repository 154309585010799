<div class="header">
	<h2>Informe de Censo</h2>
	<img src="../../../../../assets/images/icons/cancel-icon.svg" alt="" (click)="onCloseDialog()" />
</div>
<div class="redil-info" *ngIf="farms">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-gender">Ganaderías</label>

					<p-multiSelect
						defaultLabel="Ganadería"
						selectedItemsLabel="{0} Seleccionados"
						maxSelectedLabels="2"
						[options]="farmsOptions"
						[(ngModel)]="fields.farms"
						(onChange)="onFarmChange($event)"
					>
					</p-multiSelect>
				</div>
				<p-message
					*ngIf="errors.dropDate"
					severity="error"
					text="La fecha de baja es anterior a la del informe"
				></p-message>

				<div class="info-row">
					<label for="input-gender">Razas</label>

					<p-multiSelect
						defaultLabel="Razas"
						selectedItemsLabel="{0} Seleccionados"
						[options]="racesOptions"
						[(ngModel)]="fields.races"
					>
					</p-multiSelect>
				</div>

				<p-message *ngIf="errors.farm" severity="error" text="Debe seleccionar una Ganadería"></p-message>

				<div class="info-row">
					<label for="input-races">Regas</label>
					<p-multiSelect
						defaultLabel="Regas"
						selectedItemsLabel="{0} Seleccionados"
						maxSelectedLabels="1"
						[options]="ranchOptions"
						[(ngModel)]="fields.ranches"
						optionLabel="label"
						[appendTo]="'body'"
					>
					</p-multiSelect>
				</div>
				<div class="info-row info-row-calendar">
					<div>
						<p-checkbox [binary]="true" [(ngModel)]="exportToday" (onChange)="changeExportType()">
						</p-checkbox>
						<p for="drop-date">Informe a día de hoy</p>
					</div>
					<div *ngIf="!exportToday">
						<label for="drop-date">Fecha</label>
						<p-calendar
							id="drop-date"
							appUseUtc
							[(ngModel)]="fields.date"
							[locale]="es.calendar_es"
							dateFormat="dd/mm/yy"
							[showIcon]="true"
							[appendTo]="'body'"
							[placeholder]="'24/06/1998'"
						>
						</p-calendar>
					</div>
				</div>

				<p-message *ngIf="errors.date" severity="error" text="Debe seleccionar una fecha"></p-message>
			</div>
		</section>
		<div class="centered">
			<button (click)="onGenerateReport()" class="app-btn orange">Generar informe</button>
		</div>
	</div>
</div>
