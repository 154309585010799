import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { RaceProvider } from 'src/app/providers/race.provider';
import { RanchProvider } from 'src/app/providers/ranch.provider';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { RaceModel } from 'src/app/schemes/models/race.model';
import { RanchModel } from 'src/app/schemes/models/ranch.model';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { isArray } from 'util';
import { UtilsService } from '../../../../services/utils.service';

@Component({
	selector: 'app-filter-farms-modal',
	templateUrl: './filter-farms-modal.component.html',
	styleUrls: ['./filter-farms-modal.component.scss'],
})
export class FilterFarmsModalComponent implements OnInit {
	farms: FarmModel[];
	selectedOwnerFarm: any;
	selectedBreederFarm: any;
	ranchs: RanchModel[];
	races: RaceModel[];
	racesInFarm: RaceModel[];
	selectedRace: any;

	es = ngPrimeLocale;
	filters;
	filteredFarms: FarmModel[];
	active: boolean = true;
	indexedFarms: { [key: string]: FarmModel };

	constructor(
		private farmProvider: FarmProvider,
		private ranchProvider: RanchProvider,
		private racesProvider: RaceProvider,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterFarmsModalComponent>,
		private utilsService: UtilsService,
	) { }

	async ngOnInit() {
		try {
			this.filters = {
				name: null,
				races: null,
				owner: null,
				mobilephone: null,
			};

			const [{ data: farms }, { data: ranchs }, { data: races }] = await Promise.all([
				this.farmProvider.getActiveFarms(),
				this.ranchProvider.getAll(),
				this.racesProvider.getAll({ _embed: 'races' }),
			]);
			if (farms) {
				this.farms = farms;
				this.indexedFarms = this.utilsService.indexArray(farms, '_id');
				this.selectedOwnerFarm = this.indexedFarms[this.filters.ownerFarm];
				this.selectedBreederFarm = this.indexedFarms[this.filters.breederFarm];
				if (ranchs) this.ranchs = ranchs;
			}

			if (races) {
				this.races = races;
			}
			this.setFiltersValues(this.data);
		} catch (error) {
			console.error(error);
		}
	}

	searchFarm($event) {
		this.filteredFarms = this.farms.filter(farm => farm.name.toLowerCase().indexOf($event.query.toString().toLowerCase()) != -1);
	}

	onSaveFilters() {
		const urlFilters = this.parseFilters(this.filters);
		urlFilters['active'] = this.active;
		this.dialogRef.close();
		this.router.navigate(['/book/farms'], { queryParams: urlFilters });
	}

	// Parses start and end dates, value on object types ($eq) and ($like) on string types
	parseFilters(filters: Object) {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (!value) {
				return newFilters;
			}
			if (key.toLowerCase().includes('date')) {
				if (value.start) newFilters[`${key}_gte`] = value.start;
				if (value.end) {
					newFilters[`${key}_lte`] = value.end;
					newFilters[`${key}_lte`];
				}
			} else if (typeof value === 'string') {
				newFilters[`${key}_like`] = value;
			} else if (isArray(value) && value.length > 0) {
				newFilters[`${key}_in`] = value.map(val => val._id).join(',') || '';
			} else {
				if (typeof value === 'object') {
					for (const val in value) {
						newFilters[`${key}.${val}_like`] = filters[key][val];
					}
				}
				if (!value.value) {
					return newFilters;
				}
				newFilters[`${key}`] = value.value;
			}
			return newFilters;
		}, {});
	}

	onClearFilters() {
		this.filters = {
			name: null,
			races: null,
			owner: null,
			mobilephone: null,
		};
		this.active = false;
		// this.dialogRef.close();
		// this.router.navigate(['/book/farms']);
	}

	setFiltersValues(data) {
		if (data.filters) {
			if (data.filters.name_like) this.filters.name = data.filters.name_like;
			if (data.filters.races_in) this.filters.races = this.getRaces(data.filters.races_in.split(','));
			if (data.filters.owner_like) this.filters.owner = data.filters.owner_like;
			if (data.filters.mobilephone_like) this.filters.mobilephone = data.filters.mobilephone_like;
			else if (data.active) {
				this.active = true;
				this.filters.dropDate = { start: null, end: null };
			} else {
				this.active = false;
			}
		}
	}

	getRaces(races: string[]): RaceModel[] {
		return this.races.filter(race => races.includes(race._id));
	}
}
