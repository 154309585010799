import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ModulesNotDevelopedYet } from '../schemes/enums/modules-not-developed-yet.enum';
import { UserProvider } from '../providers/user.provider';
import { ModuleEnum } from '../schemes/enums/modules.enum';
import { AssociationService } from '../services/association.service';
import { AuthService } from '../services/auth.service';
import { NavigatorService } from '../services/navigator.service';

@Injectable({
	providedIn: 'root',
})
export class ModuleGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private associationService: AssociationService,
		private router: Router,
		private userProvider: UserProvider,
	) { }

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		const redirectUrl = route.data.redirectUrl as string;
		const appModule = ((route.pathFromRoot || []).map(({ routeConfig }) => routeConfig?.path) as ModuleEnum[]).filter(path => !!path);
		const association = await this.associationService.getCurrentAssociation();
		// if (!environment.production) return true;

		if (!association) {
			this.router.navigateByUrl('select-association');
			return false;
		}
		if (
			appModule.length === 0
			|| Object.values(ModuleEnum).every(module => module !== appModule[0])
			|| association.modules.some(module => appModule.includes(module))
		) {
			return true;
		}
		this.router.navigateByUrl('pagenotallowed');
		return false;
	}

	async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
		const redirectUrl = route.data.redirectUrl as string;
		const appModule = route.data.modules as ModuleEnum;
		const association = await this.associationService.getCurrentAssociation();
		// if (!environment.production) return true;

		if (!association) {
			this.router.navigateByUrl('select-association');
			return false;
		}
		if (!appModule || association.modules.includes(appModule)) {
			return true;
		}
		return false;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CheckModuleAvailability implements CanActivate {
	constructor(
		private router: Router,
	) { }

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		const modulesNotDevelopedYet = Object.keys(ModulesNotDevelopedYet);
		const moduleSelected = route.paramMap.get('module') ? route.paramMap.get('module') : route.url.toString();
		if (modulesNotDevelopedYet.includes(moduleSelected)) {
			this.router.navigate(['pagenotyet']);
			return false;
		}
		return true;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CheckModuleExistence implements CanActivate {
	constructor(
		private router: Router,
	) { }

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		const modules = Object.keys(ModuleEnum);
		const moduleSelected = route.paramMap.get('module');
		if (modules.includes(moduleSelected)) {
			return true;
		}
		this.router.navigate(['pagenotfound']);
		return false;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CheckSubModuleExistence implements CanActivate {
	constructor(
		private router: Router,
		private navigator: NavigatorService,
	) { }

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		const modules = JSON.stringify(Object.values(ModuleEnum));
		const moduleSelected = route.paramMap.get('module');
		const actions = this.navigator.getModuleLinks(moduleSelected);

		if (modules.includes(moduleSelected) && !actions) {
			this.router.navigate([moduleSelected]);
			return false;
		}
		return true;
	}
}

@Injectable({
	providedIn: 'root',
})
export class RedirectModule implements CanActivate {
	constructor(
		private router: Router,
		private navigator: NavigatorService,
	) { }

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		const moduleSelected = route.paramMap.get('module');
		const moduleLinks = this.navigator.getModuleLinks(moduleSelected);

		if (moduleLinks) {
			this.router.navigate([moduleSelected]);
			return false;
		}
		return true;
	}
}
