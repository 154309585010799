import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng';
import { InfoComponent } from 'src/app/components/dialogs/info/info.component';

enum ErrorMessageEnum {
	onLoad = 'Se ha producido un error al cargar los datos',
	onSave = 'Se ha producido un error al guardar los datos',
}
type ErrorMessageType = keyof typeof ErrorMessageEnum;

enum InfoMessageEnum {
	onLoad = 'Datos cargados correctamente',
	onSave = 'Datos guardados correctamente',
	noModifications = 'No se han realizado modificaciones',
}
type InfoMessageType = keyof typeof InfoMessageEnum;
@Injectable({
	providedIn: 'root',
})
export class UserFeedbackService {
	constructor(private messageService: MessageService, private matDialog: MatDialog) {}

	public showErrorMessage(type: ErrorMessageType): void {
		this.messageService.add({
			severity: 'error',
			summary: ErrorMessageEnum[type],
			detail: 'Inténtelo de nuevo más tarde o póngase en contacto con un administrador',
			life: 5000,
		});
	}

	public showCustomErrorMessage(title: string, message: string): void {
		this.messageService.add({
			severity: 'error',
			summary: title,
			detail: message,
			life: 5000,
		});
	}

	public showInfoMessage(type: InfoMessageType): void {
		this.matDialog.open(InfoComponent, {
			data: {
				title: InfoMessageEnum[type],
				mode: 'warning',
			},
		});
	}
}
