import { IDateValidatorDetails, ILengthValidatorDetails } from 'src/app/schemes/types/form-validation';
import {
	ICustomValidatorBase,
	IMaxValidatorDetails,
	IMinValidatorDetails,
} from '../../app/schemes/types/form-validation';

const FORMAT_MESSAGE = 'El formato de este campo no es válido.';

export const ES_VALIDATION_MESSAGES = {
	required: 'El campo es requerido',
	minlength: (details: ILengthValidatorDetails): string =>
		`El campo requiere un mínimo de ${details.requiredLength} caracteres`,
	maxlength: (details: ILengthValidatorDetails): string =>
		`El campo permite un máximo de ${details.requiredLength} caracteres`,
	max: (details: IMaxValidatorDetails): string => `El valor no puede ser mayor a ${details.max}`,
	min: (details: IMinValidatorDetails): string => `El valor no puede ser menor a ${details.min}`,
	email: 'El campo requiere un email válido',
	invalidDate: (details: IDateValidatorDetails): string =>
		details.customMessage ?? 'La fecha introducida no es válida',
	futureDate: 'Este campo no admite fechas posteriores a hoy.',
	notUnique: 'El valor introducido ya está en uso.',
	invalidFormat: (details: ICustomValidatorBase): string => details.customMessage ?? FORMAT_MESSAGE,
	invalidValue: (details: ICustomValidatorBase): string =>
		details.customMessage ?? 'El valor de este campo no es válido.',
	pattern: FORMAT_MESSAGE,
	customMessage: (text: string): string => text,
	not_specified: 'Mensaje de validación sin especificar',
};
