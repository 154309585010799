import { Component, OnInit } from '@angular/core';
import {
	FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserProvider } from 'src/app/providers/user.provider';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	showAccessError: boolean = false;

	constructor(
		private fb: FormBuilder,
		private utils: UtilsService,
		private userProvider: UserProvider,
		private authService: AuthService,
		private router: Router,
	) { }

	ngOnInit() {
		this.loginForm = new FormGroup({
			email: new FormControl(environment.production ? '' : 'acabras@redil.com', [Validators.required]),
			password: new FormControl(environment.production ? '' : 'password', [Validators.required]),
		});
	}

	async getAccess() {
		try {
			const email = this.loginForm.get('email').value;
			const password = this.loginForm.get('password').value;

			const login: firebase.auth.UserCredential | void = await this.authService
				.login(email, password)
				.catch(error => (this.showAccessError = error.message));

			if (!login) return this.utils.showToast('No eres usuario, aún', 'middle', 20);

			this.router.navigate(['/modules']);
		} catch (error) {
			this.showAccessError = error.message;
		}
	}

	reloadWindowWithoutCache() {
		location.reload(true);
	}
}
