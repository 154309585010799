import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';

@Component({
	selector: 'app-zootechnical-report',
	templateUrl: './zootechnical-report.component.html',
	styleUrls: ['./zootechnical-report.component.scss'],
})
export class ZootechnicalReportComponent implements OnInit {
	reportDate: Date;
	dateError = false;
	es = ngPrimeLocale;
	maxDate: Date = new Date();
	minDate: Date = new Date();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { minDate: Date },
		public dialogRef: MatDialogRef<ZootechnicalReportComponent>
	) {}

	ngOnInit(): void {
		this.minDate = this.data.minDate;
	}

	onGenerateReport(): void {
		if (this.reportDate) {
			this.dateError = false;
			this.onCloseDialog(this.reportDate);
		} else {
			this.dateError = true;
		}
	}

	onCloseDialog(date: Date = null): void {
		this.dialogRef.close({ date });
	}
}
