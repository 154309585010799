import { Pipe, PipeTransform } from '@angular/core';
import birthTypeTranslate from '../../../assets/translate/birthTypeTranslate.json';

@Pipe({
	name: 'birthType',
})
export class BirthTypePipe implements PipeTransform {
	// eslint-disable-next-line no-unused-vars
	transform(value: string, ...args: string[]): string {
		return ((birthTypeTranslate[value] || value) as unknown) as string;
	}
}
