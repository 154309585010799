import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import weightTranslate from '../../../assets/translate/weightTranslate.json';

@Pipe({
	name: 'weight',
})
export class WeightPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) { }

	transform(value: string | number | Date): unknown {
		if (value === '') {
			return '';
		}
		if (value instanceof Date || (typeof value !== 'number' && Date.parse(value))) {
			return this.datePipe.transform(value, 'dd/MM/yyyy');
		}
		return weightTranslate[value as string];
	}
}
