import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { GenderEnum } from 'src/app/schemes/enums/gender.enum';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { RaceModel } from 'src/app/schemes/models/race.model';
import { RanchModel } from 'src/app/schemes/models/ranch.model';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { UtilsService } from '../../../../services/utils.service';

@Component({
	selector: 'app-filter-ranches-modal',
	templateUrl: './filter-ranches-modal.component.html',
	styleUrls: ['./filter-ranches-modal.component.scss'],
})
export class FilterRanchesModalComponent implements OnInit {
	genders: { name: string; value: GenderEnum }[];
	farms: FarmModel[];
	selectedOwnerFarm: any;
	selectedBreederFarm: any;
	ranchs: RanchModel[];
	races: RaceModel[];
	racesInFarm: RaceModel[];
	selectedRace: any;
	active = true;

	es = ngPrimeLocale;
	filters;
	filteredFarms: FarmModel[];
	indexedFarms: { [key: string]: FarmModel };

	constructor(
		private farmProvider: FarmProvider,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterRanchesModalComponent>,
		private utilsService: UtilsService,
	) { }

	async ngOnInit() {
		try {
			this.filters = {};

			const [{ data: farms }] = await Promise.all([this.farmProvider.getFarms()]);
			if (farms) {
				this.farms = farms;
				this.indexedFarms = this.utilsService.indexArray(this.farms, '_id');
				this.selectedOwnerFarm = this.indexedFarms[this.filters.ownerFarm];
				this.selectedBreederFarm = this.indexedFarms[this.filters.breederFarm];
			}
			this.setFiltersValues(this.data);
		} catch (error) {
			console.error(error);
		}
	}

	searchFarm($event) {
		this.filteredFarms = this.farms.filter(farm => farm.name.toLowerCase().indexOf($event.query.toString().toLowerCase()) != -1);
	}

	onSaveFilters() {
		this.filters.farm = this.filters.farm ? { value: this.filters.farm._id } : null;

		const urlFilters = this.parseFilters(this.filters);
		urlFilters['active'] = this.active;

		this.dialogRef.close();
		this.router.navigate(['/book/ranches'], { queryParams: urlFilters });
	}

	// Parses start and end dates, value on object types ($eq) and ($like) on string types
	parseFilters(filters: Object) {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (!value) {
				return newFilters;
			}
			if (key.toLowerCase().includes('date')) {
				if (value.start) newFilters[`${key}_gte`] = value.start;
				if (value.end) {
					newFilters[`${key}_lte`] = value.end;
					newFilters[`${key}_lte`];
				}
			} else if (typeof value === 'string') {
				newFilters[`${key}_like`] = value;
			} else {
				if (!value.value) {
					return newFilters;
				}
				newFilters[`${key}`] = value.value;
			}
			return newFilters;
		}, {});
	}

	onClearFilters() {
		this.filters = {
			officialId: null,
			eId: null,
			birthDate: {
				start: null,
				end: null,
			},
			registerDate: {
				start: null,
				end: null,
			},
			dropDate: {
				start: null,
				end: null,
			},
			ownerFarm: null,
			rega: null,
			race: null,
			gender: null,
		};
		this.active = false;
	}

	setFiltersValues(data: { filters: Record<string, string>; active: boolean }) {
		if (data.filters) {
			if (data.filters.rega_like) {
				this.filters.rega = data.filters.rega_like;
			}
			if (data.filters.name_like) {
				this.filters.name = data.filters.name_like;
			} else if (data.active) {
				this.active = true;
				this.filters.dropDate = { start: null, end: null };
			} else {
				this.active = false;
			}
		}
	}
}
