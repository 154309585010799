import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
	selector: 'app-arca-sections-form',
	templateUrl: './arca-sections-form.component.html',
	styleUrls: ['./arca-sections-form.component.scss', '../../../shared/styles/form.styles.scss'],
})
export class ArcaSectionsFormComponent implements OnInit {
	arcaSections = {
		categorySectionsAnnexes: '',
		basicCategory: '',
		fundationalRegister: '',
		meritsCategory: '',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			arcaSections: {
				categorySectionsAnnexes: string[];
				basicCategory: string[];
				fundationalRegister: string[];
				meritsCategory: string[];
			};
		},
		public dialogRef: MatDialogRef<ArcaSectionsFormComponent>
	) {}

	ngOnInit(): void {
		this.arcaSections.categorySectionsAnnexes = this.data?.arcaSections?.categorySectionsAnnexes?.join();
		this.arcaSections.basicCategory = this.data?.arcaSections?.basicCategory?.join();
		this.arcaSections.fundationalRegister = this.data?.arcaSections?.fundationalRegister?.join();
		this.arcaSections.meritsCategory = this.data?.arcaSections?.meritsCategory.join();
	}

	onDialogClose(res): void {
		this.dialogRef.close(res);
	}
}
