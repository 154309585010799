export const environment = {
	production: true,
	firebase: {
		apiKey: 'AIzaSyD59Qjh9MPdm4BUtpoqB_4Y8-RXv7i3LOQ',
		authDomain: 'redil-prod.firebaseapp.com',
		databaseURL: 'https://redil-prod.firebaseio.com',
		projectId: 'redil-prod',
		storageBucket: 'redil-prod.appspot.com',
		messagingSenderId: '214257554403',
		appId: '1:214257554403:web:c29fea6d5aaf43c810c6d6',
		measurementId: 'G-KQ3D2JSE9W',
	},
	// apiUrl: 'https://redil-backend.herokuapp.com/api/',
	apiUrl: 'https://api.redil.es/api/',
	// apiUrl: 'http://localhost:3000/api/',
};
