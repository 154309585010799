import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-pagenotfound',
	templateUrl: './pagenotfound.component.html',
	styleUrls: ['./pagenotfound.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
	returnHome!: boolean;

	constructor(
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.returnHome = this.route.snapshot.queryParamMap.get('home') == 'true';
	}

	goBack(): void {
		this.returnHome ? this.router.navigate(['']) : this.location.back();
	}
}
