<nav>
	<a class="bg logo" [routerLink]="['/']"></a>
	<a
		*ngFor="let item of navItems"
		class="link"
		[routerLink]="[item.routerLink]"
		routerLinkActive
		#modules="routerLinkActive"
		[ngClass]="{ active: modules.isActive }"
	>
		{{ item.label | titlecase }}
	</a>
	<!-- <p class="farm-tag">Ganadería: {{ farm?.name || 'todas las ganaderías' | titlecase }}</p> -->
	<div class="navbar-info" (click)="onDisplayUserOptions()">
		<div *ngIf="this.association?.acronym" class="link">Asociación: {{ this.association?.acronym }}</div>
		<div *ngIf="this.farm?.acronym" class="link">Ganadería: {{ this.farm.acronym }}</div>
	</div>
	<div
		[matMenuTriggerFor]="appMenu"
		[ngClass]="{ active: displayedUserOptions }"
		class="bg user-options"
		(click)="onDisplayUserOptions()"
	>
		<img class="user-icon" src="../../../assets/images/icons/user.svg" alt="" />
		<img class="arrow-icon" src="../../../assets/images/icons/white-arrow.svg" alt="" />
		<div
			[matBadge]="countTotalBadge()"
			[matBadgeHidden]="isBadgeHidden()"
			matBadgePosition="above after"
			matBadgeOverlap="false"
			class="badge-navbar badge-navbar-color"
		></div>
	</div>

	<mat-menu #appMenu="matMenu">
		<div class="association-label">
			<p>Asociación: {{ association?.acronym || '-' }}</p>
			<p>Ganadería: {{ farm?.acronym || '-' }}</p>
		</div>
		<a mat-menu-item [routerLink]="userOption.link" *ngFor="let userOption of userOptions">
			<p>{{ userOption.label | titlecase }}</p>
			<div
				*ngIf="userOption.badge"
				[matBadge]="getCountBadge(userOption.label)"
				class="badge-navbar-color"
				[matBadgeHidden]="getCountBadgeIsHidden(userOption.label)"
				matBadgePosition="above after"
				matBadgeOverlap="true"
			></div>
		</a>
		<button mat-menu-item (click)="onLogout()" class="logout-btn">Cerrar sesión</button>
	</mat-menu>
</nav>

<app-sub-navbar></app-sub-navbar>
