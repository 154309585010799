import { Pipe, PipeTransform } from '@angular/core';
import { FileStatusEnum } from 'src/app/schemes/enums/file-status.enum';
import { UserFile } from 'src/app/services/file.service';

@Pipe({
	name: 'filePipe',
})
export class FilePipe implements PipeTransform {
	transform(value: FileStatusEnum, property?: keyof UserFile, ...args: unknown[]): string {
		if (property === 'status') {
			switch (value) {
				case FileStatusEnum.success:
					return 'Procesado';
				case FileStatusEnum.processing:
					return 'Procesando...';
				case FileStatusEnum.error:
					return 'Error';
				case FileStatusEnum.pending:
					return 'Pendiente de procesado';
				case FileStatusEnum.queue_error:
					return 'Error en la solicitud';
			}
		}
		return value;
	}
}
