<div class="redil-info dialog">
	<h2>Informe arca</h2>
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="drop-date">Fecha del informe</label>
					<p-calendar
						id="drop-date"
						appUseUtc
						[(ngModel)]="date"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'dd/mm/yyyy'"
						[maxDate]="maxDate"
					>
					</p-calendar>
				</div>
				<p-message *ngIf="showErrors && !date" severity="error" text="Debe elegir una fecha"></p-message>

				<div class="info-row">
					<label class="centered-text">Raza</label>
					<p-dropdown
						[options]="races"
						[(ngModel)]="race"
						placeholder="Selecciona una opción"
						optionLabel="name"
						[appendTo]="'body'"
					></p-dropdown>
				</div>

				<p-message *ngIf="showErrors && !race" severity="error" text="Debe elegir una raza"></p-message>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(true)" class="app-btn orange">Aceptar</button>
				<button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button>
			</div>
		</section>
	</div>
</div>
