import { Injectable } from '@angular/core';
import { AssociationModel } from '../schemes/models/association.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class AssociationProvider extends BaseProvider<AssociationModel> {
	private headers = {
		database: 'super',
	};

	constructor(http: HttpService) {
		super('associations', http, 'super');
	}

	getAll(params?): Promise<ResponseModel<AssociationModel[]>> {
		return this.http.get<ResponseModel<AssociationModel[]>>(`${this.collection}`, this.headers, params);
	}

	getById(id: string, params?): Promise<ResponseModel<AssociationModel>> {
		return this.http.get<ResponseModel<AssociationModel>>(`${this.collection}/${id}`, this.headers, params);
	}
}
