import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleEnum } from 'src/app/schemes/enums/modules.enum';
import { UserModel } from 'src/app/schemes/models/user.model';
import { ILink } from 'src/app/schemes/types/types';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
	selector: 'app-submodules-selector',
	templateUrl: './submodules-selector.component.html',
	styleUrls: ['./submodules-selector.component.scss'],
})
export class SubmodulesSelectorComponent implements OnInit {
	mod: string;
	actions: ILink[];
	submodules: ModuleEnum[];
	addFavourites: boolean;
	user: UserModel;
	favActions: ILink[];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private navigator: NavigatorService,
	) { }

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.mod = params.get('module');
			this.submodules = Object.values(ModuleEnum);
			this.actions = this.navigator.getModuleLinks(this.mod);
			this.navigator.getFavouriteLinks().subscribe(actions => this.favActions = actions);
		});

		this.route.queryParamMap.subscribe(async query => {
			this.addFavourites = query.has('add');
		});
	}

	goTo(link: string) {
		this.router.navigateByUrl(link);
	}

	updateActions(action, selected) {
		if (selected) {
			this.favActions.push(action);
		} else {
			this.favActions = this.favActions.filter(({ link }) => link !== action.link);
		}
		this.navigator.setFavouriteLinks(this.favActions);
	}

	actionIsFav(action) {
		if (!this.favActions) { return false; }
		return this.favActions.find(({ link }) => link === action.link);
	}
}
