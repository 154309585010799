<div class="container">
	<h2>Subir archivo</h2>
	<div class="body">
		<app-file-dropzone
			*ngIf="!file; else fileTemplate"
			[showButton]="false"
			message="Arrastrar archivo"
			(dropped)="onImportExcel($event)"
		>
		</app-file-dropzone>

		<ng-template #fileTemplate>
			<div class="file-container">
				<img src="assets/images/doc.svg" alt="document" />
				<p>{{ file.name }}</p>
			</div>
		</ng-template>
	</div>
	<div class="buttons">
		<button [disabled]="true" class="app-btn orange">{{ 'Aceptar' }}</button>
		<button (click)="onClose()" class="app-btn optional">{{ 'Cancelar' }}</button>
	</div>
</div>
