import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ES_VALIDATION_MESSAGES } from '../../../assets/messages/form-validation.message';
import { IFormInputValidator } from '../../schemes/types/form-inputs';
import { RendererService } from '../../services/renderer.service';
@Directive({ selector: '[formValidationMessage]' })
export class FormValidationDirective implements AfterViewInit {
	@Input('formValidationMessage') validator: IFormInputValidator;
	constructor(private elementRef: ElementRef, private rendererService: RendererService) {}

	ngAfterViewInit(): void {
		this.setValidationMessage();
	}

	setValidationMessage(): void {
		const { validatorName, details } = this.validator;
		let validationMessage = '';
		const hasProperty = Object.prototype.hasOwnProperty.call(ES_VALIDATION_MESSAGES, validatorName);
		if (!hasProperty) {
			validationMessage = ES_VALIDATION_MESSAGES.not_specified;
		} else {
			const validationAttribute = ES_VALIDATION_MESSAGES[validatorName];
			const isFunction = typeof validationAttribute === 'function';
			validationMessage = isFunction ? validationAttribute(details) : validationAttribute;
		}

		this.rendererService.renderProperty(this.elementRef, 'innerText', validationMessage);
	}
}
