export enum AnalysisStatusEnum {
	pending = 'pending',
	standby = 'standby',
	lab = 'lab',
	processed = 'processed',
	discarded = 'discarded',
}

export enum AnalysisTypeEnum {
	karyotype = 'karyotype',
	genotype = 'genotype',
	layer = 'layer',
	casein = 'casein',
	scrapie = 'scrapie',
	quality = 'quality',
	fatherFiliation = 'father_filiation',
	motherFiliation = 'mother_filiation',
}

export enum DirectAnalysisTypesEnum {
	fatherFiliation = 'father_filiation',
	motherFiliation = 'mother_filiation',
}

export enum LaboratoryEnum {
	meragem = 'meragem',
	nasertic = 'nasertic',
	citogenetica = 'citogenetica',
	nasertic_meragem = 'nasertic_meragem',
}
