import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { UtilsService } from '../../../../services/utils.service';

@Component({
	selector: 'app-filter-breedings-modal',
	templateUrl: './filter-breedings-modal.component.html',
	styleUrls: ['./filter-breedings-modal.component.scss'],
})
export class FilterBreedingsModalComponent implements OnInit {
	filters;
	farms: { label: string; value: string }[] = [];
	type: string;
	indexedFarms: { [key: string]: { label: string; value: string } };

	constructor(
		private router: Router,
		private farmProvider: FarmProvider,
		private activatedRoute: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterBreedingsModalComponent>,
		private utilsService: UtilsService,
	) { }

	async ngOnInit() {
		// Tipo;
		// Fecha suelta(desde);
		// Fecha recogida(hasta);
		// ganadería;
		this.type = this.activatedRoute.snapshot.queryParamMap.get('type');

		try {
			this.farms = (await this.farmProvider.getAll()).data
				.map(farm => ({
					label: farm.name,
					value: farm._id,
				}))
				.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
			this.indexedFarms = this.utilsService.indexArray(this.farms, 'value');
			this.filters = {
				farm: null,
				type: null,
			};
			this.setFiltersValues(this.data);
		} catch (error) {
			console.error(error);
		}
	}

	onSaveFilters() {
		this.filters.type = this.type;
		const urlFilters = {};
		urlFilters['type'] = this.filters.type;
		if (this.filters.farm) urlFilters['farm'] = this.filters.farm.value;
		this.dialogRef.close();
		this.router.navigate(['/book/breedings'], { queryParams: urlFilters });
	}

	onClearFilters() {
		this.filters = {
			name: null,
			races: null,
			details: {
				headline: null,
				mobilephone: null,
			},
		};
		// this.dialogRef.close();
		// this.router.navigate(['/book/breedings'], {queryParams: {type: this.type}});
	}

	setFiltersValues(data) {
		if (data.filters && data.filters.farm) this.indexedFarms[data.filters.farm];
	}
}
