<div class="summary">
	<h2>Procesado análisis</h2>
	<div class="errors">
		<div class="error-row" *ngFor="let summarySection of summary">
			<p>{{ summarySection.type | analysisPipe | titlecase }}</p>
			<div class="sub-section-cnt" *ngIf="summarySection.samples?.length > 0">
				<p *ngFor="let summarySectionSample of summarySection.samples">- {{ summarySectionSample }}</p>
			</div>
		</div>
	</div>
</div>
<div>
	<div class="btn-grp centered">
		<button class="app-btn primary" (click)="onClose(true)">Aceptar</button>
		<button class="app-btn optional" (click)="onClose(false)">Cancelar</button>
	</div>
</div>