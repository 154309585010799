<div
	class="form-input__container"
	*ngIf="formController"
	[ngClass]="layoutOrientation"
	[class.labeled]="inputLabel"
>
	<label *ngIf="inputLabel" class="form-input__label">{{ inputLabel }}</label>
	<ng-container [ngSwitch]="inputType">
		<ng-container *ngSwitchCase="inputTypeEnum.select">
			<p-autoComplete
				#autocomplete
				*ngIf="selectData"
				[class.invalid]="formController.invalid && formController.dirty"
				[suggestions]="filteredSelectData"
				(completeMethod)="onSelectComplete($event)"
				[dropdown]="true"
				[forceSelection]="true"
				[(ngModel)]="selectedData"
				[disabled]="disabled"
				(ngModelChange)="onChangeSelection($event)"
				(onFocus)="onShowErrors(true)"
				(onHide)="onShowErrors(true)"
				(onBlur)="onShowErrors(false)"
				(onShow)="onShowErrors(false)"
				[placeholder]="placeholder"
			>
			</p-autoComplete>
		</ng-container>
		<ng-container *ngSwitchCase="inputTypeEnum.multiselect">
			<p-multiSelect
				*ngIf="selectData"
				[(ngModel)]="selectedMultiselectData"
				[disabled]="disabled"
				(ngModelChange)="onChangeMultiSelection($event)"
				[options]="selectData"
				[class.invalid]="formController.invalid && formController.dirty"
				(onPanelShow)="onShowErrors(true)"
				(onBlur)="onShowErrors(false)"
				[virtualScroll]="true"
				itemSize="30"
				filterBy="displayValue"
			>
				<ng-template let-selected pTemplate="selectedItems">
					<span *ngIf="selected && selected.length <= 3 && selectedMultiselectDataReadable">{{
						selectedMultiselectDataReadable.join(', ')
					}}</span>
					<span *ngIf="selected && selected.length > 3 && selectedMultiselectDataReadable"
						>{{ selectedMultiselectDataReadable.length }} opciones seleccionadas</span
					>
					<span *ngIf="!selected || selected.length === 0">{{
						placeholder ? placeholder : 'Sin selección'
					}}</span>
				</ng-template>
				<ng-template let-selectData pTemplate="item">
					<span>{{ selectData.displayValue ? selectData.displayValue : selectData.value }}</span>
				</ng-template>
			</p-multiSelect>
		</ng-container>
		<p-calendar
			*ngSwitchCase="inputTypeEnum.date"
			class="date-element"
			[class.invalid]="formController.invalid && formController.dirty"
			[formControl]="formController"
			[locale]="ngPrimeLocale"
			dateFormat="dd/mm/yy"
			[showIcon]="true"
			(onFocus)="onShowErrors(true)"
			(onBlur)="onShowErrors(false)"
			(onSelect)="onDateSelected($event)"
			[placeholder]="placeholder"
			[maxDate]="maxDate"
			[minDate]="minDate"
		>
		</p-calendar>
		<textarea
			*ngSwitchCase="inputTypeEnum.textarea"
			class="textarea-element"
			[formControl]="formController"
			[class.invalid]="formController.invalid && formController.dirty"
			pInputTextarea
			(focus)="onShowErrors(true)"
			(focusout)="onShowErrors(false)"
			[placeholder]="placeholder"
		>
		</textarea>
		<div class="pwd-input__container" *ngSwitchCase="inputTypeEnum.password">
			<input
				class="input-element"
				[class.invalid]="formController.invalid && formController.dirty"
				[formControl]="formController"
				[placeholder]="placeholder"
				[feedback]="false"
				[showPassword]="showPassword"
				(focus)="onShowErrors(true)"
				(focusout)="onShowErrors(false)"
				pPassword
			/>
			<div *ngIf="canTogglePwd" class="pwd-icon__container" (click)="onTogglePwdVisibility()">
				<img class="pwd-icon__icon" [src]="getPwdIcon()" alt="show-pwd-icon" />
			</div>
		</div>
		<input
			*ngSwitchDefault
			class="ui-inputtext input-element"
			[class.invalid]="formController.invalid && formController.dirty"
			[type]="inputType"
			[formControl]="formController"
			pInputText
			(focus)="onShowErrors(true)"
			(focusout)="onShowErrors(false)"
			[placeholder]="placeholder"
		/>
	</ng-container>
	<div
		class="error-container"
		[ngClass]="{ 'error-container-outlabel': !inputLabel }"
		[hidden]="controllerErrors && showErrors"
		[attr.show-errors]="showErrors && formController.dirty"
	>
		<span class="error-message" *ngFor="let error of controllerErrors" [formValidationMessage]="error"></span>
	</div>
</div>
