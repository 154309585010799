import { Injectable } from '@angular/core';
import { RanchModel } from '../schemes/models/ranch.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class RanchProvider extends BaseProvider<RanchModel> {
	constructor(http: HttpService) {
		super('ranchs', http);
	}

	newRanch(): RanchModel {
		return null;
		// return {
		// 	rega: '',
		// 	farm: '',
		// 	name: '',
		// 	region: '',
		// 	location: {
		// 		lat: 0,
		// 		lon: 0,
		// 	},
		// 	surface: 0,
		// 	vetInspection: '',
		// 	brucelosis: '',
		// 	tuberculosis: '',
		// };
	}
}
