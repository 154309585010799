import {
	Component, OnInit, OnDestroy, Output, EventEmitter, Input,
} from '@angular/core';
import { Subject, Subscription, of } from 'rxjs';
import {
	map, debounceTime, distinctUntilChanged, mergeMap, delay,
} from 'rxjs/operators';

@Component({
	selector: 'app-global-filter',
	templateUrl: './global-filter.component.html',
	styleUrls: ['./global-filter.component.scss'],
})
export class GlobalFilterComponent implements OnInit, OnDestroy {
	public keyUp = new Subject<KeyboardEvent>();
	subscription: Subscription;
	@Output() onQuery: EventEmitter<string> = new EventEmitter();
	@Input() inputPlaceholder: string = 'Buscar...';
	constructor() {
		this.subscription = this.keyUp.pipe(
			map((event: any) => event.target.value),
			debounceTime(1000),
			distinctUntilChanged(),
			mergeMap(search => of(search).pipe(
				delay(100),
			)),
		).subscribe(query => this.onQuery.next(query));
	}

	ngOnInit() {

	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
