import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MessageService } from 'primeng';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalysisProcessingSummaryComponent } from './components/dialogs/analysis-processing-summary/analysis-processing-summary.component';
import { ArcaFormComponent } from './components/dialogs/arca-form/arca-form.component';
import { ArcaSectionsFormComponent } from './components/dialogs/arca-sections-form/arca-sections-form.component';
import { BirthCodeModificationComponent } from './components/dialogs/birth-code-modification/birth-code-modification.component';
import { CalculateGenbookOptionsComponent } from './components/dialogs/calculate-genbook-options/calculate-genbook-options.component';
import { CalculateGenbookSummaryComponent } from './components/dialogs/calculate-genbook-summary/calculate-genbook-summary.component';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { DeathFormComponent } from './components/dialogs/death-form/death-form.component';
import { DropFarmComponent } from './components/dialogs/drop-farm/drop-farm.component';
import { ExportFilterOptionsComponent } from './components/dialogs/export-filter-options/export-filter-options.component';
import { FilterAnalysesModalComponent } from './components/dialogs/filters/filter-analyses-modal/filter-analyses-modal.component';
import { FilterAnimalsModalComponent } from './components/dialogs/filters/filter-animals-modal/filter-animals-modal.component';
import { FilterBirthsModalComponent } from './components/dialogs/filters/filter-births-modal/filter-births-modal.component';
import { FilterBreedingsModalComponent } from './components/dialogs/filters/filter-breedings-modal/filter-breedings-modal.component';
import { FilterDnaAnimalsModalComponent } from './components/dialogs/filters/filter-dna-animals-modal/filter-dna-animals-modal.component';
import { FilterFarmsModalComponent } from './components/dialogs/filters/filter-farms-modal/filter-farms-modal.component';
import { FilterProposalsModalComponent } from './components/dialogs/filters/filter-proposals-modal/filter-proposals-modal.component';
import { FilterRanchesModalComponent } from './components/dialogs/filters/filter-ranches-modal/filter-ranches-modal.component';
import { FilterSamplesModalComponent } from './components/dialogs/filters/filter-samples-modal/filter-samples-modal/filter-samples-modal.component';
import { GenbookFormComponent } from './components/dialogs/genbook-form/genbook-form.component';
import { ImportSummaryComponent } from './components/dialogs/import-summary/import-summary.component';
import { InfoComponent } from './components/dialogs/info/info.component';
import { NewFolderComponent } from './components/dialogs/new-folder/new-folder.component';
import { PromptComponent } from './components/dialogs/prompt/prompt.component';
import { QualificationFormComponent } from './components/dialogs/qualification-form/qualification-form.component';
import { CensusReportComponent } from './components/dialogs/reports/census-report/census-report.component';
import { ZootechnicalReportComponent } from './components/dialogs/reports/zootechnical-report/zootechnical-report.component';
import { ResurrectFormComponent } from './components/dialogs/resurrect-form/resurrect-form.component';
import { SampleSplittingModalComponent } from './components/dialogs/sample-splitting-modal/sample-splitting-modal.component';
import { TransferenceFormComponent } from './components/dialogs/transference-form/transference-form.component';
import { UploadDocComponent } from './components/dialogs/upload-doc/upload-doc.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SubNavbarComponent } from './components/navbar/sub-navbar/sub-navbar.component';
import { AssociationSelectorComponent } from './pages/association-selector/association-selector.component';
import { FarmSelectorComponent } from './pages/farm-selector/farm-selector.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { ModulesSelectorComponent } from './pages/modules-selector/modules-selector.component';
import { PagenotallowedComponent } from './pages/pagenotallowed/pagenotallowed.component';
import { PageNotFoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PagenotyetComponent } from './pages/pagenotyet/pagenotyet.component';
import { SubmodulesSelectorComponent } from './pages/submodules-selector/submodules-selector.component';
import {
	AuthTokenHttpInterceptorProvider,
	ErrorHandlerHttpInterceptor,
} from './services/http-interceptor.service';
import { AppAbility } from './shared/app-abilities';
import { LoaderInterceptor } from './shared/loader.interceptor';
import { SharedModule } from './shared/shared.module';
import { FilterWeightModalComponent } from './components/dialogs/filters/filter-weight-modal/filter-weight-modal.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		MainComponent,
		AssociationSelectorComponent,
		PageNotFoundComponent,
		ModulesSelectorComponent,
		SubmodulesSelectorComponent,
		SubNavbarComponent,
		ExportFilterOptionsComponent,
		FarmSelectorComponent,
		ImportSummaryComponent,
		PagenotyetComponent,
		PagenotallowedComponent,
		DeathFormComponent,
		ConfirmComponent,
		InfoComponent,
		FilterAnimalsModalComponent,
		FilterBirthsModalComponent,
		FilterBreedingsModalComponent,
		FilterFarmsModalComponent,
		FilterRanchesModalComponent,
		QualificationFormComponent,
		TransferenceFormComponent,
		DropFarmComponent,
		CensusReportComponent,
		ZootechnicalReportComponent,
		FilterProposalsModalComponent,
		NavbarComponent,
		ResurrectFormComponent,
		GenbookFormComponent,
		PromptComponent,
		BirthCodeModificationComponent,
		ArcaFormComponent,
		AnalysisProcessingSummaryComponent,
		SampleSplittingModalComponent,
		FilterAnalysesModalComponent,
		FilterSamplesModalComponent,
		FilterDnaAnimalsModalComponent,
		ArcaSectionsFormComponent,
		UploadDocComponent,
		NewFolderComponent,
		CalculateGenbookSummaryComponent,
		CalculateGenbookOptionsComponent,
		FilterWeightModalComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireDatabaseModule,
		AngularFireStorageModule,
		SharedModule,
		NgxUiLoaderModule,
		MatBadgeModule,
		AbilityModule,
	],
	providers: [
		AuthTokenHttpInterceptorProvider,
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		MessageService,
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerHttpInterceptor, multi: true },
		{ provide: AppAbility, useValue: new AppAbility() },
		{ provide: PureAbility, useExisting: AppAbility },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
