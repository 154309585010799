import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'user',
})
export class UserPipe implements PipeTransform {
	transform(value: string, prop: string, ...args: unknown[]): string {
		switch (prop) {
			case 'role':
				return this.translateRoles(value);
			default:
				return value;
		}
	}

	private translateRoles(role: string): string {
		switch (role) {
			case 'farmer':
				return 'Ganadero';
			case 'tech':
				return 'Técnico';
			case 'admin':
				return 'Administrador asociación';
			case 'super':
				return 'Usuario GESCAN';
			default:
				return role;
		}
	}
}
