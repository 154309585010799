import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	constructor(public ngxLoader: NgxUiLoaderService) { }

	public startBasicLoader(): void {
		this.ngxLoader.start();
	}

	public stopBasicLoader(): void {
		this.ngxLoader.stop();
	}

	public startLoader() { }

	public stopLoader() { }
}
