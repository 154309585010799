import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CUSTOM_FORM_ERRORS_ID } from '../schemes/types/form-validation';

@Injectable({
	providedIn: 'root',
})
export class FormUtilsService {
	constructor() {}

	convertToFormControl(controller: AbstractControl): FormControl {
		return controller as FormControl;
	}

	setUniqueErrorToFormControl(controller: AbstractControl): void {
		controller.setErrors({ [CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_NOT_UNIQUE_ID]: true });
		if (!controller.dirty) this.markControllerAsDirty(controller);
	}

	setCustomErrorToFormControl(controller: AbstractControl, msg: string): void {
		controller.setErrors({ [CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_MESSSAGE_ID]: msg });
		if (!controller.dirty) this.markControllerAsDirty(controller);
	}

	markControllerAsDirty(controller: AbstractControl): void {
		controller.markAsDirty();
	}

	markFormAsDirty(formGroup: FormGroup): void {
		Object.values(formGroup.controls).forEach((control: AbstractControl | FormArray) => {
			if ((control as FormArray).controls) {
				this.markFormAsDirty(control as FormGroup);
			} else {
				control.markAsDirty();
				if (!(control as AbstractControl).value?.toString()) {
					(control as AbstractControl).setValue('');
				}
			}
		});
	}

	clearValidators(controller: AbstractControl): void {
		controller.clearValidators();
		controller.updateValueAndValidity();
	}

	addValidators(controller: AbstractControl, newValidator: ValidatorFn | ValidatorFn[]): void {
		controller.setValidators(newValidator);
		controller.updateValueAndValidity();
	}
}
