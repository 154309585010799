import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-pagenotallowed',
	templateUrl: './pagenotallowed.component.html',
	styleUrls: ['./pagenotallowed.component.scss'],
})
export class PagenotallowedComponent implements OnInit {
	constructor() { }

	ngOnInit(): void {
	}

	reloadWindowWithoutCache() {
		location.reload(true);
	}
}
