import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class TimeService {
	static parseDate(dateToParse: string | Date | number | null): Date | null {
		if (!dateToParse && dateToParse !== 0) {
			return null;
		}

		if (typeof dateToParse === 'number') {
			return new Date(
				new Date(dateToParse).setHours(12 - new Date(dateToParse).getTimezoneOffset() / 60, 0, 0, 0)
			);
		}

		if (dateToParse instanceof Date) {
			return new Date(dateToParse.setHours(12 - new Date(dateToParse).getTimezoneOffset() / 60, 0, 0, 0));
		}

		if (typeof dateToParse === 'string') {
			const possibleFormats = ['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', ''];
			for (const format of possibleFormats) {
				const momentDate = moment(dateToParse, format, true);
				if (momentDate.isValid()) {
					return new Date(
						momentDate.toDate().setHours(12 - new Date(dateToParse).getTimezoneOffset() / 60, 0, 0, 0)
					);
				}
			}
			// last try, transform to date and then to moment
			const dateObj = new Date(dateToParse);
			const momentDate = moment(dateObj);
			if (momentDate.isValid()) {
				return new Date(
					momentDate.toDate().setHours(12 - new Date(dateToParse).getTimezoneOffset() / 60, 0, 0, 0)
				);
			}

			console.error(`Parsing invalid date (string): ${dateToParse}`);
			return null;
		}
		const date = Date.parse(`${dateToParse}`);
		if (Number.isNaN(date)) {
			console.error(`Parsing Invalid date (number): ${dateToParse}`);
			return null;
		}
		return new Date(
			moment(date)
				.toDate()
				.setHours(12 - new Date(dateToParse).getTimezoneOffset() / 60, 0, 0, 0)
		);
	}

	dateToString(dateToParse: string | Date | null): string {
		if (!dateToParse) {
			return '-';
		}
		if (dateToParse instanceof Date) {
			return moment(dateToParse).startOf('day').format('DD/MM/YYYY');
		}

		if (typeof dateToParse === 'string') {
			const possibleFormats = ['DD/MM/YYYY', 'DD-MM-YYYY', ''];
			for (const format of possibleFormats) {
				const momentDate = moment(dateToParse, format, true);
				if (momentDate.isValid()) {
					return momentDate.startOf('day').format('DD/MM/YYYY');
				}
			}

			// last try, transform to date and then to moment
			const dateObj = TimeService.parseDate(dateToParse);
			const momentDate = moment(dateObj, true);
			if (momentDate.isValid()) {
				return momentDate.startOf('day').format('DD/MM/YYYY');
			}

			console.error(`Parsing invalid date (string): ${dateToParse}`);
			return '-';
		}

		const date = Date.parse(`${dateToParse}`);
		if (Number.isNaN(date)) {
			console.error(`Parsing Invalid date (number): ${dateToParse}`);
			return '-';
		}
		return moment(date).startOf('day').format('DD/MM/YYYY');
	}

	static getActualDate(): Date {
		return new Date(
			new Date().setHours(
				new Date().getUTCHours() - new Date().getTimezoneOffset() / 60,
				new Date().getUTCMinutes(),
				new Date().getUTCSeconds(),
				0
			)
		);
	}
}
