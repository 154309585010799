<div class="checkbox" [class.labeled]="inputLabel">
	<label *ngIf="inputLabel" class="checkbox__label">{{ inputLabel }}</label>
	<p-checkbox
		class="checkbox__check"
		[formControl]="formController"
		(onChange)="onChecked($event.checked)"
		[binary]="true"
	>
	</p-checkbox>
</div>
