import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-calculate-genbook-summary',
	templateUrl: './calculate-genbook-summary.component.html',
	styleUrls: ['./calculate-genbook-summary.component.scss'],
})
export class CalculateGenbookSummaryComponent implements OnInit {
	numberOfChangedAnimals: number;
	noChangedAnimals: boolean = false;
	changedAnimals = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<CalculateGenbookSummaryComponent>
	) {}

	ngOnInit(): void {
		this.changedAnimals = this.data.data;
		this.numberOfChangedAnimals = this.data.data.length;
		if (this.numberOfChangedAnimals === 0) this.noChangedAnimals = true;
	}

	onClose(): void {
		this.dialogRef.close();
	}

	onExportDetails(): void {
		this.dialogRef.close(this.changedAnimals);
	}
}
