import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { GenBookRulesProvider } from 'src/app/providers/gen-book-rule.provider';
import { RaceProvider } from 'src/app/providers/race.provider';
import { RanchProvider } from 'src/app/providers/ranch.provider';
import { GenderEnum } from 'src/app/schemes/enums/gender.enum';
import { RoleEnum } from 'src/app/schemes/enums/role.enum';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { RaceModel } from 'src/app/schemes/models/race.model';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { FarmService } from 'src/app/services/farm.service';
import { UtilsService } from 'src/app/services/utils.service';
import { TimeService } from '../../../../services/time.service';
import ngPrimeLocale from '../../../../../assets/locale/ngprime.json';
import { FilterAnimalsModalComponent } from '../filter-animals-modal/filter-animals-modal.component';
import { AnimalModel } from '../../../../schemes/models/animal.model';

@Component({
	selector: 'app-filter-dna-animals-modal',
	templateUrl: './filter-dna-animals-modal.component.html',
	styleUrls: ['./filter-dna-animals-modal.component.scss'],
})
export class FilterDnaAnimalsModalComponent implements OnInit {
	genders: { name: string; value: GenderEnum }[];
	farms: FarmModel[];
	selectedOwnerFarm: any;
	selectedBreederFarm: any;
	// ranchs: RanchModel[];
	races: RaceModel[];
	racesInFarm: RaceModel[];
	selectedRace: any;

	es = ngPrimeLocale;
	filters: { [key in keyof AnimalModel]?: any };
	filteredFarms: FarmModel[];
	currentUser: UserModel;
	roleEnum = RoleEnum;
	active = false;
	ranches: { name: string; value: string }[];
	filteredRanches: { name: string; value: string }[];
	indexedFarms: { [key: string]: FarmModel } = {};
	indexedRanches: {
		[key: string]: {
			name: string;
			value: string;
		};
	} = {};

	indexedGenBooks: {
		[key: string]: {
			name: string;
			value: string;
		};
	} = {};

	currentFarm: FarmModel;
	genBooks: any;
	filteredGenBooks: any;
	urlToNavigate: string = '';

	constructor(
		private farmProvider: FarmProvider,
		private farmService: FarmService,
		private ranchProvider: RanchProvider,
		private genBookProvider: GenBookRulesProvider,
		private racesProvider: RaceProvider,
		private router: Router,
		private authService: AuthService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterAnimalsModalComponent>,
		private utilsService: UtilsService,
	) {
		this.filters = {
			officialId: null,
			crotal: null,
			eId: null,
			name: null,
			birthDate: {
				start: null,
				end: null,
			},
			dropDate: {
				start: null,
				end: null,
			},
			ownerFarmRanch: null,
			races: null,
			gender: null,
		};
		this.genders = [
			{ name: 'Hembra', value: GenderEnum.female },
			{ name: 'Macho', value: GenderEnum.male },
		];
	}

	async ngOnInit() {
		try {
			this.currentUser = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
			this.currentFarm = await this.farmService.getCurrentFarm();
			await this.setRanches();
			this.urlToNavigate = this.data.url;
			this.setFiltersValues(this.data.filters);
			this.active = this.data.active;
			if (this.filters.dropDate === null) this.active = true;
		} catch (error) {
			console.error(error);
		}
	}

	async setRanches() {
		const ranchs = this.currentFarm
			? (await this.ranchProvider.getAll({ farm: this.currentFarm._id, _sort: 'rega' })).data
			: (await this.ranchProvider.getAll({ _sort: 'rega' })).data;
		this.ranches = ranchs.map(ranch => ({ name: ranch.rega, value: ranch._id }));
		this.ranches.forEach(ranch => {
			this.indexedRanches[ranch.value] = ranch;
		});
		this.filteredRanches = this.ranches;
	}

	searchFarm($event) {
		this.filteredFarms = this.farms.filter(farm => farm.name.toLowerCase().indexOf($event.query.toString().toLowerCase()) != -1);
	}

	searchRanches($event) {
		this.filteredRanches = this.ranches.filter(rega => rega.name.toLowerCase().includes($event.query.toString().toLowerCase()));
	}

	searchGenBooks($event) {
		this.filteredGenBooks = this.genBooks.filter(gb => gb.name.toLowerCase().includes($event.query.toString().toLowerCase()));
	}

	onSaveFilters() {
		const urlFilters = this.parseFilters(this.filters);
		urlFilters['active'] = this.active;
		this.dialogRef.close(urlFilters);
		this.router.navigate([this.urlToNavigate], { queryParams: urlFilters });
	}

	// Parses start and end dates, value on object types ($eq) and ($like) on string types
	parseFilters(filters: Object) {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (value !== false && !value) {
				return newFilters;
			}
			if (key === 'blocked' && value) {
				newFilters[`${key}`] = value;
				return newFilters;
			}
			if (key.toLowerCase().includes('date')) {
				if (value.start) newFilters[`${key}_gte`] = value.start;
				if (value.end) {
					newFilters[`${key}_lte`] = value.end;
					newFilters[`${key}_lte`];
				}
			} else if (typeof value === 'string') {
				newFilters[`${key}_like`] = value;
			} else {
				if (value.value !== false && !value.value) {
					return newFilters;
				}
				newFilters[`${key}`] = value.value;
			}
			return newFilters;
		}, {});
	}

	onClearFilters() {
		this.filters = {
			officialId: null,
			crotal: null,
			eId: null,
			name: null,
			birthDate: {
				start: null,
				end: null,
			},
			dropDate: {
				start: null,
				end: null,
			},
			ownerFarmRanch: null,
			races: null,
			gender: null,
		};
		this.active = false;
	}

	setFiltersValues(data) {
		Object.keys(data).forEach(key => {
			const keys = key.split('_');
			if (keys.length == 1) {
				if (key === 'blocked') this.filters[keys[0]] = data[key];
				if (key === 'gender') {
					this.filters[keys[0]] = data[key] == 'H' ? { name: 'Hembra', value: GenderEnum.female } : { name: 'Macho', value: GenderEnum.male };
				} else if (key == 'dropDate' && data[keys[0]] === null) {
					this.active = true;
					this.filters.dropDate = { start: null, end: null };
				} else if (key == 'ownerFarmRanch') {
					this.filters.ownerFarmRanch = this.indexedRanches[data.ownerFarmRanch];
				} else if (key == 'genBook') {
					this.filters.genBook = this.indexedRanches[data.genBook];
				}
			} else if (keys.length == 2) {
				if (keys[1] == 'gte') this.filters[keys[0]].start = TimeService.parseDate(data[key]);
				else if (keys[1] == 'lte') this.filters[keys[0]].end = TimeService.parseDate(data[key]);
				else if (keys[0] != '') this.filters[keys[0]] = data[key];
			}
		});
	}
}
