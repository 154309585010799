<h2>Filtrar Partos</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="">Solo animales activos</label>
					<div class="checkbox-cnt">
						<input type="checkbox" [(ngModel)]="active" />
					</div>
				</div>
				<div class="info-row">
					<label for="input-date">Identificación Madre</label>
					<input id="input-officialId" class="ui-inputtext" type="text"
						[(ngModel)]="filters.mother.officialId" pInputText />
				</div>
				<div class="info-row">
					<label for="input-date">Crotal Madre</label>
					<input id="input-officialId" class="ui-inputtext" type="text" [(ngModel)]="filters.mother.crotal"
						pInputText />
				</div>
				<div class="info-row">
					<label for="input-date">ID Provisional</label>
					<input id="input-officialId" class="ui-inputtext" type="text" [(ngModel)]="filters.children.provId"
						pInputText />
				</div>
				<div class="info-row">
					<label for="input-birthtype">Tipo de parto</label>
					<p-autoComplete [(ngModel)]="filters.type" [suggestions]="filteredBirthType" [dropdown]="true"
						[forceSelection]="true" [appendTo]="'body'" field="name"
						(completeMethod)="searchBirthTypes($event)">
					</p-autoComplete>
				</div>
				<div class="info-row">
					<label for="input-date">Fecha</label>
					<p>Desde:</p>
					<p-calendar id="birth-date-start" appUseUtc [(ngModel)]="filters.date.start"
						[locale]="es.calendar_es" dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'"
						[placeholder]="'24/06/1998'"></p-calendar>
					<p>Hasta:</p>
					<p-calendar id="birth-date-end" appUseUtc [(ngModel)]="filters.date.end" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'">
					</p-calendar>
				</div>
				<div class="info-row" *ngIf="!active">
					<label for="drop-date">Fecha de baja</label>
					<p>Desde:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.motherDropDate.start"
						[locale]="es.calendar_es" dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'"
						[placeholder]="'24/06/1998'"></p-calendar>
					<p>Hasta:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.motherDropDate.end"
						[locale]="es.calendar_es" dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'"
						[placeholder]="'24/06/1998'"></p-calendar>
				</div>
				<!-- 
                <div class="info-row">
                    <label for="input-officialId">ID de la Madre</label>
                    <input id="input-officialId" class="ui-inputtext" type="text" [(ngModel)]="filters.mother.officialId"
                        pInputText>

                </div>

                <div class="info-row">
                    <label for="input-children">Nº de Crias</label>
                    <input id="input-children" class="ui-inputtext" type="number" [(ngModel)]="filters.children"
                        pInputText>
                </div> -->
			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>