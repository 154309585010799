<div class="summary" *ngIf="!noChangedAnimals">
	<h2>Se ha modificado la categoría de libro de {{ numberOfChangedAnimals }} animales</h2>

	<button class="app-btn tertiary excel-btn" (click)="onExportDetails()">Exportar cambios</button>
</div>

<div class="summary" *ngIf="noChangedAnimals">
	<h2>No se ha modificado la categoría de libro de ningún animal</h2>

	<button class="app-btn tertiary excel-btn" (click)="onClose()">Ok</button>
</div>
