<h2>Filtrar Pesadas</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-officialId">Identificación Animal o Crotal</label>
					<input
						id="input-officialId"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="filters.animal.officialId"
						pInputText
					/>
				</div>
				<div class="info-row">
					<label for="weight">Peso Mínimo</label>
					<p>Desde</p>
					<input
						id="input-min"
						class="ui-inputtext"
						type="Number"
						[(ngModel)]="filters.weight.min"
						(ngModelChange)="filters.weight.min = filters.weight.min ? +filters.weight.min : null"
						placeholder="Introduzca peso mínimo"
						pInputText
					/>
				</div>
				<div class="info-row">
					<label for="weight">Peso Máximo</label>
					<p>Hasta</p>
					<input
						id="input-max"
						class="ui-inputtext"
						type="Number"
						[(ngModel)]="filters.weight.max"
						(ngModelChange)="filters.weight.max = filters.weight.max ? +filters.weight.max : null"
						placeholder="Introduzca peso máximo"
						pInputText
					/>
				</div>
				<div class="info-row">
					<label for="date">Fecha de Pesada</label>
					<p>Desde:</p>
					<p-calendar
						id="date"
						appUseUtc
						[(ngModel)]="filters.date.start"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'19/03/1995'"
					></p-calendar>
					<p>Hasta:</p>
					<p-calendar
						id="date"
						appUseUtc
						[(ngModel)]="filters.date.end"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'01/01/2024'"
					>
					</p-calendar>
				</div>
			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>
