<h2>Nueva muestra</h2>
<div class="redil-info" *ngIf="newSample">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-date">Código de barras</label>
					<input id="input-officialId" class="ui-inputtext" type="text" [(ngModel)]="newSample.code"
						pInputText />
				</div>

				<p-message *ngIf="validated && !newSample.code" severity="error" text="Código requerido"></p-message>
				<p-message *ngIf="validated && sampleCodeIsDuplicated()" severity="error" text="Código repetido">
				</p-message>

				<div class="info-row">
					<label for="input-date">Cantidad</label>
					<input id="input-officialId" class="ui-inputtext" type="text" [(ngModel)]="newSample.quantity"
						pInputText />
					<span class="quantity-span">{{ newSample.type | samplePipe: 'typeUnit' }}</span>
				</div>

				<p-message *ngIf="validated && (newSample.quantity < 0 || newSample.quantity > previousSample.quantity)"
					severity="error"
					[text]="'La cantidad tiene que ser inferior a la cantidad de la muestra original (max. ' + previousSample.quantity + 'ML)'">
				</p-message>
			</div>
		</section>
		<div class="centered">
			<button (click)="onDivideSample()" class="app-btn orange">Dividir muestras</button>
			<button (click)="onClose(false)" class="app-btn primary">Cancelar</button>
		</div>
	</div>
</div>