import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RanchModel } from 'src/app/schemes/models/ranch.model';
import { StringUtilsService } from 'src/app/services/string-utils.service';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { RanchProvider } from '../../../providers/ranch.provider';
import { TimeService } from '../../../services/time.service';

@Component({
	selector: 'app-transference-form',
	templateUrl: './transference-form.component.html',
	styleUrls: ['./transference-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TransferenceFormComponent implements OnInit {
	transference: {
		date: Date;
		originRanch: { name: string; value: string };
		destinationRanch: string;
	};

	ranchs: RanchModel[];
	ranches: { name: string; value: string }[];
	dataLoaded: boolean;
	showErrors: boolean = false;
	dropDate: Date;
	birthDate: Date;
	originRanchName: string = '';

	es = ngPrimeLocale;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<TransferenceFormComponent>,
		private ranchProvider: RanchProvider,
		private strUtilsService: StringUtilsService
	) {}

	// TODO: Componenten pendiente de refactorizar

	async ngOnInit(): Promise<void> {
		try {
			this.dataLoaded = false;
			this.dropDate = this.data.dropDate ? new Date(this.data.dropDate) : null;
			this.birthDate = this.data.birthDate;
			const { data: ranchs } = await this.ranchProvider.getAll();
			this.transference = this.data.transference;
			this.ranchs = ranchs || [];
			this.ranchs.forEach(ranch => {
				if (!this.ranches) this.ranches = [];
				if (ranch._id === this.data.transference.originRanch._id) {
					this.transference.originRanch = { name: ranch.rega, value: ranch._id };
					this.originRanchName = ranch.rega;
				}
				this.ranches.push({ name: ranch.rega, value: ranch._id });
			});
			this.ranches = this.ranches.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			this.dataLoaded = true;
		} catch (err) {
			this.dataLoaded = true;
		}
	}

	onSelectComplete(event: { query: string }): void {
		const inputNormalized = this.strUtilsService.normalize(event.query).toLowerCase();
		this.ranches = [];
		this.ranches = this.ranchs.reduce((total, current) => {
			const value = current.rega;
			const dataNormalized = this.strUtilsService.normalize(value).toLowerCase();
			if (
				dataNormalized.includes(inputNormalized) &&
				current._id.toString() !== this.transference.originRanch.value.toString()
			) {
				total.push({ name: current.rega, value: current._id });
			}
			return total;
		}, [] as { name: string; value: string }[]);
	}

	onDialogClose(res): void {
		if (res) {
			this.showErrors = true;
			if (!res.destinationRanch || !this.validateQualificationDate()) return;
			res.originRanch = res.originRanch.value;
			res.destinationRanch = res.destinationRanch.value;
		}
		this.dialogRef.close(res);
	}

	validateQualificationDate(): boolean {
		return (
			this.transference.date &&
			this.transference.date.getTime() >= TimeService.parseDate(this.birthDate).getTime() &&
			(!this.dropDate || this.transference.date.getTime() <= this.dropDate.getTime())
		);
	}
}
