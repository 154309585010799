<h2>Filtrar Propuestas</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info" *ngIf="filters">
				<div class="info-row">
					<label for="input-gender">Ganadería</label>
					<p-dropdown [options]="farms" [(ngModel)]="filters.farm" placeholder="Selecciona una opción"
						optionLabel="name" [style]="{ width: '100%' }" [appendTo]="'body'"></p-dropdown>
				</div>

				<div class="info-row">
					<label for="input-gender">Tipo de propuesta</label>
					<p-dropdown [options]="proposalTypes" [(ngModel)]="filters.type" placeholder="Selecciona una opción"
						optionLabel="name" [style]="{ width: '100%' }" [appendTo]="'body'"></p-dropdown>
				</div>
				<!-- 
                  <div class="info-row">
                    <label for="input-gender">Entidad</label>
                    <p-dropdown [options]="proposalEntities" [(ngModel)]="filters.entityName" placeholder="Selecciona una opción"
                      optionLabel="name" [style]="{'width':'100%'}" [appendTo]="'body'"></p-dropdown>
                  </div> -->
			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>