import { AnalysisModel } from './analysis.model';
import { AnimalModel } from './animal.model';

export interface SampleModel {
	_id?: string;
	analysis?: string | AnalysisModel;
	originalAnalysis?: string | AnalysisModel;
	originalSample?: string | SampleModel;
	animal: string | AnimalModel;
	code: string;
	quantity: number;
	type: SampleTypeEnum;
	createdAt: Date;
	storeDate?: Date;
	storeId?: string;
	standById?: string;
}

export enum SampleTypeEnum {
	Blood = 'blood',
	Semen = 'semen',
	Hair = 'hair',
}
