import { Pipe, PipeTransform } from '@angular/core';
import { SampleTypeEnum } from '../../schemes/models/sample.model';

@Pipe({
	name: 'samplePipe',
})
export class SamplePipe implements PipeTransform {
	transform(value: string | SampleTypeEnum, property: string, ...args: unknown[]): string {
		if (property === 'typeUnit') {
			return value === SampleTypeEnum.Hair ? 'Unidades' : 'ML';
		}
		return value;
	}
}
