import { Pipe, PipeTransform } from '@angular/core';
import importErrorsJson from '../../../assets/translate/import-errors.json';

@Pipe({
	name: 'importErrors',
})
export class ImportErrorsPipe implements PipeTransform {
	transform(value: string): string {
		return importErrorsJson[value] as string;
	}

	replace(value: string): string {
		if (!value || typeof value !== 'string') {
			return '';
		}
		Object.entries(importErrorsJson).forEach(([key, replaceValue]) => {
			if (value.includes(key)) {
				value = this.replaceAll(value, key, replaceValue);
			}
		});
		return value;
	}

	replaceAll(value: string, search: string, replace: string) {
		return value.split(search).join(replace);
	}
}
