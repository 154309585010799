import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import analysisTranslate from '../../../assets/translate/analysis.json';

@Pipe({
	name: 'analysisPipe',
})
export class AnalysisPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	transform(value: string | number | Date, type?: string): unknown {
		if (value === '') {
			return '';
		}
		if (value instanceof Date || (typeof value !== 'number' && Date.parse(value))) {
			return this.datePipe.transform(value, 'dd/MM/yyyy');
		}

		if (value === 'processed' && type && (type === 'mother_filiation' || type === 'father_filiation')) {
			value = 'compatible'; // eslint-disable-line no-param-reassign
		}

		return analysisTranslate[value as string];
	}
}
