import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimalProvider } from 'src/app/providers/animal.provider';
import { AnimalModel } from 'src/app/schemes/models/animal.model';
import { BirthModel } from 'src/app/schemes/models/birth.model';
import { FarmService } from 'src/app/services/farm.service';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { BirthTypeEnum } from '../../../../schemes/enums/birth-type.enum';
import { BirthViewModel } from '../../../../schemes/models/birth-view.model';
import { TimeService } from '../../../../services/time.service';
import { UtilsService } from '../../../../services/utils.service';
import { BirthTypePipe } from '../../../../shared/pipes/birth-type.pipe';

@Component({
	selector: 'app-filter-births-modal',
	templateUrl: './filter-births-modal.component.html',
	styleUrls: ['./filter-births-modal.component.scss'],
})
export class FilterBirthsModalComponent implements OnInit {
	filters: { [key in keyof BirthViewModel]?: any } = {
		date: {
			start: null,
			end: null,
		},
		mother: {
			officialId: null,
			crotal: null,
		},
		children: {
			provId: null,
		},
		motherDropDate: {
			start: null,
			end: null,
		},
	};

	brith: BirthModel;
	animal: AnimalModel;
	es = ngPrimeLocale;
	mothers: AnimalModel[] = [];
	filteredMothers: string[] = [];
	indexedMothers: { [key: string]: AnimalModel };
	farmId: string;
	active = false;

	filteredBirthType: any;
	birthTypes: { name: string; value: BirthTypeEnum }[] = [];

	constructor(
		private router: Router,
		private animalProvider: AnimalProvider,
		private farmService: FarmService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterBirthsModalComponent>,
		private utilsService: UtilsService,
		private titlecase: TitleCasePipe,
		private birthTypePipe: BirthTypePipe
	) {}

	async ngOnInit(): Promise<void> {
		this.setBirthTypes();
		this.setFiltersValues(this.data);
		this.farmId = this.data.filters.farm || (await this.farmService.getCurrentFarm())?._id;
		this.active = this.data.active;
		if (this.filters.motherDropDate === null) this.active = true;
		// this.mothers = (await this.animalProvider.getMothers()).data;
		// this.indexedMothers = this.utilsService.indexArray(this.mothers, '_id');
		// this.filteredMothers = this.mothers.map((mothers) => mothers.officialId);
	}

	onSaveFilters(): void {
		const urlFilters = this.parseFilters(this.filters);
		urlFilters['active'] = this.active;
		this.dialogRef.close(urlFilters);

		this.router.navigate(['/book/births'], { queryParams: urlFilters }).then(() => {
			// Reload the current page to apply the filters
			location.reload();
		});
	}

	parseFilters(filters: Record<string, unknown>): Record<string, any> {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (!value) {
				return newFilters;
			}
			if (key.toLowerCase().includes('date')) {
				if (value['start']) {
					newFilters[`${key}_gte`] = (value['start'] as Date).toISOString();
				}
				if (value['end']) {
					newFilters[`${key}_lte`] = value['end'] as Date;
					newFilters[`${key}_lte`] as Date;
					newFilters[`${key}_lte`] = newFilters[`${key}_lte`].toISOString();
				}
			} else if (key.toLowerCase().includes('type')) {
				newFilters[`${key}`] = value['value'];
			} else if (key.toLowerCase().includes('mother')) {
				if (value['officialId']) {
					newFilters['mother_like'] = value['officialId'] as string;
				}
				if (value['crotal']) {
					newFilters['motherCrotal_like'] = value['crotal'] as string;
				}
			} else if (key.toLowerCase().includes('children')) {
				if (value['provId']) {
					newFilters['children_like'] = value['provId'] as string;
				}
			} else if (typeof value === 'string') {
				newFilters[`${key}_like`] = value;
			} else if (typeof value === 'number') {
				newFilters[`${key}`] = value;
			} else if (Array.isArray(value)) {
				newFilters[`${key}_in`] = value.map((val: Record<string, any>) => val._id as string).join(',');
			} else {
				if (typeof value === 'object') {
					// eslint-disable-next-line guard-for-in
					for (const val in value) {
						newFilters[`${key}.${val}_like`] = filters[key][val] as string;
					}
				}
				if (!value['value']) {
					return newFilters;
				}
				newFilters[`${key}`] = value['value'] as string;
			}
			return newFilters;
		}, {});
	}

	setBirthTypes() {
		this.birthTypes = Object.values(BirthTypeEnum).map(value => ({
			value,
			name: this.titlecase.transform(this.birthTypePipe.transform(value) as string),
		}));
		this.filteredBirthType = this.birthTypes;
	}

	searchBirthTypes($event) {
		this.filteredBirthType = this.birthTypes.filter(gb =>
			gb.name.toLowerCase().includes($event.query.toString().toLowerCase())
		);
	}

	onClearFilters(): void {
		this.filters = {
			date: {
				start: null,
				end: null,
			},
			mother: {
				officialId: null,
				crotal: null,
			},
			children: {
				provId: null,
			},
			motherDropDate: {
				start: null,
				end: null,
			},
		};
		this.active = false;
	}

	setFiltersValues(data: { filters: { [key: string]: string | any[] } }): boolean {
		if (!data.filters) {
			return;
		}
		if (data.filters.motherDropDate && data.filters.motherDropDate == null) {
			this.active = true;
			this.filters.motherDropDate = { start: null, end: null };
		}
		if (data.filters.motherDropDate_gte) {
			this.filters.motherDropDate.start = TimeService.parseDate(data.filters.motherDropDate_gte as string);
		}
		if (data.filters.motherDropDate_lte) {
			this.filters.motherDropDate.end = TimeService.parseDate(data.filters.motherDropDate_lte as string);
		}
		if (data.filters.date_gte) {
			this.filters.date.start = TimeService.parseDate(data.filters.date_gte as string);
		}
		if (data.filters.date_lte) {
			this.filters.date.end = TimeService.parseDate(data.filters.date_lte as string);
		}
		if (data.filters.mother_like) {
			this.filters.mother.officialId = data.filters.mother_like;
		}
		if (data.filters.motherCrotal_like) {
			this.filters.mother.crotal = data.filters.motherCrotal_like;
		}
		if (data.filters.children_like) {
			this.filters.children.provId = data.filters.children_like;
		}
		if (data.filters.type) {
			this.filters.type = this.birthTypes.find(type => type.value === data.filters.type);
		}
	}
}
