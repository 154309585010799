import { Injectable } from '@angular/core';
import { AssociationProvider } from '../providers/association.provider';
import { AssociationModel } from '../schemes/models/association.model';
import { HttpService } from './http.service';

@Injectable({
	providedIn: 'root',
})
export class AssociationService {
	private currentAssociation: AssociationModel;

	constructor(private associationProvider: AssociationProvider) {}

	public async setCurrentAssociation(associationId: string = null): Promise<void> {
		if (associationId) {
			try {
				const association = (await this.associationProvider.getById(associationId)).data;
				this.currentAssociation = association;
				HttpService.currentDb = association ? association.database : '';
				if (associationId !== localStorage.getItem('selectedAssociationId')) {
					localStorage.setItem('selectedAssociationId', association._id);
				}
			} catch (error) {
				console.error(error);
				localStorage.removeItem('selectedAssociationId');
				this.currentAssociation = null;
			}
		} else {
			localStorage.removeItem('selectedAssociationId');
			this.currentAssociation = null;
		}
	}

	public async getCurrentAssociation(): Promise<AssociationModel> {
		const localAssociationId = localStorage.getItem('selectedAssociationId');

		if (this.currentAssociation) {
			return this.currentAssociation;
		}

		if (localAssociationId) {
			await this.setCurrentAssociation(localAssociationId);
			return this.currentAssociation;
		}
		return null;
	}

	public getCrotalLength(): number {
		return this.currentAssociation?.config?.crotalLength || 6;
	}
}
