export interface IFormInputValidator {
	validatorName: string;
	details: any;
}

export interface ISelectData {
	value: string;
	displayValue?: string;
}

export enum InputFormatEnum {
	uppercase = 'uppercase',
	lowercase = 'lowercase',
	capitalize = 'capitalize',
	spanishName = 'spanishName',
	coordinates = 'coordinates',
	iban = 'iban',
	phoneES = 'phoneES',
}

export type InputFormatType = keyof typeof InputFormatEnum;

export const CUSTOM_MULTISELECT_ARRAY_SEPARATOR = ':';
