import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
	error: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InfoComponent>) {}

	ngOnInit(): void {
		this.error = this.data.mode === 'error';
	}

	onCloseDialog(res: boolean): void {
		this.dialogRef.close(res);
	}
}
