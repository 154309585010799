import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileService } from './services/file.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'dashboard';
	loaderText = '';
	textIndex = 0;
	texts = [
		'Redil está trabajando en ello, espere un momento...',
		'Dependiendo del volumen de datos, esta operación puede tardar cierto tiempo, mantengase a la espera',
		'Procure que su ordenador se mantenga encendido y con conexión mientras esta operación se realiza',
	];

	intervalId;

	constructor(public loaderService: NgxUiLoaderService, private fileService: FileService) { }

	ngOnInit() {
		this.loaderService.showForeground$.subscribe(loader => {
			if (loader.loaderId === 'master') {
				this.intervalId = setInterval(() => {
					this.textIndex = this.textIndex === this.texts.length - 1 ? 0 : this.textIndex + 1;
					this.loaderText = this.texts[this.textIndex];
				}, 3000);
			}
		});
		this.loaderService.showBackground$.subscribe(loader => {
			if (loader.loaderId === 'master') {
				window.clearInterval(this.intervalId);
				this.loaderText = '';
			}
		});
	}
}
