<div class="bg profile-pic" *ngIf="!imageUrl && oldImplementation"></div>
<div class="default-image default-image--animal" *ngIf="!imageUrl && !oldImplementation"></div>

<img
	*ngIf="imageUrl"
	(click)="displayImage(imageUrl)"
	class="profile-pic cover"
	[src]="imageUrl"
	style="cursor: pointer"
	alt="img-thumb-animal"
/>
