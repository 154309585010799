import { AbilityBuilder } from '@casl/ability';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AppAbility } from '../app-abilities';

export const SuperRules = (user: UserModel) => {
	const { can: allow, rules } = new AbilityBuilder<AppAbility>();

	// General Actions
	allow('manage', 'Modules', { _id: user._id });
	allow('manage', 'Documents');
	allow('manage', 'UserModel');
	allow('manage', 'Self', { _id: user._id });
	allow('manage', 'Files');
	allow('manage', 'Proposal');
	allow(['manage', 'update'], 'AssociationModel');

	// Permissions Navigator
	allow('view', [
		'Proposal',
		'ProposalAnimal',
		'ProposalFarm',
		'ProposalRanch',
		'ProposalBreed',
		'ProposalBirth',
		'ProposalAnalysis',
		'ProposalTransference',
	]);
	allow('view', ['Files', 'FileAnimal', 'FileAnalysis']);
	allow('view', [
		'Book',
		'BookAnimal',
		'BookRanch',
		'BookBreedArtificial',
		'BookBreedBatch',
		'BookBirth',
		'BookFarm',
	]);
	allow('view', ['Morfologic', 'MorfologicQualification', 'MorfologicForm']);
	allow('view', ['Dna', 'DnaAnalysis', 'DnaBank', 'DnaAnimal', 'DnaSample']);

	// PERMISSIONS GENBOOK
	// Permissions Animal
	allow('download', 'Arca');
	allow('download', 'Census');
	allow(
		[
			'create',
			'export',
			'import',
			'calculate',
			'calculateAll',
			'update',
			'qualify',
			'transfer',
			'locate',
			'delete',
			'block',
			'unblock',
		],
		'BookAnimal'
	);
	allow('delete', 'AnimalHistorical');
	allow('download', 'Zootechnical');
	allow(['create', 'delete'], 'BirthAssociation');
	// Permissions Farm
	allow(['create', 'export', 'import', 'locate', 'delete', 'update', 'read', 'unique-update'], 'BookFarm');
	// Permissions Ranch
	allow(['create', 'export', 'import', 'update', 'locate', 'delete'], 'BookRanch');
	// Permissions Breed
	allow(['export', 'import', 'create', 'update'], 'BookBreed');
	// Permissions Birth
	allow(['export', 'import', 'create', 'block', 'unblock', 'delete'], 'BookBirth');
	return rules;
};
