<div class="container">
	<h2>Nueva carpeta</h2>
	<div class="content">
		<input
			id="input-id"
			class="ui-inputtext"
			type="text"
			maxlength="60"
			[(ngModel)]="folderName"
			[pattern]="pattern"
			pInputText
		/>
	</div>
	<p>Sólo caracteres alfanumericos (A - Z, 0 - 9), espacios y guiones "-" (Límite de caracteres es de 60)</p>

	<div class="buttons">
		<button [disabled]="!validateInput()" class="app-btn orange" (click)="onCreate()">{{ 'Aceptar' }}</button>
		<button (click)="onClose()" class="app-btn optional">{{ 'Cancelar' }}</button>
	</div>
</div>
