<div class="redil-info">
	<h2>Rellena las categorías de libro de cada sección (separadas por comas)</h2>
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-value">Sección anexa</label>
					<input
						id="input-value"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="arcaSections.categorySectionsAnnexes"
						pInputText
					/>
				</div>

				<div class="info-row">
					<label for="input-value">Registro fundacional</label>
					<input
						id="input-value"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="arcaSections.fundationalRegister"
						pInputText
					/>
				</div>

				<div class="info-row">
					<label for="input-value">Categoría básica</label>
					<input
						id="input-value"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="arcaSections.basicCategory"
						pInputText
					/>
				</div>

				<div class="info-row">
					<label for="input-value">Categoría animales reproductores con méritos</label>
					<input
						id="input-value"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="arcaSections.meritsCategory"
						pInputText
					/>
				</div>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(arcaSections)" class="app-btn primary">Guardar</button>
				<button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button>
			</div>
		</section>
	</div>
</div>
