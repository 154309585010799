<h2>Filtrar Muestras</h2>
<div class="redil-info" *ngIf="filters">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-name">Animal</label>
					<input id="input-name" class="ui-inputtext" type="text" [(ngModel)]="filters.animal_like"
						pInputText />
				</div>
				<div class="info-row">
					<label for="input-gender">Prueba</label>
					<p-dropdown [options]="types" [(ngModel)]="filters.analysisType_like"
						placeholder="Selecciona una opción" optionLabel="name" [appendTo]="'body'"
						[style]="{ width: '100%' }"></p-dropdown>
				</div>

				<div class="info-row">
					<label for="drop-date">Fecha de toma</label>
					<p>Desde:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.createdAt.start"
						[locale]="es.calendar_es" dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'"
						[placeholder]="'24/06/1998'"></p-calendar>
					<p>Hasta:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.createdAt.end" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'">
					</p-calendar>
				</div>
			</div>
		</section>
	</div>
	<div class="centered">
		<button (click)="onSaveFilters()" class="app-btn orange">Aceptar</button>
		<button (click)="onClearFilters()" class="app-btn primary">Borrar filtros</button>
	</div>
</div>