<h2>Filtrar Fincas</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-id">Rega</label>
					<input id="input-id" class="ui-inputtext" type="text" [(ngModel)]="filters.rega" pInputText />
				</div>

				<!-- <div class="info-row" *ngIf="farms">
                    <label for="float-input">Ganadería</label>
                    <p-autoComplete [(ngModel)]="filters.farm" [suggestions]="filteredFarms"
                        (completeMethod)="searchFarm($event)" [dropdown]="true" field="name">
                    </p-autoComplete>
                </div> -->

				<div class="info-row">
					<label for="input-name">Nombre</label>
					<input id="input-name" class="ui-inputtext" type="text" [(ngModel)]="filters.name" pInputText />
				</div>

				<div class="info-row">
					<label for="">Solo fincas activas</label>
					<div class="checkbox-cnt">
						<input type="checkbox" [(ngModel)]="active" />
					</div>
				</div>
			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>