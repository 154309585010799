import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { subject } from '@casl/ability';
import { take } from 'rxjs/operators';
import { AssociationProvider } from 'src/app/providers/association.provider';
import { RoleEnum } from 'src/app/schemes/enums/role.enum';
import { AssociationModel } from 'src/app/schemes/models/association.model';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AssociationService } from 'src/app/services/association.service';
import { AuthService } from 'src/app/services/auth.service';
import { FarmService } from 'src/app/services/farm.service';
import { AppAbility } from '../../shared/app-abilities';

@Component({
	selector: 'app-association-selector',
	templateUrl: './association-selector.component.html',
	styleUrls: ['./association-selector.component.scss'],
})
export class AssociationSelectorComponent implements OnInit {
	associations: AssociationModel[];
	selectedAssociation: AssociationModel;
	user: UserModel;

	constructor(
		private associationProvider: AssociationProvider,
		private associationService: AssociationService,
		private farmService: FarmService,
		private auth: AuthService,
		private router: Router,
		private ability: AppAbility
	) {}

	async ngOnInit(): Promise<void> {
		this.user = await this.auth.getCurrentUser().pipe(take(1)).toPromise();
		if (this.user.role === RoleEnum.super) {
			this.associations = (await this.associationProvider.getAll()).data;
		} else {
			const responses = await Promise.all(
				(this.user.associations as string[]).map((id: string) =>
					this.associationProvider.getById(id)
				)
			);
			this.associations = responses.map(response => response.data);
		}
		this.associations = this.associations.map(association =>
			subject('AssociationModel', association)
		);
	}

	async selectAssociation(association: AssociationModel): Promise<void> {
		if (this.ability.can('read', association)) {
			try {
				this.selectedAssociation = association;
				await this.associationService.setCurrentAssociation(association._id);
				await this.farmService.setCurrentFarm();
				this.router.navigateByUrl('/');
			} catch (e) {
				console.error(e);
			}
		} else {
			console.log('Not valid');
		}
	}
}
