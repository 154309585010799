<div class="redil-info">
	<h2>{{ title | titlecase }}</h2>
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label>Categoría</label>
					<p-autoComplete id="input-role" [(ngModel)]="value" [suggestions]="filteredRules"
						(completeMethod)="onSearchRule($event)" [dropdown]="true" field="name" [size]="30"
						[disabled]="isSuggestedValue" [ngClass]="{ 'disabled-input': isSuggestedValue }" [minLength]="1"
						[forceSelection]="true">
					</p-autoComplete>
				</div>
				<div class="info-row">
					<label for="gb-date">Fecha de asignación</label>
					<p-calendar id="gb-date" appUseUtc [(ngModel)]="date" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'">
					</p-calendar>
				</div>
			</div>

			<div class="buttons centered">
				<button (click)="onDialogClose()" class="app-btn orange">Guardar</button>
			</div>
		</section>
	</div>
</div>