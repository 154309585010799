import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormUtilsService } from 'src/app/services/form-utils.service';

@Component({
	selector: 'app-input-check',
	templateUrl: './input-check.component.html',
	styleUrls: ['./input-check.component.scss'],
})
export class InputCheckComponent implements OnInit {
	@Input() formController: FormControl;
	@Input() inputLabel: string;
	@Output() onCheck = new EventEmitter<boolean>();

	constructor(public formUtils: FormUtilsService) {}

	ngOnInit(): void {}

	onChecked(checked: boolean): void {
		this.onCheck.emit(checked);
	}
}
