import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-upload-doc',
	templateUrl: './upload-doc.component.html',
	styleUrls: ['./upload-doc.component.scss'],
})
export class UploadDocComponent implements OnInit {
	file: File;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<UploadDocComponent>
	) {}

	ngOnInit(): void {}

	onImportExcel(file: File): void {
		this.file = file;
		this.dialogRef.close({ file: this.file });
	}

	onClose(): void {
		this.dialogRef.close({ file: null });
	}
}
