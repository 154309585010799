import { Pipe, PipeTransform } from '@angular/core';
import { BreedTypeEnum } from 'src/app/schemes/enums/breed-type.enum';

@Pipe({
	name: 'breedingPipe',
})
export class BreedTypePipe implements PipeTransform {
	transform(breed: BreedTypeEnum, args?: any): any {
		switch (breed) {
			case 'artificial': return 'inseminación artificial';
			case 'batch': return 'cubrición por lotes';
			default: return '';
		}
	}
}
