import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageThumbComponent } from './image-thumbnail.component';

@NgModule({
	declarations: [ImageThumbComponent],
	exports: [ImageThumbComponent],
	imports: [CommonModule],
})
export class ImageThumbModule {}
