import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-prompt',
	templateUrl: './prompt.component.html',
	styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
	input = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PromptComponent>) { }

	ngOnInit(): void { }

	onCloseDialog(res: boolean): void {
		if (res) {
			this.dialogRef.close(this.input);
		} else {
			this.dialogRef.close(null);
		}
	}
}
