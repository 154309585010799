import { Pipe, PipeTransform } from '@angular/core';
import destinationTranslate from '../../../assets/translate/destinationTranslate.json';
import dropReasonsTranslate from '../../../assets/translate/dropReasonsTranslate.json';

@Pipe({
	name: 'animalPipe',
})
export class AnimalPipe implements PipeTransform {
	transform(value: string, property: string, ...args: unknown[]): string {
		if (property === 'gender') {
			return this.transformGender(value);
		}
		if (property === 'dropReason') {
			return this.transformDropReason(value);
		}
		if (property === 'destination') {
			return this.transformDestination(value);
		}
	}

	transformProp(property: string, ...args: unknown[]): string {
		if (property.includes('officialId')) {
			return property.replace('officialId', 'id oficial');
		}
	}

	private transformGender(value: string): string {
		return value == 'H' ? 'hembra' : 'macho';
	}

	private transformDestination(value: string): string {
		return destinationTranslate[value] || value;
	}

	private transformDropReason(value: string): string {
		return dropReasonsTranslate[value] || value;
	}

	revertTransformation(value: string, property: string): string {
		if (!value) return value;
		if (property === 'dropReason') {
			return (
				Object.keys(dropReasonsTranslate).find(key => dropReasonsTranslate[key] === value.toLowerCase()) ||
				value
			);
		}
	}
}
