import { CommonModule, DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AbilityModule } from '@casl/angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { InputPlacesComponent } from 'src/app/components/input-places/input-places.component';
import { FileDropzoneComponent } from '../components/file-dropzone/file-dropzone.component';
import { GlobalFilterComponent } from '../components/filters/global-filter/global-filter.component';
import { FormInputComponent } from '../components/form-input/form-input.component';
import { ImageExpandDisplayComponent } from '../components/image-expand-display/image-expand-display.component';
import { ImageThumbModule } from '../components/image-thumbnail/image-thumbnail.module';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { InputCheckComponent } from '../components/input-check/input-check.component';
import { UseUtcDirective } from '../use-utc.directive';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FormValidationDirective } from './directives/form-validation.directive';
import { AnalysisPipe } from './pipes/analysis.pipe';
import { AnimalPipe } from './pipes/animal.pipe';
import { BirthTypePipe } from './pipes/birth-type.pipe';
import { BreedTypePipe } from './pipes/breed-type.pipe';
import { FilePipe } from './pipes/file.pipe';
import { HeaderTranslatePipe } from './pipes/header-translate.pipe';
import { ImportErrorsPipe } from './pipes/import-errors.pipe';
import { BrucelosisPipe } from './pipes/inspection-brucelosis.pipe';
import { TuberculosisPipe } from './pipes/inspection-tuberculosis.pipe';
import { ModulePipe } from './pipes/module-enum.pipe';
import { MolecularMarkerPipe } from './pipes/molecular-marker.pipe';
import { ProposalPipe } from './pipes/proposal.pipe';
import { SamplePipe } from './pipes/sample.pipe';
import { TextFormatPipe } from './pipes/text-format.pipe';
import { TransferencesPipe } from './pipes/transferences.pipe';
import { UserPipe } from './pipes/user.pipe';
import { ValidationDataPipe } from './pipes/validation-data.pipe';
import { WeightPipe } from './pipes/weight.pipe';

@NgModule({
	imports: [
		HttpClientModule,
		CommonModule,
		FormsModule,
		PasswordModule,
		ButtonModule,
		ReactiveFormsModule,
		CardModule,
		PanelMenuModule,
		TableModule,
		DropdownModule,
		MultiSelectModule,
		CalendarModule,
		InputTextModule,
		AutoCompleteModule,
		InputNumberModule,
		InputTextareaModule,
		FileUploadModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		MatDialogModule,
		MatInputModule,
		MatMenuModule,
		CheckboxModule,
		ToastModule,
		AbilityModule,
		MatButtonModule,
		MatIconModule,
		ImageThumbModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		PasswordModule,
		ButtonModule,
		ReactiveFormsModule,
		CardModule,
		RadioButtonModule,
		PanelMenuModule,
		TableModule,
		DropdownModule,
		MultiSelectModule,
		CalendarModule,
		InputTextModule,
		AutoCompleteModule,
		InputNumberModule,
		InputTextareaModule,
		HttpClientModule,
		FileUploadModule,
		MessageModule,
		ModulePipe,
		BreedTypePipe,
		AnimalPipe,
		SelectButtonModule,
		FileDropzoneComponent,
		GlobalFilterComponent,
		MatMenuModule,
		ImportErrorsPipe,
		ImageUploadComponent,
		MatMenuModule,
		MatInputModule,
		CheckboxModule,
		AnalysisPipe,
		TransferencesPipe,
		ValidationDataPipe,
		ProposalPipe,
		BirthTypePipe,
		UserPipe,
		WeightPipe,
		ToastModule,
		SamplePipe,
		FilePipe,
		UseUtcDirective,
		AbilityModule,
		ImageExpandDisplayComponent,
		ImageThumbModule,
		FormInputComponent,
		FormValidationDirective,
		TextFormatPipe,
		InputPlacesComponent,
		InputCheckComponent,
	],
	declarations: [
		ModulePipe,
		BreedTypePipe,
		AnimalPipe,
		DropZoneDirective,
		FileDropzoneComponent,
		GlobalFilterComponent,
		ImportErrorsPipe,
		ImageUploadComponent,
		HeaderTranslatePipe,
		AnalysisPipe,
		TransferencesPipe,
		ValidationDataPipe,
		ProposalPipe,
		BirthTypePipe,
		UserPipe,
		WeightPipe,
		SamplePipe,
		FilePipe,
		MolecularMarkerPipe,
		UseUtcDirective,
		ImageExpandDisplayComponent,
		FormInputComponent,
		FormValidationDirective,
		TextFormatPipe,
		InputPlacesComponent,
		InputCheckComponent,
	],
	providers: [
		ModulePipe,
		BreedTypePipe,
		TuberculosisPipe,
		BrucelosisPipe,
		UpperCasePipe,
		AnimalPipe,
		ImportErrorsPipe,
		DatePipe,
		TitleCasePipe,
		HeaderTranslatePipe,
		AnalysisPipe,
		TransferencesPipe,
		ValidationDataPipe,
		ProposalPipe,
		BirthTypePipe,
		UserPipe,
		WeightPipe,
		FilePipe,
		MolecularMarkerPipe,
		TextFormatPipe,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
