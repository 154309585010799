import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimalModel } from 'src/app/schemes/models/animal.model';
import { GenBookRuleModel } from 'src/app/schemes/models/gen-book.model';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { TimeService } from '../../../services/time.service';
import { InfoComponent } from '../info/info.component';

@Component({
	selector: 'app-genbook-form',
	templateUrl: './genbook-form.component.html',
	styleUrls: ['./genbook-form.component.scss'],
})
export class GenbookFormComponent implements OnInit {
	title = 'Introduzca una nueva Categoría de Libro';
	value: { name: string; value: string };
	date: Date;
	animal: AnimalModel;

	es = ngPrimeLocale;
	rules: { name: string; value: string }[] = [];
	filteredRules: { name: string; value: string }[];

	isSuggestedValue = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: Record<string, unknown>, public dialogRef: MatDialogRef<InfoComponent>) { }

	ngOnInit(): void {
		this.date = this.data.date ? TimeService.parseDate(this.data.date as string) : TimeService.getActualDate();
		this.animal = this.data.animal as AnimalModel;
		const suggestedValue = this.data.value as string;
		this.isSuggestedValue = !!suggestedValue;
		if (this.data.rules) {
			this.rules = (this.data.rules as GenBookRuleModel[]).map(({ value }) => ({ value, name: value }));
		} else {
			this.rules = [{ value: suggestedValue, name: suggestedValue }];
		}
		this.value = this.rules[0];
	}

	onDialogClose() {
		this.dialogRef.close({
			date: this.date,
			value: this.value.value,
			animal: this.animal._id,
		});
	}

	onSearchRule($event: Record<string, string>) {
		this.filteredRules = this.rules.filter(({ name }) => name.includes($event.query));
	}
}
