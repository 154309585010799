import {
	Directive, Host, HostListener, Self,
} from '@angular/core';
import { Calendar } from 'primeng';
import { TimeService } from './services/time.service';

@Directive({
	selector: '[appUseUtc]',
})
export class UseUtcDirective {
	constructor(@Host() @Self() private calendar: Calendar) { }

	@HostListener('onSelect', ['$event']) onSelect() {
		this.toUtc();
	}

	@HostListener('onInput', ['$event']) onInput() {
		this.toUtc();
	}

	private toUtc() {
		if (this.calendar.value && new Date(this.calendar.value) && !isNaN(new Date(this.calendar.value).getTime())) {
			const parsedTime = new Date((this.calendar.value as Date).setHours(12, 0, 0, 0));
			this.calendar.updateModel(parsedTime);
		}
	}
}
