import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-pagenotyet',
	templateUrl: './pagenotyet.component.html',
	styleUrls: ['./pagenotyet.component.scss'],
})
export class PagenotyetComponent implements OnInit {
	constructor() { }

	ngOnInit(): void {
	}

	reloadWindowWithoutCache() {
		location.reload(true);
	}
}
