import { AbilityBuilder } from '@casl/ability';
import { UserModel } from '../../schemes/models/user.model';
import { AppAbility } from '../app-abilities';

export const FarmerRules = (user: UserModel) => {
	const { can: allow, rules } = new AbilityBuilder<AppAbility>();

	// General Actions
	allow('manage', 'Modules', { _id: user._id });
	allow('read', 'Self', { _id: user._id });
	allow('manage', 'Files', { associations: [user.associations] });
	allow('read', 'AssociationModel', { _id: { $in: user.associations } });

	// Permissions Navigator
	allow('view', [
		'Proposal',
		'ProposalAnimal',
		'ProposalFarm',
		'ProposalRanch',
		'ProposalBreed',
		'ProposalBirth',
		'ProposalAnalysis',
		'ProposalTransference',
	]);
	allow('view', ['Files', 'FileAnimal', 'FileAnalysis']);
	allow('view', [
		'Book',
		'BookAnimal',
		'BookFarm',
		'BookRanch',
		'BookBreedArtificial',
		'BookBreedBatch',
		'BookBirth',
	]);
	allow('view', ['Morfologic', 'MorfologicQualification', 'MorfologicForm']);
	allow('view', ['Dna', 'DnaAnalysis', 'DnaBank', 'DnaAnimal', 'DnaSample']);

	// PERMISSIONS GENBOOK
	// Permissions Animal
	// allow('download', 'Census');
	allow(
		[
			'request-create',
			'export',
			'request-transfer',
			'request-locate',
			'request',
			'request-delete',
			'request-update',
		],
		'BookAnimal'
	);
	allow(['request-create', 'request-delete'], 'BirthAssociation');
	// Permissions Farm
	allow(['read', 'request-update'], 'BookFarm');
	// Permissions Ranch
	allow(['request-create', 'export', 'request-update'], 'BookRanch');
	// Permissions Breed
	allow(['export', 'request-create', 'request-update'], 'BookBreed');
	// Permissions Birth
	allow(['export', 'request-create', 'request-update', 'request-delete'], 'BookBirth');
	return rules;
};
