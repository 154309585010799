import { Injectable } from '@angular/core';
import { TimeService } from './time.service';
import { AnalysisProvider } from '../providers/analysis.provider';
import { AnimalViewProvider } from '../providers/animal-view.provider';
import { AnimalProvider } from '../providers/animal.provider';
import { BirthProvider } from '../providers/birth.provider';
import { FarmProvider } from '../providers/farm.provider';
import { GenBookRulesProvider } from '../providers/gen-book-rule.provider';
import { GenBooksProvider } from '../providers/gen-book.provider';
import { QualificationsProvider } from '../providers/qualifications.provider';
import { RanchProvider } from '../providers/ranch.provider';
import { SampleProvider } from '../providers/sample.provider';
import { AnalysisTypeEnum } from '../schemes/enums/analysis.enum';
import { AnalysisModel } from '../schemes/models/analysis.model';
import { BirthModel } from '../schemes/models/birth.model';
import { GenBookModel } from '../schemes/models/gen-book.model';
import { QualificationModel } from '../schemes/models/qualifications.model';
import { BirthViewProvider } from '../providers/birth-view.provider';
import { AssociationService } from './association.service';
import { ExcelService } from './excel.service';
import { HttpService } from './http.service';
import { LoaderService } from './loader.service';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root',
})

// aquí irán metodos que nos han servido en algun punto para corregir algun que otro bug
export class BugService {
	constructor(
		private loader: LoaderService,
		private farmProvider: FarmProvider,
		private ranchProvider: RanchProvider,
		private birthProvider: BirthProvider,
		private qualificationProvider: QualificationsProvider,
		private animalProvider: AnimalProvider,
		private analysisProvider: AnalysisProvider,
		private genbookProvider: GenBooksProvider,
		private genbookRuleProvider: GenBookRulesProvider,
		private associationService: AssociationService,
		private excelService: ExcelService,
		private utils: UtilsService,
		private animalViewProvider: AnimalViewProvider,
		private http: HttpService,
		private birthViewProvider: BirthViewProvider,
		private sampleProvider: SampleProvider,
	) { }

	// ordena todos los partos y les asigna un code correcto
	async sortBirths(): Promise<void> {
		const births = (await this.birthProvider.getAll()).data;
		const groupedBirths = births.reduce((obj: { [key: string]: BirthModel[] }, b) => {
			if (Object.prototype.hasOwnProperty.call(obj, b.mother as string)) {
				// obj[b.mother as string] = [...obj[b.mother as string], b];
			} else {
				obj[b.mother as string] = [b];
			}
			return obj;
		}, {});

		const orderedGroupedBirths = {};
		Object.entries(groupedBirths).forEach(([key, value]) => {
			const sortedBirths = value.sort((a, b) => TimeService.parseDate(a.date).getTime() - TimeService.parseDate(b.date).getTime());
			orderedGroupedBirths[key] = sortedBirths.map((b, i) => {
				b.code = i + 1;
				return b;
			});
		});

		// const birthsToUpdate: unknown = Object.values(orderedGroupedBirths).reduce((arr: unknown[], val: unknown[]) => [...arr, ...val], []);

		// const res = (await this.birthProvider.updateBatch(birthsToUpdate as BirthModel[])).data;
	}

	// genera todos los acrónimos de las ganaderías
	async getAcro(): Promise<void> {
		this.loader.startBasicLoader();
		const farms = (await this.farmProvider.getAll()).data;

		const toUpdate = farms.map(farm => {
			farm.acronym = farm.name
				.split(' ')
				.map(w => w[0].toUpperCase())
				.join('');
			return farm;
		});

		const res = (await this.farmProvider.updateBatch(toUpdate)).data;

		this.loader.stopBasicLoader();
	}

	// relaciona qualificaciones con el animal
	async relateQualifications(): Promise<void> {
		const qualis = (await this.qualificationProvider.getAll()).data;
		const grouped = qualis.reduce((gq, q) => {
			const quali = gq[q.animal as string] as QualificationModel;
			gq[q.animal as string] = !quali || TimeService.parseDate(quali.date).getTime() < TimeService.parseDate(q.date).getTime() ? q._id : quali;
			return gq;
		}, {});

		const toUpdate = Object.entries(grouped).map(([animalId, qualification]) => ({
			_id: animalId,
			qualification,
		})) as any[];

		for (let i = 0; i < toUpdate.length; i += 500) {
			const elements = toUpdate.slice(i, i + 500);
			const updated = await this.animalProvider.updateBatch(elements);
		}
	}

	// Set qualifications as apt or not apt depending on param
	async fixQualifications(): Promise<void> {
		// const qualifications = (await this.qualificationProvider.getAll({ apt: null })).data;
		// const res = await this.qualificationProvider.updateBatch(toUpdate);
		//
	}

	async setCrotal(): Promise<void> {
		const crotalLength = this.associationService.getCrotalLength();
		const animals = (await this.animalProvider.getAll()).data;

		const toUpdate = animals.map(animal => {
			animal.crotal = animal.officialId.slice(-crotalLength);
			return animal;
		});

		const res = await this.animalProvider.updateBatch(toUpdate);
	}

	async fixPurity() {
		const animals = await this.animalProvider.getAll({ genBook: null });

		const genbooks = await this.genbookProvider.getAll();
	}

	async removeEmbedded() {
		const animals = (await this.animalProvider.getAll()).data;

		animals.forEach(animal => (animal.analysis = []));

		await this.animalProvider.updateBatch(animals);
	}

	async parseDateStrings() {
		const analysis = (await this.analysisProvider.getAll()).data;

		await this.analysisProvider.updateBatch(analysis);
	}

	tryLoader() {
		this.loader.startBasicLoader();
	}

	async deleteGenbooks() {
		try {
			const gb = (await this.genbookProvider.getAll()).data;

			const deleted = [];
			const i = 0;
			for await (const g of gb) {
				const d = await this.genbookProvider.delete(g._id);

				deleted.push(d);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async exportGenbookDates() {
		const populateFields = [
			'breederFarm',
			'breederFarmRanch',
			'father',
			'mother',
			'ownerFarm',
			'qualification',
			'races',
			'genBook',
			'transferences',
			'mother',
		];
		const gb = (await this.genbookProvider.getAll()).data;
		const gbr = (await this.genbookRuleProvider.getAll()).data;
		const indexedRules = this.utils.indexArray(gbr, 'value');

		const animals = (await this.animalProvider.getAll({ _embed: populateFields })).data;
		const indexedAnimals = this.utils.indexArray(animals, '_id');

		const res = gb.map(g => {
			const animal = indexedAnimals[g.animal as string];
			const rule = indexedRules[g.value];

			if (!rule) {
			}

			if (g.value === 'SABf') {
			}
			let date: Date = null;
			try {
				date = eval(rule.suggestedDate) as Date;
			} catch (error) {
				date = null;
			}

			const gdate = g.date ? TimeService.parseDate(g.date) : null;
			const sdate = date ? TimeService.parseDate(date) : null;
			return {
				'ID OFICIAL': animal.officialId,
				CATEGORÍA: g.value,
				'FECHA ACTUAL': gdate ? `${gdate.getDate()}/${gdate.getMonth() + 1}/${gdate.getFullYear()}` : null,
				'FECHA SUGERIDA': sdate ? `${sdate.getDate()}/${sdate.getMonth() + 1}/${sdate.getFullYear()}` : null,
			};
		});

		await this.excelService.generateExcel([res], [], 'Fechas Categorías');
	}

	async fixGenBookDates() {
		const populateFields = [
			'breederFarm',
			'breederFarmRanch',
			'father',
			'mother',
			'ownerFarm',
			'qualification',
			'races',
			'genBook',
			'transferences',
			'mother',
		];
		const gb = (await this.genbookProvider.getAll()).data;
		const gbr = (await this.genbookRuleProvider.getAll()).data;
		const indexedRules = this.utils.indexArray(gbr, 'value');

		const animals = (await this.animalProvider.getAll({ _embed: populateFields })).data;
		const indexedAnimals = this.utils.indexArray(animals, '_id');

		const errors = [];
		const res = gb.reduce((total, g) => {
			const animal = indexedAnimals[g.animal as string];
			const rule = indexedRules[g.value];

			let date: Date = null;
			try {
				date = eval(rule.suggestedDate) as Date;
			} catch (error) {
				date = null;
			}
			const sdate = date ? TimeService.parseDate(date) : null;
			if (sdate) {
				g.date = sdate;
				total.push(g);
				return total;
			}
			return total;
		}, [] as GenBookModel[]);

		// void this.excelService.generateExcel([errors], [], 'Errores Categorías');
		await this.genbookProvider.updateBatch(res);
	}

	async linkGenbook() {
		const gb = (await this.genbookProvider.getAll()).data;

		await this.genbookProvider.updateBatch(gb);
	}

	async fixGenBookValues() {
		const gb = (await this.genbookProvider.getAll({ value: 'FL' })).data;

		const newgb = gb.map(g => {
			g.value = 'CF';
			return g;
		});

		const res = this.genbookProvider.updateBatch(newgb);
	}

	async deleteRace() {
		const animals = (await this.animalProvider.getAll({ _show: 'races' })).data;
		const farms = (await this.farmProvider.getAll({ _show: 'races' })).data;
		const gb = (await this.genbookRuleProvider.getAll({ _show: 'race' })).data;
		const racecount = {};
		animals.forEach(({ races }) => {
			(races as string[]).forEach(race => {
				racecount[race] = ((racecount[race] as number) || 0) + 1;
			});
		}, {});
		farms.forEach(({ races }) => {
			(races as string[]).forEach(race => {
				racecount[race] = ((racecount[race] as number) || 0) + 1;
			});
		}, {});
		gb.forEach(({ race }) => {
			racecount[race as string] = ((racecount[race as string] as number) || 0) + 1;
		}, {});
	}

	async calculateGenBook() {
		const officialIds = [];

		const animals = (await this.animalProvider.getAll({ officialId_in: officialIds })).data;

		const res = await this.animalProvider.updateBatch(animals);

		// const animalsToExport = (await this.animalProvider.export({ officialId_in: officialIds })).data;
		// void this.excelService.generateExcel([animalsToExport], [], 'Animales');
	}

	async fixRanches() {
		const ranches = (await this.ranchProvider.getAll({ _show: 'province municipality' })).data;

		// const toUpdate = ranches.map((ranch) => {
		//   const json = JSON.parse(JSON.stringify(ranch)) as RanchModel;
		//   json.municipality = ranch.province;
		//   json.province = ranch.municipality;
		//   return json;
		// });

		// toUpdate.forEach((u, i) => {
		//   if (ranches[i].municipality !== u.province && ranches[i].province !== u.municipality) {

		//   }
		// });

		// await Promise.all(toUpdate.map((r) => this.ranchProvider.update(r)));
	}

	async updateAnimals() {
		const animals = (await this.animalProvider.getAll()).data;
		await this.animalProvider.updateBatch(animals);
	}

	async updateViews() {
		console.log('a');

		const res = await this.animalViewProvider.updateViews();
	}

	async updateBirthViews() {
		const res = await this.birthViewProvider.updateViews();
	}

	async resetProp() {
		console.log('start');
		const res = await this.http.get('bug', { database: HttpService.currentDb });
		console.log('end', res);
	}

	async removeFiliations() {
		const officialIds = [];
		const animals = (await this.animalProvider.getAll({ officialId_in: officialIds, _embed: 'analysis' })).data;
		const aToDelete = animals.reduce((toDelete, animal) => {
			const analysisToDelete = ((animal.analysis as AnalysisModel[]) || []).reduce((total, analysis) => {
				const wanted = analysis.type === AnalysisTypeEnum.fatherFiliation || analysis.type === AnalysisTypeEnum.motherFiliation;
				if (wanted) {
					total.push(analysis);
				}

				return total;
			}, []);

			toDelete = toDelete.concat(analysisToDelete);
			return toDelete;
		}, []) as AnalysisModel[];

		for await (const analysis of aToDelete) {
			await this.analysisProvider.delete(analysis._id);
			console.count('analysis deleted!');
		}
	}

	async generateSampleViews() {
		const samples = (await this.sampleProvider.getAll()).data;
		const res = await this.sampleProvider.updateBatch(samples);
		console.log(res);
	}

	async setDate() {
		const analysis = (await this.analysisProvider.getAll({ date: null })).data;
		analysis.forEach(a => (a.date = new Date(1940, 0, 1, 0, 0) as any));
		const update = await this.analysisProvider.updateBatch(analysis);
		console.log(update);
	}
}
