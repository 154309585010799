<div class="redil-info">
	<h2>Rellena los datos de baja</h2>
	<div class="body">
		<section>
			<div class="info centered">
				<div class="info-row">
					<label for="drop-date">Fecha de baja</label>
					<p-calendar
						id="drop-date"
						appUseUtc
						[(ngModel)]="dropDate"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'dd/mm/yyyy'"
						[maxDate]="maxDate"
						[minDate]="minDate"
					>
					</p-calendar>
				</div>
			</div>

			<div class="buttons centered">
				<button (click)="onDialogClose(dropDate)" class="app-btn primary">Aceptar</button>
				<button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button>
			</div>
		</section>
	</div>
</div>
