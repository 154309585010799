export enum BrucelosisEnum {
	no = 'no aplica',
	suspended = 'calificación suspendida',
	positive = 'm2 positiva',
	negative = 'm2 negativa',
	m3 = 'm3',
	m4 = 'm4',
}

export enum TuberculosisEnum {
	no = 'no aplica',
	suspended = 'calificación suspendida',
	positive = 't2 positiva',
	negative = 't2 negativa',
	t3 = 't3',
}
