import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import links from '../../assets/translate/links.json';
import { ILink } from '../schemes/types/types';
import { HttpService } from './http.service';

@Injectable({
	providedIn: 'root',
})
export class NavigatorService {
	favouriteLinks: ReplaySubject<ILink[]> = new ReplaySubject<ILink[]>(1);
	id: string;

	constructor(private http: HttpService) { }

	getLinks(): ILink[] {
		return links as unknown as ILink[];
	}

	getModuleLinks(mod: string): ILink[] {
		return links.find(({ label }) => label === mod)?.actions as unknown as ILink[];
	}

	getFavouriteLinks() {
		return this.favouriteLinks;
	}

	async updateFavouriteLinks() {
		const newLinks = await this.favouriteLinks.pipe(take(1)).toPromise();

		return this.http.patch(`users/${this.id}`, { data: { settings: { panel: { actions: [...newLinks] } } } }, { database: 'super' });
	}

	// set uid to avoid cyclic dependency. Might need a Settings Service or Remove the user from auth service.
	// Create a User State Service instead
	setFavouriteLinks(actions: ILink[], id?: string) {
		if (id) {
			this.id = id;
		}
		this.favouriteLinks.next(actions);
	}
}
