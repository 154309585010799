import { Pipe, PipeTransform } from '@angular/core';
import { TuberculosisEnum } from 'src/app/schemes/enums/ranches-inspection.enum';

@Pipe({
	name: 'tuberculosisPipe',
})
export class TuberculosisPipe implements PipeTransform {
	transform(inspection: TuberculosisEnum, args?: any): any {
		switch (inspection) {
			case TuberculosisEnum.no: return 'no aplica';
			case TuberculosisEnum.suspended: return 'calificación suspendida';
			case TuberculosisEnum.positive: return 't2 positiva';
			case TuberculosisEnum.negative: return 't2 negativa';
			case TuberculosisEnum.t3: return 't3';
			default: return '';
		}
	}
}
