<div class="redil-info">
	<h2>Localizar</h2>
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="drop-date">Fecha de baja</label>
					<p-calendar id="drop-date" appUseUtc [(ngModel)]="dropDate" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'"
						[disabled]="true" class="disabled-input"></p-calendar>
				</div>
				<div class="info-row">
					<label for="drop-date">Fecha</label>
					<p-calendar id="drop-date" appUseUtc [(ngModel)]="resurrectionDate" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'"
						[minDate]="dropDate"></p-calendar>
				</div>
				<p-message *ngIf="showErrors" severity="error" text="Introduzca una fecha superior a la fecha de baja">
				</p-message>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(true)" class="app-btn orange">Aceptar</button>
			</div>
		</section>
	</div>
</div>