import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { TimeService } from '../../../services/time.service';

@Component({
	selector: 'app-export-filter-options',
	templateUrl: './export-filter-options.component.html',
	styleUrls: ['./export-filter-options.component.scss'],
})
export class ExportFilterOptionsComponent implements OnInit {
	es = ngPrimeLocale;
	date = TimeService.getActualDate();

	exportToday = true;
	firstGenBook: boolean = false;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ExportFilterOptionsComponent>
	) {}

	ngOnInit(): void {}

	closeDialog(mode: 'all' | 'filters' | null) {
		this.dialogRef.close({
			mode,
			date: this.exportToday ? null : this.date,
			firstGenBook: this.firstGenBook,
		});
	}

	changeExportType() {
		this.date = TimeService.getActualDate();
	}
}
