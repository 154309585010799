import { Injectable } from '@angular/core';
import { RaceModel } from '../schemes/models/race.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class RaceProvider extends BaseProvider<RaceModel> {
	constructor(http: HttpService) {
		super('races', http);
	}

	getAllRaces(): Promise<ResponseModel<RaceModel[]>> {
		return this.http.get<ResponseModel<RaceModel[]>>('races', { database: HttpService.currentDb });
	}

	getAssociationRaces(associationDB: string): Promise<ResponseModel<RaceModel[]>> {
		return this.http.get<ResponseModel<RaceModel[]>>('races', { database: associationDB });
	}

	getAssociationRaceById(database: string, id: string): Promise<ResponseModel<RaceModel>> {
		return this.http.get<ResponseModel<RaceModel>>(`races/${id}`, { database });
	}

	saveAssociationRace(database: string, race: RaceModel): Promise<ResponseModel<RaceModel>> {
		return this.http.post<ResponseModel<RaceModel>>('races', { data: race }, { database });
	}

	updateAssociationRace(database: string, race: RaceModel) {
		return this.http.patch<ResponseModel<RaceModel>>(`races/${race._id}`, { data: race }, { database });
	}
}
