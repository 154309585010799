<div class="redil-info">
	<h2>Rellena los datos de la calificación</h2>
	<div class="body" *ngIf="dropDate !== undefined">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="drop-date">Fecha de calificación</label>
					<p-calendar
						id="drop-date"
						appUseUtc
						[(ngModel)]="qualification.date"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					>
					</p-calendar>
				</div>

				<p-message *ngIf="showErrors && !qualification.date" severity="error" text="Fecha necesaria">
				</p-message>

				<p-message
					*ngIf="
						this.qualification.date &&
						this.dropDate &&
						this.qualification.date.getTime() > this.dropDate.getTime()
					"
					severity="error"
					text="La fecha debe ser anterior a la fecha de baja"
				></p-message>

				<p-message
					*ngIf="
						this.qualification.date &&
						this.birthDate &&
						this.birthDate.getTime() > this.qualification.date.getTime()
					"
					severity="error"
					text="La fecha debe ser posterior a la fecha de nacimiento"
				></p-message>

				<p-message
					*ngIf="this.qualification.date && this.qualification.date.getTime() > this.actualDate.getTime()"
					severity="error"
					text="La fecha no puede ser posterior a hoy"
				></p-message>

				<div class="info-row">
					<label for="input-value">Puntuación</label>
					<input
						id="input-value"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="qualification.value"
						pInputText
					/>
				</div>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(qualification)" class="app-btn primary">Aceptar</button>
				<button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button>
			</div>
		</section>
	</div>
</div>
