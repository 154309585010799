import { Component, Input, OnInit } from '@angular/core';
import { ImageExpandService } from '../../services/image-expand.service';

@Component({
	selector: 'app-image-thumbnail',
	templateUrl: './image-thumbnail.component.html',
	styleUrls: ['./image-thumbnail.component.scss'],
})
export class ImageThumbComponent implements OnInit {
	@Input('imageUrl') imageUrl: string;

	/* Due to dependencies to a bad global CSS hierarchy,
	this variable must be declared to prevent unexpected errors in older versions of the app */
	@Input() oldImplementation = false;

	constructor(private imageExpand: ImageExpandService) {}

	ngOnInit(): void {}

	displayImage(imageUrl: string): void {
		this.imageExpand.openDialog(imageUrl);
	}
}
