import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	onGoingPetitionsCount = 0;
	private readonly MUST_DISPLAY_LOADER_METHODS = ['POST', 'PUT', 'DELETE', 'PATCH', 'OPTIONS'];
	constructor(public loaderService: LoaderService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let timeoutId;

		if (this.MUST_DISPLAY_LOADER_METHODS.includes(req.method)) {
			this.loaderService.startBasicLoader();
		} else if (this.onGoingPetitionsCount === 0) {
			timeoutId = setTimeout(() => {
				this.loaderService.startBasicLoader();
			}, 500);
		}

		this.onGoingPetitionsCount += 1;
		return next.handle(req).pipe(
			finalize(() => {
				window.clearTimeout(timeoutId);
				setTimeout(() => {
					this.onGoingPetitionsCount -= 1;
					if (this.onGoingPetitionsCount === 0) {
						this.loaderService.stopBasicLoader();
					}
				}, 50);
			})
		);
	}
}
