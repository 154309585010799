import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Message, MessageService } from 'primeng';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import proposalTranslate from '../../assets/translate/proposal.json';
import { FileStatusEnum } from '../schemes/enums/file-status.enum';
import { ProposalEntityNameEnum } from '../schemes/enums/proposal.enum';
import { AssociationModel } from '../schemes/models/association.model';
import { AssociationService } from './association.service';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

export interface ProposalFirebase {
	id: string;
	createdAt?: string;
	read?: boolean;
	entityName: ProposalEntityNameEnum;
	association: string;
}

export interface UserFile {
	uri: string;
	createdAt: string;
	downloaded?: boolean;
	status: FileStatusEnum;
	association: string;
}

export type FileModel = UserFile & { name: string; type: string };
@Injectable({
	providedIn: 'root',
})
export class FileService {
	private userFiles$: ReplaySubject<Record<string, Record<string, UserFile>>> = new ReplaySubject(1);
	private userProposal$: ReplaySubject<Record<string, Record<string, ProposalFirebase>>> = new ReplaySubject(
		1
	);

	private currentUserFiles: Record<string, Record<string, UserFile>>;
	private currentUserProposals: Record<string, Record<string, ProposalFirebase>>;
	private currentAssociation: AssociationModel;

	constructor(
		private storage: AngularFireStorage,
		private http: HttpClient,
		private db: AngularFireDatabase,
		private auth: AuthService,
		private messageService: MessageService,
		private associationService: AssociationService
	) {
		this.subscribeToUserFiles();
		this.subscribeToUserProposals();
	}

	private subscribeToUserProposals(): void {
		this.auth
			.getAuthState$()
			.pipe(
				switchMap(fbUser =>
					fbUser
						? this.db.object<Record<string, Record<string, ProposalFirebase>>>('proposals/').valueChanges()
						: of(null)
				)
			)
			.subscribe((proposal: Record<string, Record<string, ProposalFirebase>>) => {
				if (proposal === null || Object.keys(proposal).length === 0) {
					this.currentUserProposals = null;
					return this.userProposal$.next(null);
				}

				if (!this.currentUserProposals) {
					this.currentUserProposals = proposal;
					return this.userProposal$.next(proposal);
				}
				this.proposalIsReaded(this.currentUserProposals, proposal);
				this.currentUserProposals = proposal;
				return this.userProposal$.next(proposal);
			});
	}

	private async subscribeToUserFiles(): Promise<void> {
		this.auth
			.getAuthState$()
			.pipe(
				switchMap(fbUser =>
					fbUser
						? this.db.object<Record<string, Record<string, UserFile>>>(`exports/${fbUser.uid}`).valueChanges()
						: of(null)
				)
			)
			.subscribe(async (files: Record<string, Record<string, UserFile>>) => {
				this.currentAssociation = await this.associationService.getCurrentAssociation();
				if (files === null || Object.keys(files).length === 0) {
					this.currentUserFiles = null;
					return this.userFiles$.next(null);
				}

				if (!this.currentUserFiles) {
					this.currentUserFiles = files;
					return this.userFiles$.next(files);
				}

				for (const key in FileStatusEnum) {
					if (
						this.userFileHasGivenStatus(
							this.currentUserFiles[this.currentAssociation._id],
							files[this.currentAssociation._id],
							FileStatusEnum[key]
						)
					) {
						this.addToMessageService(FileStatusEnum[key]);
					}
				}

				this.currentUserFiles = files;
				return this.userFiles$.next(files);
			});
	}

	public getUserFiles$(fileType: string, associationId: string): Observable<FileModel[]> {
		return this.userFiles$.pipe(map(userFiles => this.getFileArray(userFiles, fileType, associationId)));
	}

	public getUserProporsals$(): Observable<ProposalFirebase[]> {
		return this.userProposal$.pipe(map(userProposal => this.proposalJSONtoObject(userProposal)));
	}

	private proposalJSONtoObject(
		userProposal: Record<string, Record<string, ProposalFirebase>>
	): ProposalFirebase[] {
		const list: ProposalFirebase[] = [];
		Object.entries(userProposal).forEach(proposal => {
			const propuesta: ProposalFirebase = {
				id: proposal[0],
				read: Boolean(proposal[1].read),
				entityName: proposal[1].entityName?.toString() as ProposalEntityNameEnum,
				association: proposal[1].association?.toString(),
			};
			list.push(propuesta);
		});
		return list;
	}

	/**
	 * Using PrimeNG Messages service
	 * severity: success | info | warn | error
	 * https://www.primefaces.org/primeng/showcase/#/messages
	 */
	private addToMessageService(status: FileStatusEnum): void {
		let message: Message;
		switch (status) {
			case FileStatusEnum.success:
				message = {
					severity: 'success',
					summary: 'Se ha generado un fichero',
					detail: 'Dirígete al apartado de ficheros para descargarlo',
				};
				break;

			case FileStatusEnum.queue_error:
				message = {
					severity: 'error',
					summary: 'Se ha producido un error al registrar la solicitud',
					detail: 'Vuelve a intentarlo más tarde o contacte con nosotros',
				};
				break;

			case FileStatusEnum.error:
				message = {
					severity: 'error',
					summary: 'Se ha producido un error al generar un fichero',
					detail: 'Vuelve a intentarlo más tarde o contacte con nosotros',
				};
				break;

			case FileStatusEnum.pending:
				message = {
					severity: 'info',
					summary: 'Solicitud registrada correctamente',
					detail: 'El sistema se dispondrá a procesarla en breve',
				};
				break;

			default:
				break;
		}

		message && this.messageService.add(message);
	}

	private addToMessageProposalService(entityName: ProposalEntityNameEnum): void {
		const message: Message = {
			severity: 'success',
			summary: `Se ha generado una propuesta de tipo ${proposalTranslate[entityName]}`,
			detail: 'Dirígete al apartado de propuestas para verlo',
			life: 4000,
		};

		message && this.messageService.add(message);
	}

	private userFileHasGivenStatus(
		currentUserFiles: Record<string, UserFile>,
		newFiles: Record<string, UserFile>,
		status: FileStatusEnum
	): boolean {
		for (const [type, filesOfType] of Object.entries(newFiles || {})) {
			if (currentUserFiles) {
				const currentFilesOfType = currentUserFiles[type] || {};
				const fileOccurrenceExists = Object.entries(filesOfType || {}).some(([name, file]) => {
					const currentFile = currentFilesOfType[name]?.status === status ? currentFilesOfType[name] : null;
					return (
						file.status === status &&
						(!currentFile ||
							currentFile.status !== file.status ||
							currentFile.createdAt !== file.createdAt ||
							currentFile.uri !== file.uri)
					);
				});
				if (fileOccurrenceExists) {
					return fileOccurrenceExists;
				}
			}
		}
		return false;
	}

	private proposalIsReaded(
		oldProposals: Record<string, Record<string, ProposalFirebase>>,
		newProposals: Record<string, Record<string, ProposalFirebase>>
	): void {
		const objectListOldProposal = this.proposalJSONtoObject(oldProposals);
		const objectListNewProposal = this.proposalJSONtoObject(newProposals);
		if (objectListOldProposal.length < objectListNewProposal.length) {
			const diffrentListItem = objectListNewProposal.filter(
				({ id: id1 }) => !objectListOldProposal.some(({ id: id2 }) => id2 === id1)
			);
			if (diffrentListItem.length > 0) {
				const newProposal: ProposalFirebase = diffrentListItem[0];
				if (newProposal.read === false) {
					this.addToMessageProposalService(newProposal.entityName as ProposalEntityNameEnum);
				}
			}
		}
	}

	private getFileArray(
		userFiles: Record<string, Record<string, UserFile>> = {},
		fileType: string = '',
		associationId: string = ''
	): FileModel[] {
		if (userFiles) {
			const associationUserFiles = userFiles[associationId];
			return Object.entries(associationUserFiles || {}).reduce((files, [type, filesOfType]) => {
				if (type.includes(fileType)) {
					const fileArrayOfType = Object.entries(filesOfType || {}).map(([name, value]) => ({
						name,
						type,
						...value,
					}));
					files = files.concat(fileArrayOfType || []);
				}
				return files;
			}, []);
		}
		return [];
	}

	markExcelAsDownloaded(fileUri: string): Promise<void> {
		return this.db.object(`${fileUri}/downloaded`).set(true);
	}

	markProposalAsReaded(proposalUri: string): Promise<void> {
		return this.db.object(`${proposalUri}/read`).set(true);
	}

	deleteProposalData(proposalUri: string): Promise<void> {
		return this.db.object(`${proposalUri}`).remove();
	}

	changeFileStatus(uid: string, fileName: string, status: UserFile['status']): Promise<void> {
		return this.db.object(`/exports/${uid}/animals/${fileName}/status`).set(status);
	}

	async getFireStorageFileMetadata(url: string): Promise<any> {
		const refFromURL = decodeURIComponent(url.split('/').pop().split('?')[0]);
		const ref = this.storage.ref(refFromURL);
		const metadata = await ref.getMetadata().pipe(take(1)).toPromise();
		const customMetadata = metadata.customMetadata;
		return customMetadata;
	}

	public uploadFile(
		file: File | Blob,
		toPath: string,
		database: string = HttpService.currentDb,
		customMetadata: any = null
	): Promise<string> {
		return new Promise(resolve => {
			const fileRef = this.storage.ref(`${database}/${toPath}`);
			const uploadTask: AngularFireUploadTask = this.storage.upload(`${database}/${toPath}`, file, {
				customMetadata,
			});
			uploadTask
				.snapshotChanges()
				.pipe(finalize(() => resolve(fileRef.getDownloadURL().pipe(take(1)).toPromise())))
				.subscribe();
		});
	}

	getFileUrl(path: string, database: string = HttpService.currentDb): Promise<string> {
		return this.storage
			.ref(`${database}/${path}`)
			.getDownloadURL()
			.pipe(take(1))
			.toPromise() as Promise<string>;
	}

	getFileBlobFromUrl(url: string): Promise<Blob> {
		return this.http.get(url, { responseType: 'blob' }).pipe(take(1)).toPromise();
	}

	getFireStorageFile(url: string): Promise<Blob> {
		return this.http
			.get(url, { responseType: 'blob', headers: { firebaseHttpInterceptor: 'true' } })
			.pipe(take(1))
			.toPromise();
	}

	public deleteFile(path: string): Observable<unknown> {
		return this.storage.ref(path).delete();
	}

	uploadDocs(file: File, parent: string): Observable<any> {
		const formData = new FormData();
		const data = { parent, name: file.name };
		formData.append('file', file);
		formData.append('data', JSON.stringify(data));

		const httpOptions = {
			headers: new HttpHeaders({
				database: HttpService.currentDb,
				errorHandlerHttpInterceptor: 'false',
			}),
		};
		return this.http
			.post<any>(`${environment.apiUrl}upload`, formData, httpOptions)
			.pipe(catchError(this.handleError));
	}

	private handleError(error: HttpErrorResponse) {
		let errorMessage = 'Error al subir el archivo';
		if (error.error instanceof ErrorEvent) {
			errorMessage = `Error: ${error.error.message}`;
		} else {
			errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
		}
		console.error(errorMessage);
		return throwError(errorMessage);
	}
}
