import { Injectable } from '@angular/core';
import { BirthViewModel } from '../schemes/models/birth-view.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class BirthViewProvider extends BaseProvider<BirthViewModel> {
	constructor(http: HttpService) {
		super('birth-views', http);
	}

	updateViews(): Promise<ResponseModel<BirthViewModel>> {
		return this.http.post<ResponseModel<BirthViewModel>>(
			'birth-views/update-views',
			{ data: {} },
			{ database: this.database || HttpService.currentDb },
		);
	}
}
