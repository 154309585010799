<main class="centered">
	<p class="title">Selecciona una ganadería con la que trabajar</p>
	<div class="search-cnt">
		<img *ngIf="!filter" src="../../../assets/images/icons/loupe.svg" alt="" />
		<input type="text" (keyup)="onFilterFarms()" [(ngModel)]="filter" placeholder="Buscar ganadería" />
	</div>
	<div class="farm-list centered">
		<div class="all-farms centered" (click)="onSelectFarm()" *ngIf="!hasToSelectFarm">Ver todas las ganaderías</div>
		<div class="redil-row" *ngFor="let farm of filteredFarms" (click)="onSelectFarm(farm)">
			<div class="blue-circle"></div>
			<p>{{ farm.name | titlecase }}</p>
		</div>
	</div>
</main>