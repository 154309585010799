import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { RoleEnum } from '../schemes/enums/role.enum';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	private readonly DEFAULT_REDIRECT_URL = '/modules';
	private readonly UNLIMITED_ROLE = RoleEnum.super;

	constructor(private authService: AuthService, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		return this.canContinue(route);
	}

	async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
		return this.canContinue(route);
	}

	private async canContinue(route: ActivatedRouteSnapshot): Promise<boolean> {
		const redirectUrl = (route.data.redirectUrl as string) || this.DEFAULT_REDIRECT_URL;
		const allowedRoles = route.data.roles as Array<RoleEnum>;
		const user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();

		const cannotContinue = !user || (!allowedRoles.includes(user.role) && user.role !== this.UNLIMITED_ROLE);
		if (cannotContinue) {
			this.router.navigateByUrl(redirectUrl);
			return false;
		}

		return true;
	}
}
