import { Pipe, PipeTransform } from '@angular/core';
import proposal from '../../../assets/translate/proposal.json';

@Pipe({
	name: 'proposalPipe',
})
export class ProposalPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		return proposal[value] || value;
	}
}
