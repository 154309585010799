import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleEnum } from 'src/app/schemes/enums/role.enum';
import { AuthGuard } from './guards/auth.guard';
import { CompositeGuard } from './guards/composite.guard';
import { LoginGuard } from './guards/login.guard';
import {
	CheckModuleAvailability,
	CheckModuleExistence,
	CheckSubModuleExistence,
	ModuleGuard,
	RedirectModule,
} from './guards/module.guard';
import { AssociationSelectorComponent } from './pages/association-selector/association-selector.component';
import { FarmSelectorComponent } from './pages/farm-selector/farm-selector.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { ModulesSelectorComponent } from './pages/modules-selector/modules-selector.component';
import { PagenotallowedComponent } from './pages/pagenotallowed/pagenotallowed.component';
import { PageNotFoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PagenotyetComponent } from './pages/pagenotyet/pagenotyet.component';
import { SubmodulesSelectorComponent } from './pages/submodules-selector/submodules-selector.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoginGuard],
	},
	{
		path: 'select-association',
		component: AssociationSelectorComponent,
		canActivate: [AuthGuard],
		data: {
			roles: ['farmer', 'tech', 'admin', 'super'],
			redirectUrl: '/login',
		},
	},
	{
		path: 'select-farm',
		component: FarmSelectorComponent,
		canActivate: [AuthGuard, ModuleGuard],
		data: {
			roles: ['farmer', 'tech', 'admin', 'super'],
			redirectUrl: '/login',
		},
	},
	{
		path: '',
		component: MainComponent,
		canActivate: [CompositeGuard],
		data: {
			routeGuards: [AuthGuard, ModuleGuard],
			roles: ['super', 'tech', 'admin', 'farmer'],
			redirectUrl: '/login',
		},
		children: [
			{ path: '', redirectTo: 'modules', pathMatch: 'full' },
			{ path: 'modules', component: ModulesSelectorComponent },
			{
				path: 'modules/:module',
				canActivate: [CompositeGuard],
				data: {
					routeGuards: [
						CheckModuleAvailability,
						CheckSubModuleExistence,
						RedirectModule,
						CheckModuleExistence,
					],
				},
				component: SubmodulesSelectorComponent,
			},
			{
				path: 'book',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'tech', 'admin', 'farmer'],
					redirectUrl: '/login',
				},
				children: [
					{ path: '', redirectTo: 'animals', pathMatch: 'full' },
					{
						path: 'animals',
						loadChildren: () => import('./pages/book/animals/animals.module').then(m => m.AnimalsModule),
					},
					{
						path: 'farms',
						loadChildren: () => import('./pages/book/farms/farms.module').then(m => m.FarmsModule),
					},
					{
						path: 'ranches',
						loadChildren: () => import('./pages/book/ranches/ranches.module').then(m => m.RanchesModule),
					},
					{
						path: 'breedings',
						loadChildren: () =>
							import('./pages/book/breedings/breedings.module').then(m => m.BreedingsModule),
					},
					{
						path: 'births',
						loadChildren: () => import('./pages/book/births/births.module').then(m => m.BirthsModule),
					},
				],
			},
			{
				path: 'morfologic',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'admin', 'tech', 'farmer'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/morfologic/morfologic.module').then(m => m.MorfologicModule),
					},
				],
			},
			{
				path: 'dna',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'admin', 'tech', 'farmer'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/dna/analysis.module').then(m => m.AnalysisModule),
					},
				],
			},
			{
				path: 'proposals',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'admin'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/proposal/proposal.module').then(m => m.ProposalModule),
					},
				],
			},
			{
				path: 'files',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: {
					roles: ['farmer', 'tech', 'admin', 'super'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/files/files.module').then(m => m.FilesModule),
					},
				],
			},
			{
				path: 'documents',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: {
					roles: [RoleEnum.super, RoleEnum.admin],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule),
					},
				],
			},
			{
				path: 'users',
				canActivate: [AuthGuard, ModuleGuard],
				canActivateChild: [AuthGuard, ModuleGuard],
				data: {
					roles: [RoleEnum.super, RoleEnum.admin],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
					},
				],
			},
			{
				path: 'associations',
				canActivate: [AuthGuard, ModuleGuard],
				canActivateChild: [AuthGuard, ModuleGuard],
				data: {
					roles: [RoleEnum.super, RoleEnum.admin],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () =>
							import('./pages/association/association.module').then(m => m.AssociationModule),
					},
				],
			},
			{
				path: 'races',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/race/race.module').then(m => m.RaceModule),
					},
				],
			},
			{
				path: 'genbookrules',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () =>
							import('./pages/genbookrules/genbookrules.module').then(m => m.GenbookrulesModule),
					},
				],
			},
			{
				path: 'profile',
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'admin', 'farmer', 'tech'],
					redirectUrl: '/login',
				},
				children: [
					{
						path: '',
						loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
					},
				],
			},
			{
				path: 'performance-control',
				loadChildren: () =>
					import('./pages/performance-control/performance-control.module').then(
						m => m.PerformanceControlModule
					),
				canActivate: [CompositeGuard],
				canActivateChild: [CompositeGuard],
				data: {
					routeGuards: [AuthGuard, ModuleGuard],
					roles: ['super', 'admin', 'farmer'],
					redirectUrl: '/login',
				},
			},
		],
	},
	{ path: 'pagenotallowed', component: PagenotallowedComponent },
	{ path: 'pagenotyet', component: PagenotyetComponent },
	{ path: 'pagenotfound', component: PageNotFoundComponent },
	{ path: '**', component: PageNotFoundComponent, canActivate: [CheckModuleAvailability] },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
