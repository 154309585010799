import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-new-folder',
	templateUrl: './new-folder.component.html',
	styleUrls: ['./new-folder.component.scss'],
})
export class NewFolderComponent implements OnInit {
	folderName: string = '';
	pattern = /^[a-zA-Z0-9\-\s]{1,}$/;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<NewFolderComponent>
	) {}

	ngOnInit(): void {}

	onClose(): void {
		this.dialogRef.close({ folderName: null });
	}

	validateInput(): boolean {
		return this.pattern.test(this.folderName);
	}

	onCreate(): void {
		this.dialogRef.close({ folderName: this.folderName });
	}
}
