import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WeightTypeEnum } from 'src/app/schemes/enums/weight-type.enum';
import { AnimalModel } from 'src/app/schemes/models/animal.model';
import { WeightModel } from 'src/app/schemes/models/weight.model';
import { FarmService } from 'src/app/services/farm.service';
import { TimeService } from 'src/app/services/time.service';
import ngPrimeLocale from '../../../../../assets/locale/ngprime.json';

@Component({
	selector: 'app-filter-weight-modal',
	templateUrl: './filter-weight-modal.component.html',
	styleUrls: ['./filter-weight-modal.component.scss'],
})
export class FilterWeightModalComponent implements OnInit {
	filters: { [key in keyof WeightModel]?: any } = {
		weight: {
			min: null,
			max: null,
		},
		date: {
			start: null,
			end: null,
		},
		animal: {
			officialId: null,
		},
	};

	animal: AnimalModel;
	weightTypes: { name: string; value: WeightTypeEnum }[] = [];
	weight: WeightModel;
	farmId: string;

	selectedFarm: any;
	selectedOwnerFarm: any;
	selectedBreederFarm: any;

	es = ngPrimeLocale;

	urlToNavigate: string = '/performance-control/';

	constructor(
		private router: Router,
		private farmService: FarmService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterWeightModalComponent>
	) {}

	async ngOnInit(): Promise<void> {
		this.setFiltersValues(this.data);
		this.farmId = this.data.filters.farm || (await this.farmService.getCurrentFarm())?._id;
	}

	async onSaveFilters(): Promise<void> {
		const urlFilters = this.parseFilters(this.filters);
		this.dialogRef.close(urlFilters);

		this.router.navigate(['/performance-control/'], { queryParams: urlFilters }).then(() => {});
	}

	parseFilters(filters: Record<string, unknown>): Record<string, any> {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (!value) {
				return newFilters;
			}
			if (key.includes('weight')) {
				if (value['min'] !== null && value['min'] !== undefined) {
					const min = parseFloat(value['min']);
					if (!isNaN(min)) {
						newFilters[`${key}_gte`] = min;
					}
				}
				if (value['max'] !== null && value['max'] !== undefined) {
					const max = parseFloat(value['max']);
					if (!isNaN(max)) {
						newFilters[`${key}_lte`] = max;
					}
				}
			} else if (key.toLowerCase().includes('date')) {
				if (value['start']) {
					newFilters[`${key}_gte`] = new Date(value['start']).toISOString();
				}
				if (value['end']) {
					newFilters[`${key}_lte`] = new Date(value['end']).toISOString();
				}
			} else if (key.toLowerCase().includes('animal')) {
				if (value['officialId']) {
					newFilters['officialId_like'] = value['officialId'] as string;
				}
			} else if (typeof value === 'string') {
				newFilters[`${key}_like`] = value;
			} else if (typeof value === 'number') {
				newFilters[`${key}`] = value;
			}
			return newFilters;
		}, {});
	}

	onClearFilters(): void {
		this.filters = {
			weight: {
				min: null,
				max: null,
			},
			date: {
				start: null,
				end: null,
			},
			animal: {
				officialId: null,
			},
		};
		this.dialogRef.close();
		this.router.navigate([this.urlToNavigate], { queryParams: {} });
	}

	setFiltersValues(data: { filters: { [key: string]: string | any[] } }): boolean {
		if (!data.filters) {
			return;
		}
		if (data.filters.weight_gte) {
			this.filters.weight.min = data.filters.min_gte as unknown as Number;
		}
		if (data.filters.weight_lte) {
			this.filters.weight.max = data.filters.max_lte as unknown as Number;
		}
		if (data.filters.date_gte) {
			this.filters.date.start = TimeService.parseDate(data.filters.date_gte as string);
		}
		if (data.filters.date_lte) {
			this.filters.date.end = TimeService.parseDate(data.filters.date_lte as string);
		}
		if (data.filters.officialId_like) {
			this.filters.animal.officialId = data.filters.officialId_like;
		}
	}
}
