import { Injectable } from '@angular/core';

/* import { LoadingController, ToastController } from '@ionic/angular'; */
const HOURS = 3600 * 1000;
const DAY = 24 * HOURS;
const WEEK = 7 * DAY;
const YEAR = 365 * DAY;

const times = [
	{ label: 'Días', value: DAY },
	{ label: 'Semanas', value: WEEK },
	{ label: 'Años', value: YEAR },
];

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	static getRegexEmailValidation: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	constructor() { }

	public getArrayFromObject(object: Record<string, unknown>): unknown[] {
		if (object) {
			return Object.keys(object).map((key: string) => object[key]);
		}
		return [];
	}

	public indexArray<T>(objArray: T[], key: keyof T): { [key: string]: T } {
		return objArray.reduce((indexedObj, obj) => {
			const id: string = (obj[key] as unknown) as string;
			return {
				...indexedObj,
				[id]: obj,
			};
		}, {});
	}

	groupArray<T>(objArray: T[], key: keyof T): { [key: string]: T[] } {
		return objArray.reduce((indexedObj: { [key: string]: T[] }, obj: T) => {
			const id: string = (obj[key] as unknown) as string;
			const arr = indexedObj.hasOwnProperty(id) ? indexedObj[id] : [obj];
			arr.push(obj);
			return {
				...indexedObj,
				[id]: arr,
			};
		}, {});
	}

	public arrayToString(array: string[]): string {
		if (array.length === 0) {
			return '';
		} if (array.length === 1) {
			return array[0];
		} if (array.length === 2) {
			return `${array[0]} y ${array[1]}`;
		}
		let str = array[0];
		const subarray = array.slice(1, -1);
		for (const substr of subarray) {
			str += `, ${substr}`;
		}
		return `${str} y ${array[array.length - 1]}`;
	}

	parseTimestamp(timestamp: any): string {
		if (typeof timestamp !== 'number') {
			return '0 Días';
		}
		for (const time of times) {
			if (timestamp % time.value === 0) {
				return `${timestamp / time.value} ${time.label}`;
			}
		}
		return `${timestamp / times[0].value} ${times[0].label}`;
	}

	public presentLoading(): void {
		/*  this.loading = await this.loadingController.create({
				 spinner: 'crescent'
			 });
			 this.loading.present(); */
		console.error('presentLoading()', 'Método aún no implementado');
	}

	public dissmissLoading(): void {
		/* this.loading.dismiss(); */
		console.error('Método aún no implementado');
	}

	public showToast(message: string, position: 'top' | 'bottom' | 'middle' = 'top', duration = 2000): void {
		/* let toast = await this.toastCtrl.create({
				message: message,
				position: position,
				duration: duration
			}); */

		/*  await toast.present(); */

		console.error('Método aún no implementado');
	}

	public async errorHandling(e) {
		let error = e;
		if (!e.code && e.error) {
			error = e.error;
		}

		if (error.code) {
			switch (error.code) {
				case 'auth/user-not-found':
					// TODO: Cambiar este texto a translate.
					await this.showToast('No se ha encontrado usuario con este email. Regístrate!', 'top', 4000);
					break;
				default:
					await this.showToast(error.message, 'top', 4000);
					break;
			}
		} else {
			console.error('Error en el catch: ', e);
		}
		return null;
	}

	trimObjectsFields(data: { [key: string]: string | number }): { [key: string]: string | number } {
		Object.entries(data).forEach(([key, value]) => {
			if (typeof value === 'string') {
				data[key] = value.trim();
			}
		});
		return data;
	}

	public compareDates(d1: Date, d2: Date): number {
		const date1 = new Date(d1);
		const date2 = new Date(d2);

		if (date1 > date2) {
			return 1;
		} if (date1 < date2) {
			return -1;
		}
		return 0;
	}
}
