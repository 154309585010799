<form [formGroup]="placesForm" class="form-places">
	<app-form-input
		inputType="select"
		[selectData]="communityView"
		[formController]="formUtils.convertToFormControl(placesForm.get('community'))"
		inputLabel="Comunidad autónoma"
		[isRequired]="true"
		placeholder="Seleccione una comunidad autónoma"
		(onSelect)="onSelectCommunity($event)"
	></app-form-input>

	<app-form-input
		inputType="select"
		[selectData]="provinceView"
		[formController]="formUtils.convertToFormControl(placesForm.get('province'))"
		inputLabel="Provincia"
		[isRequired]="true"
		placeholder="Seleccione una provincia"
		(onSelect)="onSelectProvince($event)"
	></app-form-input>

	<app-form-input
		*ngIf="islandView.length"
		inputType="select"
		[selectData]="islandView"
		[formController]="formUtils.convertToFormControl(placesForm.get('island'))"
		inputLabel="Isla"
		placeholder="Seleccione una isla"
		(onSelect)="onSelectIsland($event)"
	></app-form-input>

	<app-form-input
		inputType="select"
		[selectData]="municipalityView"
		[formController]="formUtils.convertToFormControl(placesForm.get('municipality'))"
		inputLabel="Municipio"
		[isRequired]="true"
		placeholder="Seleccione un municipio"
		(onSelect)="onSelectMunicipality($event)"
	></app-form-input>

	<app-form-input
		inputType="number"
		[formController]="formUtils.convertToFormControl(placesForm.get('cp'))"
		inputLabel="Código Postal"
		[isRequired]="true"
		placeholder="Introduzca un código postal"
	></app-form-input>
</form>
