import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleModel } from 'src/app/schemes/models/sample.model';

@Component({
	selector: 'app-analysis-processing-summary',
	templateUrl: './analysis-processing-summary.component.html',
	styleUrls: ['./analysis-processing-summary.component.scss'],
})
export class AnalysisProcessingSummaryComponent implements OnInit {
	summary: { type: string; samples: string[] }[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { [key: string]: SampleModel[] },
		public dialogRef: MatDialogRef<AnalysisProcessingSummaryComponent>,
	) { }

	ngOnInit(): void {
		this.summary = Object.entries(this.data).map(([sampleType, samples]) => ({ type: sampleType, samples: samples.map(s => s.code) }));
	}

	onClose(resultData: boolean): void {
		this.dialogRef.close({ result: resultData });
	}
}
