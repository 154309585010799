import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleModel } from '../../../schemes/models/sample.model';

@Component({
	selector: 'app-sample-splitting-modal',
	templateUrl: './sample-splitting-modal.component.html',
	styleUrls: ['./sample-splitting-modal.component.scss'],
})
export class SampleSplittingModalComponent implements OnInit {
	previousSample: SampleModel;
	samplesCodes: string[] = [];
	newSample: SampleModel;
	validated = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { sample: SampleModel; samplesCodes: string[] },
		public dialogRef: MatDialogRef<SampleSplittingModalComponent>,
	) { }

	ngOnInit(): void {
		this.previousSample = JSON.parse(JSON.stringify(this.data.sample));
		this.samplesCodes = this.data.samplesCodes;
		this.newSample = {
			analysis: this.previousSample.analysis,
			originalSample: this.previousSample._id,
			code: '',
			quantity: 0,
			type: this.previousSample.type,
			createdAt: this.previousSample.createdAt,
			animal: this.previousSample.animal,
		};
	}

	onDivideSample() {
		if (this.validateSampleSplit()) {
			this.previousSample.quantity -= this.newSample.quantity;
			this.onClose({ previousSample: this.previousSample, newSample: this.newSample });
		}
	}

	onClose(samples: { previousSample: SampleModel; newSample: SampleModel } | boolean = false) {
		this.dialogRef.close(samples);
	}

	validateSampleSplit() {
		this.validated = true;
		return (
			this.newSample.code !== ''
			&& this.newSample.quantity >= 0
			&& this.newSample.quantity <= this.previousSample.quantity
			&& !this.sampleCodeIsDuplicated()
		);
	}

	sampleCodeIsDuplicated() {
		return (this.samplesCodes || []).find(sampleCode => sampleCode === this.newSample.code);
	}
}
