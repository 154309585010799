<div class="filter-dialog">
	<div class="bg info"></div>
	<p>¿Quieres recalcular las categorías de los animales teniendo en cuenta los filtros actuales?</p>
	<p>
		AVISO: Es un proceso lento y por ello se recomienda recalcular con filtros, por ejemplo seleccionando por
		ganadería
	</p>
	<!-- <div class="info-row" *ngIf="data?.dateMode">
		<div>
			<p-checkbox [binary]="true" [(ngModel)]="exportToday" (onChange)="changeExportType()"></p-checkbox>
			<p for="drop-date">Exportar a día de hoy</p>
		</div>
		<div *ngIf="!exportToday">
			<label for="drop-date">Fecha</label>
			<p-calendar id="drop-date" appUseUtc [(ngModel)]="date" [locale]="es.calendar_es" dateFormat="dd/mm/yy"
				[showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'"></p-calendar>
		</div>
	</div> -->
	<button class="app-btn tertiary" (click)="closeDialog('filters')">
		Sí, <span class="italics">continuar con filtros actuales </span>
	</button>
	<button class="app-btn tertiary" (click)="closeDialog('all')">
		No, <span class="italics">continuar sin filtros</span>
	</button>
	<button class="app-btn optional" (click)="closeDialog(null)">Cancelar</button>
</div>
