<div class="header">
	<h2>Informe Zootécnico</h2>
	<img src="../../../../../assets/images/icons/cancel-icon.svg" alt="" (click)="dialogRef.close()" />
</div>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-ownerFarm">Fecha</label>
					<p-calendar
						id="birth-date"
						appUseUtc
						[(ngModel)]="reportDate"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'dd/mm/yyy'"
						[maxDate]="maxDate"
						[minDate]="minDate"
					>
					</p-calendar>
				</div>
				<p-message *ngIf="dateError" severity="error" text="Debe seleccionar una fecha"></p-message>
			</div>
		</section>
		<div class="centered">
			<button (click)="onGenerateReport()" class="app-btn orange">Generar informe</button>
		</div>
	</div>
</div>
