import { Injectable } from '@angular/core';
import { BirthModel } from '../schemes/models/birth.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class BirthProvider extends BaseProvider<BirthModel> {
	constructor(http: HttpService) {
		super('births', http);
	}
}
