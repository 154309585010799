import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageData } from 'src/app/schemes/types/image-data';

@Component({
	selector: 'app-image-expand-display',
	templateUrl: './image-expand-display.component.html',
	styleUrls: ['./image-expand-display.component.scss'],
})
export class ImageExpandDisplayComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ImageData,
		private dialogRef: MatDialogRef<ImageExpandDisplayComponent>
	) {}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialogRef.close();
	}
}
