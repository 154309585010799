import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Ability } from '@casl/ability';
import * as firebase from 'firebase/app';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AssociationProvider } from '../providers/association.provider';
import { UserProvider } from '../providers/user.provider';
import { RoleEnum } from '../schemes/enums/role.enum';
import { UserModel } from '../schemes/models/user.model';
import { AssociationService } from './association.service';
import { FarmService } from './farm.service';
import { NavigatorService } from './navigator.service';
import { AdminRules } from '../shared/permissions/admin.permissions';
import { SuperRules } from '../shared/permissions/superadmin.permissions';
import { FarmerRules } from '../shared/permissions/farmer.permissions';
import { TechRules } from '../shared/permissions/tech.permissions';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private currentUser: ReplaySubject<UserModel> = new ReplaySubject(1);
	private subcription: Subscription;
	constructor(
		private afAuth: AngularFireAuth,
		private userProvider: UserProvider,
		private associationService: AssociationService,
		private associationProvider: AssociationProvider,
		private navigatorService: NavigatorService,
		private farmService: FarmService,
		private ability: Ability
	) {
		this.setCurrentUser();
	}

	public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
		const user = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
		await this.setCurrentUser();
		return user;
	}

	private updateAbility(user: UserModel): void {
		if (user.role === RoleEnum.super) {
			const rules = SuperRules(user);
			this.ability.update(rules);
		} else if (user.role === RoleEnum.admin) {
			const rules = AdminRules(user);
			this.ability.update(rules);
		} else if (user.role === RoleEnum.farmer) {
			const rules = FarmerRules(user);
			this.ability.update(rules);
		} else if (user.role === RoleEnum.tech) {
			const rules = TechRules(user);
			this.ability.update(rules);
		}
	}

	public logout(): Promise<void> {
		return this.afAuth.auth.signOut();
	}

	public createUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
		return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
	}

	public getAuthState$(): Observable<firebase.User> {
		return this.afAuth.authState;
	}

	public getCurrentUser(): Observable<UserModel> {
		return this.currentUser;
	}

	public getCurrentFirebaseUserEmail(): string {
		return this.afAuth.auth.currentUser.email;
	}

	public updateFirebaseUserEmail(email: string): Promise<void> {
		return this.afAuth.auth.currentUser.updateEmail(email);
	}

	public resetPassword(email: string): Promise<void> {
		return this.afAuth.auth.sendPasswordResetEmail(email);
	}

	private setCurrentUser(): Promise<boolean> {
		return new Promise(resolve => {
			this.subcription = this.afAuth.authState
				.pipe(switchMap(fbUser => (fbUser ? this.userProvider.getUserByUID(fbUser.uid) : of(null))))
				.subscribe(async (user: UserModel | null) => {
					try {
						if (user) {
							this.updateAbility(user);
							if (user.associations?.length === 1) {
								const { data: association } = await this.associationProvider.getById(
									user.associations[0] as string
								);
								if (!this.associationService.getCurrentAssociation()) {
									await this.associationService.setCurrentAssociation(association._id);
									if (user.role === RoleEnum.farmer) {
										await this.farmService.setCurrentFarm();
									}
								}
							}
							this.navigatorService.setFavouriteLinks(user.settings?.panel?.actions || [], user._id);
						} else {
							this.navigatorService.setFavouriteLinks([]);
						}
					} catch (error) {
						console.error(error);
					}
					this.currentUser.next(user);
					resolve(true);
				});
		});
	}
}
