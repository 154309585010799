<div class="summary" *ngIf="!noErrors">
	<h2>Se han encontrado los siguientes errores</h2>
	<div class="errors">
		<div class="error-row" *ngFor="let error of errorTitles">
			<p *ngIf="data.data?.errors[error].length > 0">
				- {{ error | importErrors }}: {{ data.data.errors[error].length }} errores
			</p>
		</div>
	</div>
	<button class="app-btn tertiary excel-btn" (click)="onExportDetails()">Exportar detalles</button>
</div>
<div class="summary success" *ngIf="noErrors">
	<h2>Importar elementos</h2>
	<p class="success">
		Se van a importar {{ success.length }} elementos {{ !noWarnings ? 'con algunas incongruencias' : '' }}.
		¿Desea continuar?
	</p>
	<div class="errors" *ngIf="!noWarnings">
		<div class="error-row" *ngFor="let warn of warningTitles">
			<p *ngIf="data.data?.warnings[warn].length > 0">
				- {{ warn | importErrors | titlecase }}: {{ data.data.warnings[warn].length }} incongruencias
			</p>
		</div>
	</div>
	<button class="app-btn tertiary excel-btn" *ngIf="!noWarnings" (click)="onExportDetails()">
		Exportar detalles
	</button>
	<div class="btn-grp centered" [ngStyle]="{ position: noWarnings ? 'absolute' : 'unset' }">
		<button class="app-btn primary" (click)="onClose(true)">Aceptar</button>
		<button class="app-btn optional" (click)="onClose(false)">Cancelar</button>
	</div>
</div>
