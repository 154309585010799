import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutoComplete, LocaleSettings } from 'primeng';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import {
	CUSTOM_MULTISELECT_ARRAY_SEPARATOR,
	IFormInputValidator,
	InputFormatEnum,
	InputFormatType,
	ISelectData,
} from '../../schemes/types/form-inputs';
import { StringUtilsService } from '../../services/string-utils.service';
import { TextFormatPipe } from '../../shared/pipes/text-format.pipe';

enum InputTypeEnum {
	number = 'number',
	text = 'text',
	date = 'date',
	select = 'select',
	textarea = 'textarea',
	multiselect = 'multiselect',
	password = 'password',
}
type InputType = keyof typeof InputTypeEnum;

const REQUIRED_SYMBOL = '*';

/**
 * Form-input component
 * NOTE: You can change the form controller values manually and the effects will take place in this component too.
 * @param formController - Required in order to make the component work.
 * @param inputType - Default 'text'. Determines which type of input the component must create.
 * @param selectData - Required when @param inputType is equal to 'select'.
 * This parameter is modeled by ISelectData interface. Attribute 'value' is always the one saved into the form controller.
 * If you want to display a different text in the select box, add it into the 'displayValue' attribute.
 * @param isRequired - Shows the input label with a required symbol (const REQUIRED_SYMBOL) appended to it.
 * @param inputLabel - Optional. When added the component displays a text label next to the input field.
 * @param layoutOrientation - Default 'row'. Changes the orientation of the label and input structure.
 * @param format - Optional. When added, the component will format the input values on change.
 * @param placeholder - Optional. When added the component displays a short hint value in the input field.
 * @param onSelect - Output parameter. Emits an event on field selection.
 * @param canTogglePwd - Optional. When added the component will display a button to toggle the password visibility.
 * @param maxDate - Optional. When added the component will display a datepicker with a maximum date.
 * @param minDate - Optional. When added the component will display a datepicker with a minimum date.
 */
@Component({
	selector: 'app-form-input',
	templateUrl: './form-input.component.html',
	styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit, OnChanges, OnDestroy {
	public inputTypeEnum = InputTypeEnum;
	public ngPrimeLocale = ngPrimeLocale.calendar_es as unknown as LocaleSettings;
	@ViewChild('autocomplete') autocomplete: AutoComplete;
	@Input() inputType: InputType = 'text';
	@Input() formController: FormControl;
	@Input() inputLabel: string;
	@Input() layoutOrientation: 'row' | 'column' = 'row';
	@Input() isRequired = false;
	@Input() format: InputFormatType;
	@Input() selectData: ISelectData[];
	@Input() placeholder: string = '';
	@Input() canTogglePwd: boolean = false;
	@Input() maxDate: Date;
	@Input() minDate: Date;
	@Output() onSelect = new EventEmitter<boolean>();
	disabled = false;
	selectedData: string;
	selectedMultiselectData: string[] = [];
	selectedMultiselectDataReadable: string[] = [];
	filteredSelectData: string[] = [];
	showErrors = false;
	showPassword = false;
	controllerErrors: IFormInputValidator[] = [];
	private changeValueOnDebounceSubject = new Subject<string>();
	private formatOnDebounceSubscription: Subscription;

	constructor(private formatPipe: TextFormatPipe, private strUtilsService: StringUtilsService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.formController) return;
		if (changes.selectData) {
			this.addSelectDataModifications();
			this.updateInputValuesIfNeeded();
		}
		this.disabled = this.formController.disabled;
	}

	ngOnInit(): void {
		try {
			if (!this.formController) return;
			this.formController.valueChanges.subscribe((value: string) => {
				this.updateInputValuesIfNeeded();
				if (this.format) {
					this.manageFormatting(value);
				}
			});
			this.formController.statusChanges.subscribe(() => {
				this.controllerErrors = [];
				this.manageFormControllerDisableStatus();
				if (this.formController.invalid) {
					this.updateControllerErrors();
				}
			});
			this.addLabelModifications();
			this.startSubscriptions();
		} catch (error) {
			console.error(error);
		}
	}

	private startSubscriptions(): void {
		if (this.format === InputFormatEnum.coordinates || this.format === InputFormatEnum.iban) {
			this.formatOnDebounceSubscription = this.changeValueOnDebounceSubject
				.pipe(debounceTime(1000), distinctUntilChanged())
				.subscribe(value => {
					this.formatValueIntoFormController(value, true);
				});
		}
	}

	ngOnDestroy(): void {
		if (this.formatOnDebounceSubscription) {
			this.formatOnDebounceSubscription.unsubscribe();
		}
	}

	private addSelectDataModifications(): void {
		// To enable selection search
		if (this.inputType === InputTypeEnum.multiselect) {
			this.selectData = this.selectData.map(data => ({
				value: data.value,
				displayValue: data.displayValue ?? data.value,
			}));
		}
	}

	ngAfterViewInit(): void {
		// Solves an issue with autocomplete clearing data when "ENTER KEY" is pressed on option selection
		if (this.autocomplete) {
			this.autocomplete.onOverlayAnimationDone = (): void => {};
		}
	}

	onSelectComplete(event: { query: string }): void {
		const inputNormalized = this.strUtilsService.normalize(event.query).toLowerCase();
		this.filteredSelectData = [];
		this.filteredSelectData = this.selectData.reduce((total, current) => {
			const value = current.displayValue ?? current.value;
			const dataNormalized = this.strUtilsService.normalize(value).toLowerCase();
			if (dataNormalized.includes(inputNormalized)) {
				total.push(value);
			}
			return total;
		}, [] as string[]);
	}

	onChangeSelection(selection: string | null): void {
		if (!selection) return this.setFormToNoSelection();

		const optionSelected = this.getOptionSelected(selection);

		const controlValueUpdateIsNeeded = this.formController.value !== optionSelected.value;
		if (controlValueUpdateIsNeeded) {
			this.setFormControllerValue(optionSelected.value);
			this.onSelect.emit(true);
		}
	}

	onChangeMultiSelection(selections: string[] | null): void {
		if (!selections.length) return this.setFormToNoSelection();
		this.selectedMultiselectDataReadable = this.selectData.reduce((totalArray, currentOption) => {
			if (selections.find(selection => selection === currentOption.value)) {
				totalArray.push(currentOption.displayValue ?? currentOption.value);
			}
			return totalArray;
		}, [] as string[]);

		const multiSelectionString = selections.join(CUSTOM_MULTISELECT_ARRAY_SEPARATOR);
		const controlValueUpdateIsNeeded = this.formController.value !== multiSelectionString;

		if (controlValueUpdateIsNeeded) {
			this.setFormControllerValue(multiSelectionString);
			this.onSelect.emit(true);
		}
	}

	onDateSelected(_event: any): void {
		this.onSelect.emit(true);
	}

	onShowErrors(state: boolean): void {
		this.showErrors = state;
	}

	onTogglePwdVisibility(): void {
		this.showPassword = !this.showPassword;
	}

	getPwdIcon(): string {
		return `assets/images/icons/${this.showPassword ? 'eye-slash-solid' : 'eye-solid'}.svg`;
	}

	private setFormToNoSelection(): void {
		this.selectedMultiselectDataReadable = [];
		this.setFormControllerValue('');
		this.onSelect.emit(false);
	}

	private getOptionSelected(selection: string): ISelectData {
		if (!this.selectData) return undefined;
		return this.selectData.find(
			element => element.value === selection || element?.displayValue === selection
		);
	}

	private addLabelModifications(): void {
		if (!this.inputLabel) return;
		if (this.isRequired) {
			this.inputLabel += REQUIRED_SYMBOL;
		}
	}

	private updateControllerErrors(): void {
		Object.entries(this.formController.errors).forEach(([validatorName, details]) => {
			if (details) {
				this.controllerErrors.push({ validatorName, details });
			}
		});
	}

	private setFormControllerValue(value: string): void {
		if (!this.formController.dirty) {
			this.formController.markAsDirty();
		}
		if (this.inputType === InputTypeEnum.select) {
			this.formController.setValue('');
		}
		this.formController.setValue(value);
	}

	private manageFormControllerDisableStatus(): void {
		this.disabled = this.formController.disabled;
		if (!this.disabled) return;
		if (this.selectedData) this.selectedData = null;
		if (this.selectedMultiselectDataReadable.length) {
			this.selectedMultiselectDataReadable = [];
			this.selectedMultiselectData = [];
		}
	}

	private updateInputValuesIfNeeded(): void {
		if (this.inputType === InputTypeEnum.select) {
			this.updateSelectOnFormControllerChange(this.formController.value);
		}
		if (this.inputType === InputTypeEnum.multiselect) {
			this.updateMultiselectOnFormControllerChange(this.formController.value);
		}
	}

	private updateSelectOnFormControllerChange(value: string): void {
		const optionSelected = this.getOptionSelected(value);
		const sameOptionAsCurrent =
			optionSelected &&
			this.selectedData !== undefined &&
			(this.selectedData === optionSelected.value || this.selectedData === optionSelected?.displayValue);
		if (sameOptionAsCurrent) return;
		if (optionSelected) {
			this.selectedData = optionSelected?.displayValue ?? optionSelected.value;
		} else {
			this.selectedData = '';
		}
	}

	private updateMultiselectOnFormControllerChange(value: string): void {
		const selections = value.split(CUSTOM_MULTISELECT_ARRAY_SEPARATOR);
		const optionsSelected = selections.map(selection => this.getOptionSelected(selection));
		const sameOptionsAsCurrent = Boolean(
			optionsSelected.every(option => option !== undefined) &&
				this.selectedMultiselectData.length &&
				this.selectedMultiselectData.every(selection =>
					optionsSelected.find(option => option.value === selection)
				)
		);

		if (sameOptionsAsCurrent) return;
		this.selectedMultiselectData = optionsSelected.reduce((total, currentOption) => {
			if (currentOption === undefined) return total;
			total.push(currentOption.value);
			return total;
		}, [] as string[]);
		this.selectedMultiselectDataReadable = optionsSelected.reduce((total, currentOption) => {
			if (currentOption === undefined) return total;
			total.push(` ${currentOption?.displayValue ?? currentOption.value}`);
			return total;
		}, [] as string[]);
	}

	private manageFormatting(value: string): void {
		if (this.format === InputFormatEnum.coordinates || this.format === InputFormatEnum.iban) {
			this.changeValueOnDebounceSubject.next(value);
		} else {
			this.formatValueIntoFormController(value);
		}
	}

	private formatValueIntoFormController(value: string, emitEvent = false): void {
		this.formController.setValue(this.formatPipe.transform(value, this.format), {
			emitEvent,
		});
	}
}
