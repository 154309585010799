import { Pipe, PipeTransform } from '@angular/core';
import headerTranslateJson from '../../../assets/translate/headerTranslate.json';

@Pipe({
	name: 'headerTranslate',
})
export class HeaderTranslatePipe implements PipeTransform {
	transform(value: string): string {
		const transformedValue = headerTranslateJson[value];
		return transformedValue || value;
	}
}
