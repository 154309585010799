import { Injectable } from '@angular/core';
import { FarmProvider } from '../providers/farm.provider';
import { FarmModel } from '../schemes/models/farm.model';

@Injectable({
	providedIn: 'root',
})
export class FarmService {
	private currentFarm: FarmModel;

	constructor(private farmProvider: FarmProvider) {}

	public async setCurrentFarm(farmId: string = null): Promise<void> {
		if (farmId) {
			const farm = (await this.farmProvider.getById(farmId)).data;
			this.currentFarm = farm;
			if (farmId !== localStorage.getItem('selectedfarmId')) {
				localStorage.setItem('selectedFarmId', farm._id);
			}
		} else {
			localStorage.removeItem('selectedFarmId');
			this.currentFarm = null;
		}
	}

	public async setCurrentFarmFromArray(farms: string[] = []): Promise<void> {
		if (farms && farms[0]) {
			const farm = (await this.farmProvider.getById(farms[0])).data;
			this.currentFarm = farm;
			if (farms[0] !== localStorage.getItem('selectedfarmId')) {
				localStorage.setItem('selectedFarmId', farm._id);
			}
		} else {
			localStorage.removeItem('selectedFarmId');
			this.currentFarm = null;
		}
	}

	public async getCurrentFarm(): Promise<FarmModel> {
		const localFarmId = localStorage.getItem('selectedFarmId');
		if (this.currentFarm) {
			return this.currentFarm;
		}
		if (localFarmId) {
			await this.setCurrentFarm(localFarmId);
			return this.currentFarm;
		}
		return null;
	}
}
