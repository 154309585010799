<h2>Filtrar Animales</h2>
<div class="redil-info" *ngIf="ranches">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="">Solo animales activos</label>
					<div class="checkbox-cnt">
						<input type="checkbox" [(ngModel)]="active" />
					</div>
				</div>
				<div class="info-row">
					<label for="input-id">Identificación</label>
					<input id="input-id" class="ui-inputtext" type="text" [(ngModel)]="filters.officialId" pInputText />
				</div>
				<div class="info-row">
					<label for="input-id2">Crotal</label>
					<input id="input-id2" class="ui-inputtext" type="text" [(ngModel)]="filters.crotal" pInputText />
				</div>

				<div class="info-row">
					<label for="input-ownerFarm">Categoría LG</label>
					<p-multiSelect
						defaultLabel="Seleccione una categoría"
						[options]="filteredGenBooks"
						selectedItemsLabel="{0} Seleccionados"
						[(ngModel)]="filters.genBook"
						optionLabel="name"
						onselect="serchGenBooks($event)"
					>
					</p-multiSelect>
				</div>

				<div class="info-row">
					<label for="input-name">Nombre</label>
					<input id="input-name" class="ui-inputtext" type="text" [(ngModel)]="filters.name" pInputText />
				</div>

				<div class="info-row">
					<label for="input-gender">Sexo</label>
					<p-dropdown
						[options]="genders"
						[(ngModel)]="filters.gender"
						placeholder="Selecciona una opción"
						optionLabel="name"
						[style]="{ width: '100%' }"
					></p-dropdown>
				</div>

				<div class="info-row">
					<label for="input-officialId">ID de la Madre</label>
					<input
						id="input-officialId"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="filters.mother.officialId"
						pInputText
					/>
				</div>
				<div class="info-row">
					<label for="input-fatherOfficialId">ID del Padre</label>
					<input
						id="input-fatherOfficialId"
						class="ui-inputtext"
						type="text"
						[(ngModel)]="filters.father.officialId"
						pInputText
					/>
				</div>

				<div class="info-row">
					<label for="input-ownerFarm">Ganadería</label>
					<p-autoComplete
						[(ngModel)]="filters.ownerFarmAcronym"
						[suggestions]="filteredFarms"
						[dropdown]="true"
						[forceSelection]="true"
						[appendTo]="'body'"
						field="name"
						(completeMethod)="searchFarm($event)"
					>
					</p-autoComplete>
				</div>

				<div class="info-row">
					<label for="input-ownerFarmRanch">Rega</label>
					<p-autoComplete
						[(ngModel)]="filters.ownerRega"
						[suggestions]="filteredRanches"
						[dropdown]="true"
						[forceSelection]="true"
						[appendTo]="'body'"
						field="name"
						(completeMethod)="searchRanches($event)"
					>
					</p-autoComplete>
				</div>

				<div class="info-row">
					<label for="">Solo animales bloqueados</label>
					<div class="checkbox-cnt">
						<input type="checkbox" [(ngModel)]="filters.blocked" />
					</div>
				</div>

				<div class="info-row">
					<label for="birth-date">Fecha de nacimiento</label>
					<p>Desde:</p>
					<p-calendar
						id="birth-date"
						appUseUtc
						[(ngModel)]="filters.birthDate.start"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					></p-calendar>
					<p>Hasta:</p>
					<p-calendar
						id="birth-date"
						appUseUtc
						[(ngModel)]="filters.birthDate.end"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					>
					</p-calendar>
				</div>

				<div class="info-row" *ngIf="!active">
					<label for="drop-date">Fecha de baja</label>
					<p>Desde:</p>
					<p-calendar
						id="birth-date"
						appUseUtc
						[(ngModel)]="filters.dropDate.start"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					>
					</p-calendar>
					<p>Hasta:</p>
					<p-calendar
						id="birth-date"
						appUseUtc
						[(ngModel)]="filters.dropDate.end"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'24/06/1998'"
					>
					</p-calendar>
				</div>
			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>
