import { Injectable } from '@angular/core';
import { ResponseModel } from '../schemes/models/response.model';
import { SampleModel } from '../schemes/models/sample.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class SampleProvider extends BaseProvider<SampleModel> {
	constructor(http: HttpService) {
		super('samples', http);
	}

	generateId(): Promise<ResponseModel<{ id: string }>> {
		return this.http.get<ResponseModel<{ id: string }>>(`${this.collection}/generate-id`, {
			database: this.database || HttpService.currentDb,
		});
	}
}
