<main>
	<div class="info centered">
		<div class="header">
			<div class="bg logo" (click)="reloadWindowWithoutCache()"></div>
			<div class="bg title"></div>
		</div>
		<p class="claim">Gestión Integral de todas las Especies y Razas Autóctonas.</p>
		<p class="sub-claim"></p>
	</div>

	<div class="ui-g ui-fluid login-container centered">
		<form class="ui-g-12 ui-md-4" [formGroup]="loginForm" (ngSubmit)="getAccess()">

			<div class="input-grp">
				<div class="ui-inputgroup">
					<label for="email">Email</label>
					<input pInputText class="ui-fluid ui-inputtext" name="email" formControlName="email" type="email"
						placeholder="Email" autocomplete="off" required />
				</div>

				<div class="ui-inputgroup">
					<label for="password">Contraseña</label>
					<input type="password" name="password" pPassword [feedback]="false" formControlName="password"
						placeholder="Contraseña" required />
				</div>

				<span *ngIf="showAccessError"> No existe ninguna cuenta con este correo y contraseña</span>
			</div>

			<button class="cta-btn centered orange" type="submit" [disabled]="!loginForm.valid">Entrar</button>
		</form>
		<div class="imgs-grp">
			<img src="../../../assets/images/icons/canarian-government-logo.svg" alt="" />
			<img src="../../../assets/images/icons/canarias_avanza_con_europa.svg" alt="" />
			<img src="../../../assets/images/icons/union_europea.svg" alt="" />
		</div>
	</div>
</main>