import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';

@Component({
	selector: 'app-resurrect-form',
	templateUrl: './resurrect-form.component.html',
	styleUrls: ['./resurrect-form.component.scss'],
})
export class ResurrectFormComponent implements OnInit {
	resurrectionDate: Date;
	dropDate: Date;
	es = ngPrimeLocale;
	showErrors = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { dropDate: Date },
		public dialogRef: MatDialogRef<ResurrectFormComponent>,
		private dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.dropDate = this.data.dropDate;
	}

	onDialogClose(res: unknown): void {
		if (res) {
			this.checkErrors();
			if (!this.showErrors) {
				this.dialogRef.close({ resurrectionDate: this.resurrectionDate });
			}
		} else {
			this.dialogRef.close(false);
		}
	}

	checkErrors() {
		this.showErrors = !(this.resurrectionDate && this.resurrectionDate >= this.dropDate);
	}
}
