<div class="confirm">
	<h2>{{ data.title }}</h2>
	<p>{{ data.msg }}</p>
	<div class="btn-grp centered">
		<button class="app-btn primary flat" (click)="goToMotherBirths()">Ver los partos de la madre</button>
	</div>
	<div class="btn-grp centered">
		<button (click)="onCloseDialog(true)" class="app-btn primary">{{ data.acceptButton || 'Aceptar' }}</button>
		<button (click)="onCloseDialog(false)" class="app-btn optional">{{ data.declineButton || 'Cancelar' }}</button>
	</div>
</div>