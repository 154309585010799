import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageExpandDisplayComponent } from '../components/image-expand-display/image-expand-display.component';

@Injectable({ providedIn: 'root' })
export class ImageExpandService {
	constructor(public dialog: MatDialog) {}

	openDialog(imageUrl: string) {
		this.dialog.open(ImageExpandDisplayComponent, {
			data: { imageUrl },
			height: '600px',
			width: '600px',
			backdropClass: 'bdrop',
		});
	}
}
