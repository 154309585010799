import { Pipe, PipeTransform } from '@angular/core';
import { ModuleEnum } from 'src/app/schemes/enums/modules.enum';

@Pipe({
	name: 'module',
})
export class ModulePipe implements PipeTransform {
	transform(mod: ModuleEnum): string {
		switch (mod) {
			case 'book':
				return 'libro genealógico';
			case 'dna':
				return 'adn';
			case 'genetic':
				return 'genética';
			case 'admin':
				return 'gestión interna';
			case 'reproduction':
				return 'control reproductivo';
			case 'morfologic':
				return 'calificación morfológica';
			case 'performance-control':
				return 'control de rendimiento';
			default:
				return mod;
		}
	}
}
