import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-birth-code-modification',
	templateUrl: './birth-code-modification.component.html',
	styleUrls: ['./birth-code-modification.component.scss'],
})
export class BirthCodeModificationComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			msg: string;
			motherOfficialId: string;
			declineButton?: string;
			acceptButton?: string;
		},
		public dialogRef: MatDialogRef<BirthCodeModificationComponent>,
		private router: Router,
	) { }

	ngOnInit(): void { }

	onCloseDialog(res: boolean) {
		this.dialogRef.close(res);
	}

	goToMotherBirths(): void {
		void this.router.navigate(['/book/births'], {
			queryParams: { mother_officialId_like: this.data.motherOfficialId, _sort: 'date', _order: 1 },
		});
		this.dialogRef.close();
	}
}
