import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FarmProvider } from '../../../../providers/farm.provider';
import { FarmModel } from '../../../../schemes/models/farm.model';

@Component({
	selector: 'app-filter-proposals-modal',
	templateUrl: './filter-proposals-modal.component.html',
	styleUrls: ['./filter-proposals-modal.component.scss'],
})
export class FilterProposalsModalComponent implements OnInit {
	farms: FarmModel[];
	proposalTypes = [
		{ name: 'Actualizar', value: 'update' },
		{ name: 'Borrar', value: 'drop' },
		{ name: 'Nuevo', value: 'new' },
	];

	proposalEntities = [
		{ name: 'Ganadería', value: 'farms' },
		{ name: 'Finca', value: 'ranchs' },
		{ name: 'Cubrición', value: 'breeds' },
		{ name: 'Raza', value: 'races' },
		{ name: 'Regla de libro', value: 'genbookrules' },
		{ name: 'Categoría LG', value: 'genbooks' },
		{ name: 'Animal', value: 'animals' },
		{ name: 'Parto', value: 'births' },
		{ name: 'Análisis', value: 'analyses' },
		{ name: 'Transferencia', value: 'transferences' },
		{ name: 'Calificación', value: 'qualifications' },
	];

	filters: { farm?: FarmModel; type?: { [key: string]: string }; entityName?: { [key: string]: string } };

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<FilterProposalsModalComponent>,
		private router: Router,
		private farmProvider: FarmProvider,
	) { }

	async ngOnInit() {
		this.farms = ((await this.farmProvider.getAll())?.data || ([] as FarmModel[])).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
		this.filters = this.setFiltersData(this.data);
	}

	onSaveFilters() {
		const parsedFilters = this.parseFilterData();
		this.dialogRef.close();
		this.router.navigate(['/proposals'], { queryParams: parsedFilters });
	}

	onClearFilters() {
		this.filters = {
			farm: null,
			type: null,
			entityName: null,
		};
	}

	parseFilterData() {
		const parsedFilters: { entityName?: string; type?: string; farm?: string } = {};
		if (this.filters.entityName) parsedFilters.entityName = this.filters.entityName.value;
		if (this.filters.type) parsedFilters.type = this.filters.type.value;
		if (this.filters.farm) parsedFilters.farm = this.filters.farm._id;
		return parsedFilters;
	}

	setFiltersData(data) {
		const filterResult: { farm?: FarmModel; type?: { [key: string]: string }; entityName?: { [key: string]: string } } = {};
		if (data) {
			Object.entries(data.filters).forEach(([key, filterValue]) => {
				let foundData;
				if (key === 'entityName') {
					foundData = this.proposalEntities.find(({ value }) => value === filterValue) || null;
				} else if (key === 'type') {
					foundData = this.proposalTypes.find(({ value }) => value === filterValue) || null;
				} else if (key === 'farm') {
					foundData = this.farms.find(({ _id }) => filterValue === _id) || null;
				}
				if (foundData !== undefined) filterResult[key] = foundData;
			}, {});
		}
		return filterResult;
	}
}
