import { Pipe, PipeTransform } from '@angular/core';

const scrapiesValuesIndexedByGroupRisk = {
	R1: ['ARR/ARR'],
	R2: ['ARR/AHQ', 'AHQ/AHQ'],
	R3: ['ARR/ARQ', 'ARR/ARH', 'ARQ/AHQ', 'AHQ/ARH'],
	R4: ['ARH/ARH', 'ARH/ARQ', 'ARQ/ARQ', 'ARR/VRQ', 'AHQ/VRQ'],
	R5: ['ARQ/VRQ', 'ARH/VRQ', 'VRQ/VRQ'],
};

@Pipe({
	name: 'molecularMarkerPipe',
})
export class MolecularMarkerPipe implements PipeTransform {
	transform(value: string): string {
		const result = Object.entries(scrapiesValuesIndexedByGroupRisk).find(([key, val]) => val.includes(value));
		return !result ? '' : result[0];
	}
}
