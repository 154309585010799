<div class="sub-nav">
	<ng-container *ngFor="let sublink of sublinks; let i = index">
		<a
			class="link"
			[ngClass]="{ main: i === 0 }"
			*ngIf="'view' | able: sublink.auth"
			[routerLink]="[sublink.link]"
			[routerLinkActive]="['active']"
			[queryParams]="sublink.queryParams || {}"
			(click)="removeProcessLinker()"
		>
			{{ sublink.label | titlecase }}
		</a>
	</ng-container>
</div>
