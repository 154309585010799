import { Injectable } from '@angular/core';
import communitiesJson from 'src/assets/geographic/communities.json';
import islandsJson from 'src/assets/geographic/islands.json';
import municipalitiesJson from 'src/assets/geographic/municipalities.json';
import provincesJson from 'src/assets/geographic/provinces.json';
import { CommunityModel, IslandModel, MunicipalityModel, ProvinceModel } from '../schemes/models/geographic';
import { StringUtilsService } from './string-utils.service';

@Injectable({
	providedIn: 'root',
})
export class PlacesService {
	private communities: CommunityModel[] = [];
	private provinces: ProvinceModel[] = [];
	private municipalities: MunicipalityModel[] = [];
	private islands: IslandModel[] = [];

	constructor(private utilsService: StringUtilsService) {
		this.communities = JSON.parse(JSON.stringify(communitiesJson));
		this.provinces = JSON.parse(JSON.stringify(provincesJson));
		this.municipalities = JSON.parse(JSON.stringify(municipalitiesJson));
		this.islands = JSON.parse(JSON.stringify(islandsJson));
	}

	get allCommunities(): CommunityModel[] {
		return this.communities;
	}

	get allProvinces(): ProvinceModel[] {
		return this.provinces;
	}

	get allMunicipalities(): MunicipalityModel[] {
		return this.municipalities;
	}

	get allIslands(): IslandModel[] {
		return this.islands;
	}

	getProvinceCodeByProvinceName(provinceName: string): string | null {
		return this.provinces.find(province => province.name === provinceName)?.code || null;
	}

	getProvincesByCommunityName(communityName: string): string[] {
		const communityCode = this.communities.find(community => community.name === communityName)?.code;
		if (!communityCode) return [];
		return this.provinces.reduce((total, currentProvince) => {
			const provinceFound =
				this.utilsService.normalizeAndToLowerCase(currentProvince.communityCode) ===
				this.utilsService.normalizeAndToLowerCase(communityCode);
			if (provinceFound) total.push(currentProvince.name);
			return total;
		}, [] as string[]);
	}

	getMunicipalitiesByProvinceName(provinceName: string): string[] {
		const provinceCode = this.provinces.find(province => province.name === provinceName)?.code;
		if (!provinceCode) return [];
		return this.municipalities.reduce((total, currentMunicipality) => {
			const municipalityFound =
				this.utilsService.normalizeAndToLowerCase(currentMunicipality.provinceCode) ===
				this.utilsService.normalizeAndToLowerCase(provinceCode);
			if (municipalityFound) total.push(currentMunicipality.name);
			return total;
		}, [] as string[]);
	}

	getMunicipalitiesByIslandNameAndProvinceName(islandName: string, provinceName: string): string[] {
		const provinceCode = this.provinces.find(province => province.name === provinceName)?.code;
		if (!provinceCode) return [];
		const municipalityCode = this.islands
			.filter(island => island.name === islandName)
			.map(island => island.municipalityCode);

		return this.municipalities.reduce((total, currentMunicipality) => {
			const municipalityFound =
				municipalityCode.includes(currentMunicipality.code) &&
				this.utilsService.normalizeAndToLowerCase(currentMunicipality.provinceCode) ===
					this.utilsService.normalizeAndToLowerCase(provinceCode);
			if (municipalityFound) total.push(currentMunicipality.name);
			return total;
		}, [] as string[]);
	}

	getIslandsByProvinceNameOrMunicipalityName(
		provinceName: string,
		municipalityName: string | null = null
	): string[] {
		const provinceCode = this.provinces.find(province => province.name === provinceName)?.code;
		if (!provinceCode) return [];

		return this.getIslandsByMunicipalityName(municipalityName).reduce((total, currentIsland) => {
			const islandFound =
				this.utilsService.normalizeAndToLowerCase(currentIsland.provinceCode) ===
				this.utilsService.normalizeAndToLowerCase(provinceCode);
			if (!total.includes(currentIsland.name) && islandFound) total.push(currentIsland.name);
			return total;
		}, [] as string[]);
	}

	private getIslandsByMunicipalityName(municipalityName: string | null = null): IslandModel[] {
		if (!municipalityName) return this.islands;

		const municipalityCode = this.municipalities.find(
			municipality => municipality.name === municipalityName
		)?.code;
		if (!municipalityCode) return [];
		return this.islands.filter(
			island =>
				this.utilsService.normalizeAndToLowerCase(island.municipalityCode) ===
				this.utilsService.normalizeAndToLowerCase(municipalityCode)
		);
	}
}
