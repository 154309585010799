import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'transferencesPipe',
})
export class TransferencesPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) { }

	transform(value: any, ...args: unknown[]): unknown {
		if (typeof value !== 'number' && Date.parse(value)) {
			return this.datePipe.transform(value, 'dd/MM/yyyy');
		}
		// return transferencesTranslate[(value as string)]
		return value;
	}
}
