import { Injectable } from '@angular/core';
import { AnimalModel } from '../schemes/models/animal.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class AnimalProvider extends BaseProvider<AnimalModel> {
	constructor(http: HttpService) {
		super('animals', http);
	}

	getAliveAnimals(params?): Promise<ResponseModel<AnimalModel[]>> {
		return this.getAll({ ...params, dropDate: null });
	}

	getAliveMothers(params?) {
		return this.getAliveAnimals({ ...params, gender: 'H' });
	}

	getAliveFathers(params?) {
		return this.getAliveAnimals({ ...params, gender: 'M' });
	}

	getMothers(params?) {
		return this.getAll({ ...params, gender: 'H' });
	}

	getFathers(params?) {
		return this.getAll({ ...params, gender: 'M' });
	}

	removeAnimalBirth(animalId: string) {
		return this.http.patch<ResponseModel<AnimalModel>>(
			`${this.collection}/remove-birth/${animalId}`,
			{},
			{ database: this.database || HttpService.currentDb }
		);
	}

	exportAnimalDna(filters) {
		return this.http.get<ResponseModel<AnimalModel[]>>(
			`${this.collection}/export-adn`,
			{ database: this.database || HttpService.currentDb },
			filters
		);
	}

	// getAnimalsToExport(filters: any): Promise<ResponseModel<Object[]>> {
	//     return;
}
