<div class="bg dropzone" [ngClass]="{ active: hovered }" dropZone (hovered)="onFileHovered($event)"
	(dropped)="onFileSelected($event, 'drop')" (click)="openFileSelector()">
	<p>{{ message }}</p>
</div>

<button *ngIf="showButton" class="app-btn primary" (click)="openFileSelector()">Subir desde el ordenador</button>
<!-- <ngx-ui-loader [loaderId]="(id === undefined) ? 'image-input-loader' : 'image-input-loader-' + id" blur=5 delay=0
    fgsColor="#ec1e79" fgsPosition="center-center" fgsSize=60 fgsType="fading-circle" gap=24
    overlayBorderRadius="10px" overlayColor="rgba(40, 40, 40, 0.8)" [hasProgressBar]="false" maxTime=-1 minTime=500>
</ngx-ui-loader> -->

<!-- Al ser una componente, problablemente el accept habrá que modificarlo para setearlo desde otras paginas, eligiendo así el formato de los datos externamente. -->
<input type="file" [id]="'file-selector-' + id" (change)="onFileSelected($event, 'select')" />