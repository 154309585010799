import { Injectable } from '@angular/core';
import { AnimalViewModel } from '../schemes/models/animal-view.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class AnimalViewProvider extends BaseProvider<AnimalViewModel> {
	constructor(http: HttpService) {
		super('animal-views', http);
	}

	updateViews(): Promise<ResponseModel<AnimalViewModel>> {
		return this.http.post<ResponseModel<AnimalViewModel>>(
			'animal-views/update-views',
			{ data: {} },
			{ database: this.database || HttpService.currentDb }
		);
	}
}
