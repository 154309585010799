import { Injectable } from '@angular/core';
import { ResponseModel } from '../schemes/models/response.model';
import { NewUserModel, UserModel } from '../schemes/models/user.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class UserProvider extends BaseProvider<UserModel> {
	private headers = {
		database: 'super',
	};

	constructor(http: HttpService) {
		super('users', http, 'super');
	}

	public getUserByUID(uid: string, params?): Promise<UserModel> {
		return this.http.get<UserModel>(`users/${uid}`, this.headers, params);
	}

	public getAllUsers(): Promise<UserModel[]> {
		return this.http.get<UserModel[]>('users', this.headers);
	}

	public createUser(user: NewUserModel): Promise<UserModel> {
		return this.http.post<UserModel>('auth/signup', user, this.headers);
	}

	public deleteUser(id: string): Promise<UserModel> {
		return this.http.delete<UserModel>(`users/${id}`, this.headers);
	}

	public save(entity: UserModel): Promise<ResponseModel<UserModel>> {
		return this.http.post<ResponseModel<UserModel>>(
			'auth/signup',
			{ data: entity },
			{ database: this.database || HttpService.currentDb }
		);
	}

	public editUser(user: UserModel) {
		return this.http.patch<UserModel>(`users/${user._id}`, { data: user }, this.headers);
	}
}
