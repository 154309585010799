import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
	CUSTOM_FORM_ERRORS_ID,
	IFormFieldDependencyValidator as IFormFieldDependency,
} from '../../schemes/types/form-validation';

export interface IStatusError {
	disableField: boolean | 'preventDefault';
}
@Injectable({
	providedIn: 'root',
})
export class CustomFormStatusValidator {
	constructor() {}

	disableFieldsOnDependencies(fieldRelationships: IFormFieldDependency[]): ValidatorFn {
		return (formGroup: FormGroup): ValidationErrors => {
			fieldRelationships.forEach(({ field, dependsOn }) => {
				const targetFieldController = formGroup.get(field) as FormControl;
				const dependencyFieldController = formGroup.get(dependsOn) as FormControl;

				if (!dependencyFieldController.value) {
					const error: IStatusError = {
						disableField: true,
					};
					targetFieldController.setValue('', { onlySelf: true, emitEvent: false });
					targetFieldController.disable({ onlySelf: true, emitEvent: false });
					targetFieldController.setErrors(error);
					return;
				}

				if (targetFieldController.hasError(CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_DISABLE_FIELD_ID)) {
					this.deleteDisableFieldError(targetFieldController);
				}
			});
			return null;
		};
	}

	/**
	 * Given a Form group, method removes every active instance of "disableField" inside the controllers
	 * and set the controllers status to 'enable' again.
	 * @param formGroup
	 */
	overrideFieldDependencyEffect(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.get(key) as FormControl;
			if (control.hasError(CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_DISABLE_FIELD_ID)) {
				this.deleteDisableFieldError(control);
			}
		});
	}

	private deleteDisableFieldError(controller: FormControl): void {
		delete controller.errors[CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_DISABLE_FIELD_ID];
		controller.enable();
		controller.updateValueAndValidity({ onlySelf: true });
	}
}
