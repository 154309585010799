import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';

@Component({
	selector: 'app-drop-farm',
	templateUrl: './drop-farm.component.html',
	styleUrls: ['./drop-farm.component.scss'],
})
export class DropFarmComponent implements OnInit {
	es = ngPrimeLocale;
	dropDate: any;
	maxDate: Date;
	minDate: Date;

	constructor(
		public dialogRef: MatDialogRef<DropFarmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { minDate: Date; maxDate: Date } | null
	) {}

	ngOnInit(): void {
		if (this.data) {
			this.maxDate = this.data.maxDate;
			this.minDate = this.data.minDate;
		}
	}

	onDialogClose(res) {
		this.dialogRef.close(res);
	}
}
