export interface ILengthValidatorDetails {
	requiredLength: string;
	actualLength: string;
}
export interface IDateValidatorDetails extends ICustomValidatorBase {}
export interface IPlacesValidatorDetails extends ICustomValidatorBase {}

export interface ICustomValidatorBase {
	customMessage: string | null;
}

export interface IMaxValidatorDetails {
	max: number;
	actual: string;
}

export interface IMinValidatorDetails {
	min: number;
	actual: string;
}

export enum EDateValidationMessages {
	registerDateMustBeLaterThanBirthdate = 'La fecha de registro tiene que ser igual o posterior a la fecha de nacimiento',
}

export enum EPlacesValidationMessages {
	cpDoesNotBelongToProvince = 'El código postal no pertenece a la provincia seleccionada',
}
export enum EValueValidationMessages {
	latitudeInvalidValue = 'La latitud requiere de un valor numérico entre -90 y 90',
	longitudeInvalidValue = 'La longitud requiere de un valor numérico entre -180 y 180',
	nifInvalidFormat = 'El NIF no tiene un formato válido',
	nifInvalid = 'El NIF no es válido',
	ibanInvalidFormat = 'El IBAN no tiene un formato válido',
	phoneInvalid = 'El número de teléfono no es válido',
	mobilePhoneInvalid = 'El número de teléfono móvil no es válido',
}

/**
 * CUSTOM VALIDATORS INTERFACES, TYPES & ENUMS
 */
export interface IFormFieldDependencyValidator {
	field: string;
	dependsOn: string;
}

export enum CUSTOM_FORM_ERRORS_ID {
	CUSTOM_ERROR_DISABLE_FIELD_ID = 'disableField',
	CUSTOM_ERROR_INVALID_DATE_ID = 'invalidDate',
	CUSTOM_ERROR_NOT_UNIQUE_ID = 'notUnique',
	CUSTOM_ERROR_INVALID_FORMAT_ID = 'invalidFormat',
	CUSTOM_ERROR_INVALID_VALUE_ID = 'invalidValue',
	CUSTOM_ERROR_MESSSAGE_ID = 'customMessage',
}
