import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-file-dropzone',
	templateUrl: './file-dropzone.component.html',
	styleUrls: ['./file-dropzone.component.scss'],
})
export class FileDropzoneComponent implements OnInit {
	@Input() id: any;
	@Input() message: string;
	@Input() allowedFiles: string[];
	@Input() showButton: boolean;
	@Output() dropped = new EventEmitter<File>();
	hovered = false;

	constructor() {}

	ngOnInit() {
		// this.loaderID = (this.id !== undefined) ? `image-input-loader-${this.id}` : 'image-input-loader';
	}

	onFileSelected(event: FileList | Event, type: 'drop' | 'select') {
		// if (this.id !== undefined) { this.ngxLoader.startLoader(this.loaderID); }

		const file =
			type === 'drop'
				? (event as FileList).item(0)
				: ((event as Event).target as HTMLInputElement).files.item(0);
		if (!file) {
			return;
		}
		if (file && this.allowedFiles && !this.allowedFiles.some(fileType => file.name.includes(fileType))) {
			console.error('No es un archivo válido!');
			return;
		}
		this.dropped.emit(file);
		if (type === 'drop') {
			event = null;
		} else {
			((event as Event).target as HTMLInputElement).value = '';
		}
	}

	openFileSelector() {
		document.getElementById(`file-selector-${this.id as string}`).click();
	}

	onFileHovered(event: boolean) {
		if (this.hovered !== event) {
			this.hovered = event;
		}
	}
}
