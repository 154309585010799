import { Injectable } from '@angular/core';
import { GenBookModel } from '../schemes/models/gen-book.model';
import { ResponseModel } from '../schemes/models/response.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class GenBooksProvider extends BaseProvider<GenBookModel> {
	constructor(http: HttpService) {
		super('genbooks', http);
	}

	public calculateAnimalGenbook(animalId: string): Promise<ResponseModel<GenBookModel>> {
		return this.http.get<ResponseModel<GenBookModel>>(`genbooks/calculate/${animalId}`, {
			database: HttpService.currentDb,
		});
	}

	public async getValidAnimalGenbooksSortedByDate(animalId: string): Promise<GenBookModel[]> {
		return (
			await this.getAll({
				animal: animalId,
				_sort: 'date',
				_order: '1',
			})
		).data.reduce((total, current) => {
			const isInvalidFirstCategory = !total.length && current.value.toUpperCase() === 'FL';
			if (isInvalidFirstCategory) return total;

			total.push(current);
			return total;
		}, [] as GenBookModel[]);
	}
}
