<div class="redil-info dialog">
	<h2>Dar de baja</h2>
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="drop-date">Fecha de baja</label>
					<p-calendar
						id="drop-date"
						appUseUtc
						[(ngModel)]="death.dropDate"
						[locale]="es.calendar_es"
						dateFormat="dd/mm/yy"
						[maxDate]="limitDateToday"
						[showIcon]="true"
						[appendTo]="'body'"
						[placeholder]="'dd/mm/yyyy'"
					>
					</p-calendar>
				</div>

				<p-message *ngIf="showErrors && !death.dropDate" severity="error" text="Fecha de baja requerida">
				</p-message>

				<p-message
					*ngIf="
						this.death.dropDate &&
						this.qualificationDate &&
						this.death.dropDate.getTime() < this.qualificationDate.getTime()
					"
					severity="error"
					text="La fecha debe ser posterior o igual a la ultima fecha de calificación"
				>
				</p-message>

				<p-message
					*ngIf="
						this.death.dropDate &&
						this.transferenceDate &&
						this.death.dropDate.getTime() < this.transferenceDate.getTime()
					"
					severity="error"
					text="La fecha debe ser posterior o igual a la ultima fecha de transferencia"
				>
				</p-message>

				<p class="centered-text">Lista de motivos</p>

				<div class="info-row radio-list">
					<!-- <label for="input-drop-reason">Motivo de la baja</label> -->
					<div class="sub-info-row" *ngFor="let dropReason of dropReasons">
						<div
							class="radio-btn"
							(click)="changeSelectedDropReason(dropReason.value)"
							[ngClass]="{ 'selected-radio-btn': death.dropReason === dropReason.value }"
						></div>
						<span>{{ dropReason.name }}</span>
					</div>
					<!-- <p-autoComplete id="input-drop-reason" [(ngModel)]="death.dropReason"
                        [suggestions]="filteredReasons" (completeMethod)="searchReason($event)" [dropdown]="true"
                        field="name" [forceSelection]="true" [appendTo]="'body'">
                    </p-autoComplete> -->
				</div>
				<p-message
					*ngIf="showErrors && !death.dropReason"
					severity="error"
					text="Debe elegir un motivo válido"
				>
				</p-message>
			</div>

			<div class="buttons">
				<button (click)="onDialogClose(death)" class="app-btn orange">Aceptar</button>
				<!-- <button (click)="onDialogClose(false)" class="app-btn tertiary">Cancelar</button> -->
			</div>
		</section>
	</div>
</div>
