import { UpperCasePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleEnum } from 'src/app/schemes/enums/modules.enum';
import { BugService } from 'src/app/services/bug.service';
import { ModulePipe } from 'src/app/shared/pipes/module-enum.pipe';

@Component({
	selector: 'app-modules',
	templateUrl: './modules-selector.component.html',
	styleUrls: ['./modules-selector.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModulesSelectorComponent implements OnInit {
	modules: { id: ModuleEnum; name: string }[];

	constructor(private modulePipe: ModulePipe, private uppercasePipe: UpperCasePipe, private router: Router, public bug: BugService) { }

	ngOnInit(): void {
		this.modules = Object.values(ModuleEnum).map((mod: ModuleEnum) => ({
			id: mod,
			name: this.uppercasePipe.transform(this.modulePipe.transform(mod)),
		}));
	}

	async selectAssociation(mod: ModuleEnum): Promise<void> {
		await this.router.navigateByUrl(`/modules/${mod}`);
	}
}
