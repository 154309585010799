import { Pipe, PipeTransform } from '@angular/core';
import { InputFormatEnum, InputFormatType } from '../../schemes/types/form-inputs';
import { StringUtilsService } from '../../services/string-utils.service';

@Pipe({
	name: 'textFormat',
})
export class TextFormatPipe implements PipeTransform {
	constructor(private strUtilsService: StringUtilsService) {}

	transform(value: string, format: InputFormatType): string | number {
		if (!value) return value;
		if (format === InputFormatEnum.uppercase) {
			return value.toUpperCase();
		}
		if (format === InputFormatEnum.lowercase) {
			return value.toLowerCase();
		}
		if (format === InputFormatEnum.capitalize) {
			return this.strUtilsService.capitalize(value);
		}

		if (format === InputFormatEnum.spanishName) {
			return this.strUtilsService.capitalizeSpanishName(value);
		}

		if (format === InputFormatEnum.coordinates) {
			if (!value) return value;
			const numOfDecimals = 3;
			const valueTransformed = String(value).replace(',', '.');
			const coordinateNumber = parseFloat(valueTransformed);
			if (Number.isNaN(coordinateNumber)) {
				return Number(0).toFixed(numOfDecimals);
			}

			return coordinateNumber.toFixed(numOfDecimals);
		}

		/**
		 * IBAN format ES00 0000 0000 0000 0000 0000
		 */
		if (format === InputFormatEnum.iban) {
			if (!value || value.replace(/\s/g, '').length < 24) return value;
			const arrayOfChars = value.replace(/\s/g, '').toUpperCase().split('');
			for (let index = 0; index < arrayOfChars.length; index += 5) {
				arrayOfChars.splice(index, 0, ' ');
			}
			return arrayOfChars.join('').trim();
		}

		if (format === InputFormatEnum.phoneES) {
			if (!value) return value;
			const phoneValue = this.strUtilsService.normalizePhoneES(value);
			const arrayOfChars = phoneValue.replace(/\s/g, '').split('');
			if (arrayOfChars.length < 9) return phoneValue;
			for (let index = 3; index < arrayOfChars.length; index += 3) {
				arrayOfChars.splice(index, 0, ' ');
			}
			arrayOfChars.splice(0, 0, '+34 ');
			return arrayOfChars.join('').trim();
		}

		return value;
	}
}
