import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	static currentDb = '';

	constructor(private http: HttpClient) {}

	async getResponse<T>(url, headers?, params?): Promise<HttpResponse<T>> {
		return this.http
			.get<T>(environment.apiUrl + url, {
				headers,
				params,
				observe: 'response',
			})
			.pipe(take(1))
			.toPromise();
	}

	get<T>(url, headers?, params?): Promise<T> {
		return this.http
			.get<T>(environment.apiUrl + url, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	post<T>(url, body, headers?, params?): Promise<T> {
		return this.http
			.post<T>(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	put<T>(url, body, headers?, params?): Promise<T> {
		return this.http
			.put<T>(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	patch<T>(url, body, headers?, params?): Promise<T> {
		return this.http
			.patch<T>(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	delete<T>(url, headers?, params?) {
		return this.http
			.delete<T>(environment.apiUrl + url, { headers, params })
			.pipe(take(1))
			.toPromise();
	}
}
