import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RaceModel } from 'src/app/schemes/models/race.model';
import { StringUtilsService } from 'src/app/services/string-utils.service';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { FarmProvider } from '../../../../providers/farm.provider';
import { RaceProvider } from '../../../../providers/race.provider';
import { RanchProvider } from '../../../../providers/ranch.provider';
import { FarmModel } from '../../../../schemes/models/farm.model';
import { RanchModel } from '../../../../schemes/models/ranch.model';
import { FarmService } from '../../../../services/farm.service';
import { TimeService } from '../../../../services/time.service';

// TODO - Refactor de toda la clase, formularios y servicios
interface RanchOptions {
	label: string;
	value: string;
	rega: string;
	farm: string;
}
interface FieldsCensusReport {
	farms?: string[];
	ranches?: RanchOptions[];
	races?: string[];
	date?: Date;
}

@Component({
	selector: 'app-census-report',
	templateUrl: './census-report.component.html',
	styleUrls: ['./census-report.component.scss'],
})
export class CensusReportComponent implements OnInit {
	ranches: RanchModel[] = [];
	farms: FarmModel[];
	races: RaceModel[];
	farmsOptions: { value: string; label: string }[] = [];
	racesOptions: { value: string; label: string }[] = [];
	ranchOptions: RanchOptions[] = [];
	fields: FieldsCensusReport = {};

	es = ngPrimeLocale;
	errors: { [key: string]: boolean } = {};
	isValid: boolean = true;
	exportToday = true;

	constructor(
		private farmService: FarmService,
		private farmProvider: FarmProvider,
		private ranchProvider: RanchProvider,
		private raceProvider: RaceProvider,
		private utilsService: StringUtilsService,
		private dialogRef: MatDialogRef<CensusReportComponent>
	) {}

	async ngOnInit(): Promise<void> {
		const currentFarm = await this.getCurrentFarm();
		if (currentFarm) this.farms = [currentFarm];
		else this.farms = await this.getAllFarms();
		this.races = await this.getAllRaces();

		this.farmsOptions = this.farms
			.reduce((total, current) => {
				if (current.dropDate) {
					total.push({
						label: `${this.utilsService.capitalizeSpanishName(current.name)} (Baja a: ${new Date(
							current.dropDate
						).toLocaleDateString()})`,
						value: current._id,
					});
				} else {
					total.push({
						label: this.utilsService.capitalizeSpanishName(current.name),
						value: current._id,
					});
				}
				return total;
			}, [] as { label: string; value: string }[])
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		this.racesOptions = this.races
			.reduce((total, current) => {
				total.push({
					label: this.utilsService.capitalizeSpanishName(current.name),
					value: current._id,
				});
				return total;
			}, [] as { label: string; value: string }[])
			.sort((a, b) => (a.label > b.label ? 1 : -1));
	}

	async getCurrentFarm(): Promise<FarmModel> {
		const farm = this.farmService.getCurrentFarm();
		return farm;
	}

	async getAllFarms(): Promise<FarmModel[]> {
		const farms = (await this.farmProvider.getAll({ _sort: 'name' })).data;
		return farms;
	}

	async getAllRaces(): Promise<RaceModel[]> {
		const races = (await this.raceProvider.getAllRaces()).data;
		return races;
	}

	async onFarmChange(event) {
		const eventValue = event.value;
		if (eventValue.length !== 0) {
			this.ranches = (
				await this.ranchProvider.getAll({ farm_in: eventValue.join(','), _sort: 'rega', _embed: 'farm' })
			).data;
			this.ranchOptions = this.ranches
				.map(ranch => ({
					label: `${ranch.rega} (Ganadería: ${(ranch.farm as FarmModel)?.name})`,
					value: ranch._id,
					rega: ranch.rega,
					farm: ranch.farm as string,
					_id: ranch._id,
				}))
				.sort((a, b) => (a.rega > b.rega ? 1 : -1));

			this.fields.ranches = (this.fields.ranches || []).reduce((total, current) => {
				if (!this.fields.farms.includes(current.farm as string)) return total;
				total.push(current);
				return total;
			}, [] as RanchOptions[]);
		} else this.ranchOptions = [];
	}

	async onGenerateReport(): Promise<void> {
		if (this.validateObligatoryData(['farms', 'races'])) {
			if (!this.fields.ranches || this.fields.ranches.length === 0) {
				this.fields.ranches = this.ranches.map(ranch => ({
					label: ranch.rega,
					value: ranch._id,
					rega: ranch.rega,
					farm: ranch.farm as string,
					_id: ranch._id,
				}));
			}
		}
		const validDropDates = await this.checkDropDates(this.fields.farms);
		if (validDropDates) {
			if (this.exportToday) {
				delete this.fields.date;
			}

			this.onCloseDialog(this.fields);
		}
	}

	async checkDropDates(farms: string[]): Promise<Boolean> {
		this.errors.dropDate = false;

		const censusDate = this.exportToday ? TimeService.getActualDate() : this.fields.date;

		const promises = farms.map(farm => this.farmProvider.getById(farm));

		const populatedFarms = await Promise.all(promises);
		populatedFarms.forEach(populatedFarm => {
			if (populatedFarm.data.dropDate) {
				if (
					TimeService.parseDate(censusDate).getTime() >
					TimeService.parseDate(populatedFarm.data.dropDate).getTime()
				) {
					this.errors.dropDate = true;
					return false;
				}
			}
		});

		if (this.errors.dropDate) {
			return false;
		}
		return true;
	}

	validateObligatoryData(fieldsNames: string[]): boolean {
		fieldsNames.forEach(fieldName => {
			if (!this.fields[fieldName]) this.errors[fieldName] = true;
			else this.errors[fieldName] = false;
		});
		this.isValid = !Object.values(this.errors).some(error => error);
		return this.isValid;
	}

	onCloseDialog(fields: FieldsCensusReport = null) {
		this.dialogRef.close(fields);
	}

	searchFarm($event) {
		this.farms = this.farms.filter(
			race => race.name.toLowerCase().indexOf($event.query.toString().toLowerCase()) !== -1
		);
	}

	changeExportType() {
		this.fields.date = TimeService.getActualDate();
	}
}
