<div class="image-upload info-row">
	<p-fileUpload
		#fileUploader
		styleClass="upload-btn blue"
		mode="basic"
		[name]="name"
		accept="image/*"
		fileLimit="1"
		[maxFileSize]="fileSizeLimit"
		[chooseLabel]="placeholder"
		(onSelect)="onSelect($event)"
	></p-fileUpload>
	<p-button
		styleClass="ui-button-success"
		*ngIf="isUpload && fileUploader.hasFiles()"
		label="Actualizar imagen"
		iconPos="left"
		(click)="onSave()"
	></p-button>
	<p-button
		styleClass="ui-button-danger"
		*ngIf="fileUploader.hasFiles()"
		icon="pi pi-times"
		iconPos="left"
		(click)="onClearFile()"
	></p-button>
</div>
