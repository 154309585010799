import { Router } from '@angular/router';
import {
	HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { TimeService } from './time.service';

@Injectable({ providedIn: 'root' })
export class AuthTokenHttpInterceptor implements HttpInterceptor {
	constructor(private auth: AngularFireAuth) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isFirebaseRequest = !!req.headers.get('firebaseHttpInterceptor');

		return this.auth.idToken.pipe(
			take(1),
			switchMap(idToken => {
				let clone = req.clone();
				if (idToken) {
					const actualDate = TimeService.parseDate(new Date())
						.toString()
						.replace(/ \([^\)]+\)/, '');

					let headers = req.headers.set('Authorization', `Bearer ${idToken}`).set('requestDate', actualDate);

					if (isFirebaseRequest) headers = headers.delete('requestDate').delete('firebaseHttpInterceptor');

					clone = clone.clone({ headers });
				}
				return next.handle(clone);
			}),
		);
	}
}
export const AuthTokenHttpInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthTokenHttpInterceptor,
	multi: true,
};
@Injectable({ providedIn: 'root' })
export class ErrorHandlerHttpInterceptor implements HttpInterceptor {
	constructor(private router: Router) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const errorHandlerInterceptorIsEnabled = req.headers.get('errorHandlerHttpInterceptor') === 'true';
		if (!errorHandlerInterceptorIsEnabled) return next.handle(req);

		return next.handle(req)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					let errorMsg = '';
					if (error.error instanceof ErrorEvent) {
						// Client side error;
						errorMsg = `Error: ${error.error.message}`;
					} else {
						// Server side error;
						errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
					}
					this.router.navigate(['notfound'], { queryParams: { home: true } });
					return throwError(errorMsg);
				}),
			);
	}
}
