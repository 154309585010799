import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { FarmService } from 'src/app/services/farm.service';

@Component({
	selector: 'app-farm-selector',
	templateUrl: './farm-selector.component.html',
	styleUrls: ['./farm-selector.component.scss'],
})
export class FarmSelectorComponent implements OnInit {
	farms: FarmModel[];
	selectedFarm: FarmModel;
	filter: string = '';
	filteredFarms: FarmModel[];
	hasToSelectFarm: string;

	constructor(
		private farmProvider: FarmProvider,
		private farmService: FarmService,
		private router: Router,
		private route: ActivatedRoute,
		private location: Location
	) {}

	async ngOnInit(): Promise<void> {
		this.hasToSelectFarm = this.route.snapshot.queryParamMap.get('redirect');
		const farms = (await this.farmProvider.getAll()).data;
		this.farms = farms.sort((f1, f2) => (f1.name >= f2.name ? 1 : -1));
		this.onFilterFarms();
	}

	onFilterFarms() {
		this.filteredFarms = this.farms.filter(farm =>
			farm.name.toLowerCase().includes(this.filter.toLowerCase())
		);
	}

	async onSelectFarm(farm?: FarmModel) {
		try {
			this.selectedFarm = farm;
			if (farm) {
				await this.farmService.setCurrentFarm(farm._id);
			} else {
				await this.farmService.setCurrentFarm();
			}
			const nextRoute = this.router.navigated ? this.location.back() : this.router.navigateByUrl('/');
		} catch (e) {
			console.error(e);
		}
	}
}
