<div class="page">
	<div class="submod">
		<div class="submod-column" *ngFor="let submodule of actions">
			<h3>{{ submodule.label | titlecase }} </h3>
			<app-action-button *ngFor="let action of submodule.actions" [text]="action.label"
				[fav]="actionIsFav(action)" (toggle)="updateActions(action, $event)"
				[link]="(addFavourites) ? null : action.link">
			</app-action-button>
		</div>
	</div>
</div>