import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-import-summary',
	templateUrl: './import-summary.component.html',
	styleUrls: ['./import-summary.component.scss'],
})
export class ImportSummaryComponent implements OnInit {
	errorTitles: string[];
	noErrors: any;
	success: any;
	type: string;
	warningTitles: string[];
	noWarnings: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Record<string, Record<string, unknown>>,
		public dialogRef: MatDialogRef<ImportSummaryComponent>
	) {}

	ngOnInit(): void {
		this.errorTitles = Object.keys(this.data.data.errors || {}).filter(
			key => (this.data.data.errors[key] as unknown[]).length > 0
		);
		this.noErrors = Object.values(this.data.data.errors || {}).every((error: any[]) => error.length === 0);

		this.warningTitles = Object.keys(this.data.data.warnings || {}).filter(
			key => (this.data.data.warnings[key] as unknown[]).length > 0
		);
		this.noWarnings = Object.values(this.data.data.warnings || {}).every((w: any[]) => w.length === 0);

		this.success = this.data.data.success as Record<string, unknown>[];
	}

	onExportDetails(): void {
		this.dialogRef.close({ result_data: 'export' });
	}

	onClose(resultData: boolean): void {
		this.dialogRef.close({ result_data: resultData });
	}

	parseErrors(errors: Record<string, string>[] = []): string {
		const errorArray: string[] = errors.map(obj => Object.values(obj)[0]);
		return errorArray ? errorArray.join(', ') : '';
	}
}
