import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProcessLinkerService } from 'src/app/services/process-linker.service';

interface SubLink {
	label: string;
	link: string;
	queryParams?: object;
	auth?: string;
}
@Component({
	selector: 'app-sub-navbar',
	templateUrl: './sub-navbar.component.html',
	styleUrls: ['./sub-navbar.component.scss'],
})
export class SubNavbarComponent implements OnInit {
	modulesLinks = [
		{
			label: 'módulos',
			link: '/modules',
			auth: 'Modules',
		},
	];

	proposalLinks = [
		{ label: 'Propuestas', link: '/proposals', auth: 'Proposal' },
		{ label: 'Animales', link: '/proposals', queryParams: { entityName: 'animals' }, auth: 'ProposalAnimal' },
		{ label: 'Ganaderías', link: '/proposals', queryParams: { entityName: 'farms' }, auth: 'ProposalFarm' },
		{ label: 'Fincas', link: '/proposals', queryParams: { entityName: 'ranchs' }, auth: 'ProposalRanch' },
		{
			label: 'Cubriciones',
			link: '/proposals',
			queryParams: { entityName: 'breeds', type: 'batch' },
			auth: 'ProposalBreed',
		},
		{
			label: 'Inseminaciones',
			link: '/proposals',
			queryParams: { entityName: 'breeds', type: 'artificial' },
			auth: 'ProposalBreed',
		},
		{ label: 'Partos', link: '/proposals', queryParams: { entityName: 'births' }, auth: 'ProposalBirth' },
		{
			label: 'Pruebas ADN',
			link: '/proposals',
			queryParams: { entityName: 'analyses' },
			auth: 'ProposalAnalysis',
		},
		{
			label: 'Transferencias',
			link: '/proposals',
			queryParams: { entityName: 'transferences' },
			auth: 'ProposalTransference',
		},
	];

	fileLinks = [
		{ label: 'Ficheros', link: '/files', auth: 'Files' },
		{ label: 'Animales', link: '/files', queryParams: { entityName: 'animals' }, auth: 'FileAnimal' },
		{ label: 'Pruebas ADN', link: '/files', queryParams: { entityName: 'analysis' }, auth: 'FileAnalysis' },
	];

	bookLinks = [
		{
			label: '',
			link: '/',
			auth: 'Book',
		},
		{
			label: 'animales',
			link: '/book/animals',
			auth: 'BookAnimal',
		},
		{
			label: 'ganaderías',
			link: '/book/farms',
			queryParams: { active: true },
			auth: 'BookFarm',
		},
		{
			label: 'fincas',
			link: '/book/ranches',
			auth: 'BookRanch',
		},
		{
			label: 'inseminaciones',
			link: '/book/breedings',
			queryParams: { type: 'artificial' },
			auth: 'BookBreedArtificial',
		},
		{
			label: 'cubriciones',
			link: '/book/breedings',
			queryParams: { type: 'batch' },
			auth: 'BookBreedBatch',
		},
		{
			label: 'partos',
			link: '/book/births',
			auth: 'BookBirth',
		},
	];

	sublinks: SubLink[];

	morfologicLinks = [
		{
			label: '',
			link: '/',
			auth: 'Morfologic',
		},
		{
			label: 'calificaciones',
			link: '/morfologic/qualifications',
			auth: 'MorfologicQualification',
		},
		{
			label: 'formularios',
			link: '/morfologic/forms',
			auth: 'MorfologicForm',
		},
	];

	dnaLinks = [
		{
			label: '',
			link: '/',
			auth: 'Dna',
		},
		{
			label: 'pruebas',
			link: '/dna/analysis',
			auth: 'DnaAnalysis',
		},
		{
			label: 'banco',
			link: '/dna/bank',
			auth: 'DnaBank',
		},
		{
			label: 'animales',
			link: '/dna/animals',
			auth: 'DnaAnimal',
		},
		{
			label: 'muestras',
			link: '/dna/samples',
			auth: 'DnaSample',
		},
	];

	constructor(private router: Router, private processLinker: ProcessLinkerService) {}

	ngOnInit(): void {
		this.setSublinks(this.router.url);

		this.router.events
			.pipe(
				filter(
					event =>
						event instanceof NavigationEnd ||
						event instanceof NavigationCancel ||
						event instanceof NavigationError
				)
			)
			.subscribe(({ url }: NavigationEnd | NavigationCancel | NavigationError) => {
				this.setSublinks(url);
			});
	}

	setSublinks(currentUrl: string): void {
		switch (true) {
			case currentUrl.includes('modules'):
				this.sublinks = this.modulesLinks;
				break;
			case currentUrl.includes('book'):
				this.sublinks = this.bookLinks;
				break;
			case currentUrl.includes('proposals'):
				this.sublinks = this.proposalLinks;
				break;
			case currentUrl.includes('files'):
				this.sublinks = this.fileLinks;
				break;
			case currentUrl.includes('morfologic'):
				this.sublinks = this.morfologicLinks;
				break;
			case currentUrl.includes('dna'):
				this.sublinks = this.dnaLinks;
				break;
			default:
				this.sublinks = [];
				break;
		}
	}

	removeProcessLinker(): void {
		if (this.sublinks === this.bookLinks) {
			this.processLinker.clearRouteLinkerData();
		}
	}
}
