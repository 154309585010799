import { Injectable } from '@angular/core';
import { SpanishLanguageElements } from '../constants/spanish-elements.constant';

@Injectable({
	providedIn: 'root',
})
export class StringUtilsService {
	constructor() {}

	capitalize(string: string): string {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * Remove accents and diacritics from string
	 * @param string
	 * @returns
	 */
	normalize(string: string): string {
		return string
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.trim();
	}

	normalizeAndToLowerCase(string: string): string {
		return this.normalize(string).toLowerCase();
	}

	/**
	 * Given a string of compound by two or more substrings, method will return a string with each substring capitalized.
	 * @param string
	 * @param allWords When true is given, method will capitalize each substring of the given string,
	 * including language elements such as prepositions, arcticles and contractions (connectors).
	 * @returns
	 */
	capitalizeSpanishName(string: string, allWords = false): string {
		const arrayOfWords = string.toLowerCase().split(' ');
		return arrayOfWords
			.map((word, index) => {
				const doNotCapitalize = SpanishLanguageElements.global.includes(word) && index > 0 && !allWords;
				if (doNotCapitalize) return word;
				return this.capitalize(word);
			})
			.join(' ');
	}

	/**
	 * Remove prefix +34 from string
	 * @param string
	 * @returns
	 */
	normalizePhoneES(string: string): string {
		if (string.includes('+34')) return string.replace('+34', '');
		return string;
	}
}
