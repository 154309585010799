import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Color, Fill, Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import { take } from 'rxjs/operators';
import excelMetaJson from 'src/assets/locale/excel-data.json';
import { ExcelData, ExcelMeta } from '../schemes/models/excel-data.model';
import { ImportErrorsPipe } from '../shared/pipes/import-errors.pipe';
import { TimeService } from './time.service';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root',
})
export class ExcelService {
	private excelMetadata: ExcelData = JSON.parse(JSON.stringify(excelMetaJson)) as ExcelData;

	constructor(private errorsPipe: ImportErrorsPipe, private utils: UtilsService, private http: HttpClient) {}

	public getExcelMeta(key: string): { [key: string]: ExcelMeta } {
		return Object.hasOwnProperty.call(this.excelMetadata, key) ? this.excelMetadata[key] : {};
	}

	public saveExcelFile(buffer: BlobPart, fileName: string): void {
		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE,
		});
		FileSaver.saveAs(
			data,
			(fileName || `Redil-${TimeService.getActualDate().toLocaleDateString('es-ES')}`) + EXCEL_EXTENSION
		);
	}

	public async generateExcel(
		excelSheets: any[],
		metadata: ExcelMeta[],
		filename: string,
		headers?: {
			header: string;
			key: string;
			width: number;
		}[]
	): Promise<void> {
		const wb = this.objectToExcel(excelSheets, metadata, headers);
		const buffer = await wb.xlsx.writeBuffer();
		this.saveExcelFile(
			buffer,
			`${filename}_${TimeService.getActualDate().toLocaleDateString('es-ES')}` || metadata[0]?.label
		);
	}

	public objectToExcel(
		excelSheets: Record<string, string>[][],
		metadata: ExcelMeta[],
		headers?: {
			header: string;
			key: string;
			width: number;
		}[]
	): Workbook {
		const workbook = new Workbook();
		let i = -1;
		for (const data of excelSheets) {
			if (!data || data.length === 0) {
				continue;
			}
			i++;
			const excelMeta: ExcelMeta = metadata[i] || {
				wsName: `Hoja ${i + 1}`,
				headers: Object.keys(data[0]).map(header => ({ header, key: header, width: 27 })),
				filename: '',
				label: '',
				style: {
					cellBGColor: {
						argb: 'FFFFFFF',
					},
					cellFontColor: {},
					headerBGColor: {
						argb: '4F818D',
					},
					headerFontColor: {
						argb: 'FFFFFFF',
					},
				},
			};
			if (headers) {
				excelMeta.headers = headers;
			}
			const worksheet = workbook.addWorksheet(excelMeta.wsName);
			worksheet.views = [{ state: 'frozen', xSplit: undefined, ySplit: 1, topLeftCell: 'A2' }];
			worksheet.columns = excelMeta.headers;
			const sheetRows = this.getSheetRows(data);

			worksheet.addRows(sheetRows);
			worksheet.eachRow({ includeEmpty: true }, row => {
				row.eachCell({ includeEmpty: true }, cell => {
					cell.font = {
						name: 'Calibri',
						family: 2,
						bold: false,
						size: 12,
						color: this.setFontColor(cell.text, excelMeta.style.cellFontColor),
					};

					cell.alignment = {
						vertical: 'middle',
						horizontal: 'center',
					};

					cell.border = {
						top: { style: 'thin' },
						left: { style: 'thin' },
						bottom: { style: 'thin' },
						right: { style: 'thin' },
					};

					cell.fill = this.setFill(cell.text, excelMeta, 'cell');
				});
			});

			const headerRow = worksheet.getRow(1);

			headerRow.height = 18;
			headerRow.eachCell(cell => {
				cell.font = {
					name: 'Calibri',
					bold: true,
					size: 12,
					color: this.setFontColor(cell.text, excelMeta.style.headerFontColor),
				};
				cell.fill = this.setFill(cell.text, excelMeta, 'header');
				cell.font = {
					bold: true,
					color: this.setFontColor(cell.text, excelMeta.style.headerFontColor),
				};
			});
		}

		return workbook;
	}

	async getExcelModel(model: 'zoo'): Promise<Workbook> {
		switch (model) {
			case 'zoo':
				return this.readModel('src/assets/excel-templates/Modelo_zootecnico.xlsx');
			default:
				return new Workbook();
		}
	}

	async readModel(path: string): Promise<Workbook> {
		try {
			const buffer = await this.http
				.get('../assets/excel-templates/Modelo_zootecnico.xlsx', { responseType: 'arraybuffer' })
				.pipe(take(1))
				.toPromise();
			const loadedWorkbook = new Workbook();
			return loadedWorkbook.xlsx.load(buffer);
		} catch (error) {
			console.error(error);
			return new Workbook();
		}
	}

	private setFontColor(text: string, cellFontColor: {}): Partial<Color> {
		if (text.includes('{') && text.includes('}')) {
			return { argb: 'FFFFFF' };
		}
		if (text.includes('[') && text.includes(']')) {
			return { argb: 'FFFFFF' };
		}
		return cellFontColor;
	}

	private setFill(text: string, excelMeta: ExcelMeta, type: string): Fill {
		if (type === 'cell') {
			if (text.includes('{') && text.includes('}')) {
				return {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'DB4437' },
				};
			}
			if (text.includes('[') && text.includes(']')) {
				return {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'F08F2E' },
				};
			}
			return {
				type: 'pattern',
				pattern: 'none',
				fgColor: { argb: 'FFFFFF' },
			};
		}

		if (type === 'header') {
			return {
				type: 'pattern',
				pattern: 'solid',
				fgColor: excelMeta.style.headerBGColor,
			};
		}
	}

	private getSheetRows(excelData: Record<string, string>[]): any[] {
		return excelData.map(row =>
			Object.entries(row || {}).reduce(
				(newRow, [key, value]) => ({
					...newRow,
					[key]: value == null ? '' : this.parseRowData(`${value}`),
				}),
				{}
			)
		);
	}

	private parseRowData(value: string): string {
		return this.errorsPipe.replace(value);
	}
}
