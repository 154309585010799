<h2>Filtrar Ganaderías</h2>
<div class="redil-info">
	<div class="body">
		<section>
			<div class="info">

				<div class="info-row">
					<label for="input-id">Nombre</label>
					<input id="input-id" class="ui-inputtext" type="text" [(ngModel)]="filters.name" pInputText>
				</div>

				<div class="info-row">
					<label for="input-races">Raza</label>
					<p-multiSelect defaultLabel="Raza" maxSelectedLabels="2" selectedItemsLabel="{0} Seleccionados"
						[options]="races" [(ngModel)]="filters.races" optionLabel="name">
					</p-multiSelect>
				</div>

				<div class="info-row">
					<label for="">Solo ganaderias activas</label>
					<div class="checkbox-cnt">
						<input type="checkbox" [(ngModel)]="active">
					</div>
				</div>

				<div class="info-row">
					<label for="input-headline">Titular</label>
					<input id="input-headline" class="ui-inputtext" type="text" [(ngModel)]="filters.owner" pInputText>
				</div>

				<div class="info-row">
					<label for="input-mobilephone">Tel. Móvil</label>
					<input id="input-mobilephone" class="ui-inputtext" type="text" [(ngModel)]="filters.mobilephone"
						pInputText>
				</div>

			</div>
		</section>
		<div class="centered">
			<button (click)="onSaveFilters()" class="app-btn orange">Guardar Filtros</button>
			<button (click)="onClearFilters()" class="app-btn primary">Borrar Filtros</button>
		</div>
	</div>
</div>