<main class="centered">
	<p class="title">¿Con qué asociación desea conectarse?</p>
	<div class="centered">
		<div
			class="app-select-btn"
			*ngFor="let association of associations"
			(click)="selectAssociation(association)"
		>
			<p>
				{{
					association.acronym ? (association.acronym | uppercase) : (association.name | titlecase)
				}}
			</p>
		</div>
	</div>
</main>
