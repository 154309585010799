import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { subject } from '@casl/ability';
import { User } from 'firebase';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { RoleEnum } from 'src/app/schemes/enums/role.enum';
import { AssociationModel } from 'src/app/schemes/models/association.model';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AssociationService } from 'src/app/services/association.service';
import { AuthService } from 'src/app/services/auth.service';
import { FarmService } from 'src/app/services/farm.service';
import { FileService } from 'src/app/services/file.service';
import { ModulePipe } from 'src/app/shared/pipes/module-enum.pipe';
import { AppAbility } from '../../shared/app-abilities';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	items: MenuItem[];
	displayActions = false;
	displayedUserOptions = false;
	farm: FarmModel;
	role: RoleEnum;
	user: UserModel;
	roleEnum = RoleEnum;
	navItems = [];
	userOptions: { label: string; link: string; badge?: true }[] = [];

	countProposals: number = 0;
	countFiles: number = 0;

	association: AssociationModel;

	subscription: Subscription;

	constructor(
		private associationService: AssociationService,
		private modulePipe: ModulePipe,
		private router: Router,
		private authService: AuthService,
		private farmService: FarmService,
		private fileService: FileService,
		private ability: AppAbility
	) {}

	async ngOnInit(): Promise<void> {
		this.user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
		this.role = this.user.role;
		this.farm = await this.farmService.getCurrentFarm();
		this.association = await this.associationService.getCurrentAssociation();
		this.fileService.getUserFiles$('', this.association._id).subscribe(files => {
			this.countFiles = files.filter(
				file => file.association === this.association._id && file.status === 'success' && !file.downloaded
			).length;
		});

		this.fileService.getUserProporsals$().subscribe(files => {
			if (this.ability.can('manage', 'Proposal', this.user.associations.toString())) {
				this.countProposals = files.filter(
					file => file.association === this.association._id && file.read === false
				).length;
			}
		});

		this.setNavLinks(this.association);

		this.setUserOptions();
	}

	countTotalBadge(): string {
		return (this.countProposals + this.countFiles).toString();
	}

	getCountBadge(label): string {
		switch (label) {
			case 'ficheros':
				return this.countFiles.toString();
			case 'propuestas':
				return this.countProposals.toString();
			default:
				break;
		}
	}

	getCountBadgeIsHidden(label): boolean {
		switch (label) {
			case 'ficheros':
				return this.countFiles <= 0;
			case 'propuestas':
				return this.countProposals <= 0;
			default:
				break;
		}
	}

	isBadgeHidden(): boolean {
		return this.countProposals + this.countFiles <= 0;
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	onDisplayUserOptions(): void {
		this.trigger.openMenu();
		this.displayedUserOptions = !this.displayedUserOptions;
	}

	navigateTo(link): void {
		this.router.navigateByUrl(link);
	}

	async onLogout(): Promise<void> {
		localStorage.clear();
		await this.associationService.setCurrentAssociation();
		await this.farmService.setCurrentFarm();
		await this.authService.logout();
		location.reload();
	}

	reloadWindowWithoutCache(): void {
		location.reload();
	}

	private setNavLinks(association): void {
		if (association) {
			association.modules.forEach(module => {
				this.navItems.push({
					label: this.modulePipe.transform(module),
					routerLink: `/${module}`,
				});
			});
		} else {
			this.navItems.push({
				label: 'Seleccionar Asociación',
				routerLink: 'select-association',
			});
		}
	}

	private setUserOptions(): void {
		this.authService.getCurrentUser().subscribe(user => {
			const userSubject = subject('UserModel', user);
			if (this.ability.can('read', 'Self')) {
				this.userOptions.push({
					label: 'mi perfil',
					link: '/profile',
				});
			}
			if (this.ability.can('read', 'BookFarm')) {
				this.userOptions.push({
					label: 'mi ganadería',
					link: '/book/farms',
				});
			}
			if (this.ability.can('read', userSubject, user.associations.toString())) {
				this.userOptions.push({
					label: 'usuarios',
					link: '/users',
				});
			}
			if (this.ability.can('manage', 'Documents')) {
				this.userOptions.push({
					label: 'Documentos',
					link: '/documents',
				});
			}
			if (this.ability.can('manage', 'Modules', user._id)) {
				this.userOptions.push({
					label: 'módulos',
					link: '/modules',
				});
			}
			if (this.ability.can('manage', 'Proposal', user.associations.toString())) {
				this.userOptions.push({
					label: 'propuestas',
					link: '/proposals',
					badge: true,
				});
			}
			if (this.ability.can('manage', 'Files')) {
				this.userOptions.push({
					label: 'ficheros',
					link: '/files',
					badge: true,
				});
			}
			if (this.ability.can('update', 'AssociationModel')) {
				this.userOptions.push({
					label: 'asociaciones',
					link: '/associations',
				});
			}
			if (this.ability.can('read', 'AssociationModel')) {
				this.userOptions.push({
					label: 'seleccionar asociación',
					link: '/select-association',
				});
			}
			if (this.ability.can('read', 'BookFarm')) {
				this.userOptions.push({
					label: 'seleccionar ganadería',
					link: '/select-farm',
				});
			}
		});
	}
}
