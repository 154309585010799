import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-calculate-genbook-options',
	templateUrl: './calculate-genbook-options.component.html',
	styleUrls: ['./calculate-genbook-options.component.scss'],
})
export class CalculateGenbookOptionsComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<CalculateGenbookOptionsComponent>
	) {}

	ngOnInit(): void {}

	closeDialog(mode: 'all' | 'filters' | null) {
		this.dialogRef.close(mode);
	}
}
