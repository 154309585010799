import { Ability, AbilityClass, InferSubjects } from '@casl/ability';
import { AssociationModel } from '../schemes/models/association.model';
import { UserModel } from '../schemes/models/user.model';

type Actions =
	| 'view'
	| 'create'
	| 'read'
	| 'update'
	| 'delete'
	| 'request'
	| 'request-locate'
	| 'request-transfer'
	| 'request-create'
	| 'request-update'
	| 'request-delete'
	| 'download'
	| 'export'
	| 'import'
	| 'locate'
	| 'block'
	| 'unblock'
	| 'calculate'
	| 'calculateAll'
	| 'qualify'
	| 'transfer'
	| 'manage'
	| 'unique-update';

type Models = UserModel | AssociationModel;

// NAVIGATIONS PERMISSIONS TYPES
// Navigation Proposal Subjects
type NavigationProposal =
	| 'Proposal'
	| 'ProposalAnimal'
	| 'ProposalFarm'
	| 'ProposalRanch'
	| 'ProposalBreed'
	| 'ProposalBirth'
	| 'ProposalAnalysis'
	| 'ProposalTransference';

// Navigation File Subjects
type NavigationFile = 'Files' | 'FileAnimal' | 'FileAnalysis';

// Navigation Book Subjects
type NavigationBook =
	| 'Book'
	| 'BookAnimal'
	| 'BookFarm'
	| 'BookRanch'
	| 'BookBreedArtificial'
	| 'BookBreedBatch'
	| 'BookBirth';

// Navigation Morfologic Subjects
type NavigationMorfologic = 'Morfologic' | 'MorfologicQualification' | 'MorfologicForm';

// Navigation Dna Subjects
type NavigationDna = 'Dna' | 'DnaAnalysis' | 'DnaBank' | 'DnaAnimal' | 'DnaSample';

// BOOK PERMISSIONS TYPES
type Book = BookAnimal | BookBreed;
type BookAnimal = 'Arca' | 'Census' | 'Zootechnical' | 'BirthAssociation' | 'AnimalHistorical';
type BookBreed = 'BookBreed';

// Load All Subjects
type Subjects =
	| InferSubjects<Models, true>
	| NavigationProposal
	| NavigationFile
	| NavigationBook
	| NavigationMorfologic
	| NavigationDna
	| Book
	| 'Modules'
	| 'Self'
	| 'all'
	| 'Documents';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
