<div class="container">
	<div class="text-wrapper">
		<h1 class="title">Oops!</h1>
		<p class="body">
			Nos hemos liado y ahora no logramos encontrar la página que estás buscando… ¿Se habrá escapado?
		</p>
		<button class="cta-btn" (click)="goBack()">{{returnHome ? 'Volver al inicio' : 'Volver atrás'}}</button>
	</div>
	<div class="img-wrapper">
		<img src="assets/images/404-error-icon.svg" alt="not-found-img" class="not-found-img">
	</div>
</div>