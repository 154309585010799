import { Pipe, PipeTransform } from '@angular/core';
import { BrucelosisEnum } from 'src/app/schemes/enums/ranches-inspection.enum';

@Pipe({
	name: 'brucelosisPipe',
})
export class BrucelosisPipe implements PipeTransform {
	transform(inspection: BrucelosisEnum, args?: any): any {
		switch (inspection) {
			case BrucelosisEnum.no: return 'no aplica';
			case BrucelosisEnum.suspended: return 'calificación suspendida';
			case BrucelosisEnum.positive: return 'm2 positiva';
			case BrucelosisEnum.negative: return 'm2 negativa';
			case BrucelosisEnum.m3: return 'm3';
			case BrucelosisEnum.m4: return 'm4';
			default: return '';
		}
	}
}
