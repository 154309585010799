import { AnalysisStatusEnum } from 'src/app/schemes/enums/analysis.enum';
import { TitleCasePipe } from '@angular/common';
import ngPrimeLocale from 'src/assets/locale/ngprime.json';
import { Component, Inject, OnInit } from '@angular/core';
import { FarmModel } from 'src/app/schemes/models/farm.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FarmProvider } from 'src/app/providers/farm.provider';
import { AnalysisTypeEnum } from '../../../../schemes/enums/analysis.enum';
import { AnimalProvider } from '../../../../providers/animal.provider';
import { ResponseModel } from '../../../../schemes/models/response.model';
import { AnimalModel } from '../../../../schemes/models/animal.model';
import { AnalysisPipe } from '../../../../shared/pipes/analysis.pipe';
import { TimeService } from '../../../../services/time.service';

@Component({
	selector: 'app-filter-analyses-modal',
	templateUrl: './filter-analyses-modal.component.html',
	styleUrls: ['./filter-analyses-modal.component.scss'],
})
export class FilterAnalysesModalComponent implements OnInit {
	currentPage = '/dna/analysis';
	farms: { name: string; value: string }[];
	filteredFarms: { name: string; value: string }[];
	indexedFarms: { [key: string]: string } = {};
	animals: { name: string; value: string }[];
	filteredAnimals: { name: string; value: string }[];
	indexedAnimals: { [key: string]: string } = {};
	selectedOwnerFarm: any;
	selectedBreederFarm: any;
	// ranchs: RanchModel[];
	es = ngPrimeLocale;
	filters;
	types: { name: string; value: AnalysisTypeEnum }[] = [];
	status: { name: string; value: AnalysisStatusEnum }[] = [];
	originalFilters: any;

	constructor(
		private farmProvider: FarmProvider,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterAnalysesModalComponent>,
		private animalProvider: AnimalProvider,
		private analysisPipe: AnalysisPipe,
		private titleCase: TitleCasePipe,
	) {
		this.filters = {
			animal: '',
			type: null,
			farm: null,
			date: {
				start: null,
				end: null,
			},
			status: null,
		};
	}

	async ngOnInit() {
		try {
			this.types = Object.entries(AnalysisTypeEnum)
				.map(([key, value]) => ({
					name: this.titleCase.transform(this.analysisPipe.transform(value) as string),
					value,
				}))
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			this.status = Object.entries(AnalysisStatusEnum)
				.map(([key, value]) => ({
					name: this.titleCase.transform(this.analysisPipe.transform(value) as string),
					value,
				}))
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			const farms = (await this.farmProvider.getAll({ _show: 'name' })).data;
			this.farms = farms
				.map(farm => ({ name: farm.name, value: farm._id }))
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			await this.setFiltersValues(this.data);
		} catch (error) {
			console.error(error);
		}
	}

	async onSaveFilters() {
		const filtersResult: { [key: string]: any } = {};
		filtersResult.farm = this.filters.farm ? { value: this.filters.farm._id } : null;
		if (this.filters.animal) {
			filtersResult.animal = this.filters.animal;
		}
		if (this.filters.farm) {
			filtersResult.farm = this.filters.farm.value;
		}
		if (this.filters.type) {
			filtersResult.type = this.filters.type.value;
		}
		if (this.filters.status) {
			filtersResult.status = this.filters.status.value;
		}
		if (this.filters.date) {
			if (this.filters.date.start) filtersResult.date_gte = this.filters.date.start;
			if (this.filters.date.end) {
				filtersResult.date_lte = this.filters.date.end;
				filtersResult.date_lte as Date;
			}
		}
		this.onCloseDialog(filtersResult);
	}

	onCloseDialog(queryParams) {
		this.dialogRef.close(queryParams);
		queryParams ? this.router.navigate([this.currentPage], { queryParams }) : this.router.navigate([this.currentPage]);
	}

	// Parses start and end dates, value on object types ($eq) and ($like) on string types
	parseFilters(filters: Object) {
		return Object.entries(filters).reduce((newFilters, [key, value]) => {
			if (value !== false && !value) {
				return newFilters;
			}
			return newFilters;
		}, {});
	}

	async setFiltersValues(data) {
		this.originalFilters = JSON.parse(JSON.stringify(data.filters));
		for (const key of Object.keys(data.filters)) {
			const keys = key.split('_');
			if (keys.length == 1) {
				if (key === 'type') this.filters[keys[0]] = this.types.find(type => type.value === data.filters.type);
				else if (key == 'farm') this.filters.farm = this.farms.find(farm => farm.value == data.filters.farm);
				else if (key == 'animal') {
					this.filters.animal = data.filters.animal || '';
				} else if (key == 'status') {
					this.filters.status = this.status.find(status => status.value === data.filters.status);
				}
			} else if (keys.length == 2) {
				if (keys[1] == 'gte') this.filters[keys[0]].start = TimeService.parseDate(data.filters[key]);
				else if (keys[1] == 'lte') this.filters[keys[0]].end = TimeService.parseDate(data.filters[key]);
				else if (keys[0] != '') this.filters[keys[0]] = data.filters[key];
			}
		}
	}

	onClearFilters() {
		this.filters = {
			animal: '',
			type: null,
			date: {
				start: null,
				end: null,
			},
			status: null,
		};
	}
}
