<h2>Filtrar Pruebas</h2>
<div class="redil-info" *ngIf="farms">
	<div class="body">
		<section>
			<div class="info">
				<div class="info-row">
					<label for="input-name">Animal</label>
					<input id="input-name" class="ui-inputtext" type="text" [(ngModel)]="filters.animal" pInputText />
				</div>
				<div class="info-row">
					<label for="input-gender">Ganadería</label>
					<p-dropdown [options]="farms" [(ngModel)]="filters.farm" placeholder="Selecciona una opción"
						optionLabel="name" [style]="{ width: '100%' }" [appendTo]="'body'"></p-dropdown>
				</div>
				<div class="info-row">
					<label for="input-gender">Tipo</label>
					<p-dropdown [options]="types" [(ngModel)]="filters.type" placeholder="Selecciona una opción"
						optionLabel="name" [appendTo]="'body'" [style]="{ width: '100%' }"></p-dropdown>
				</div>
				<div class="info-row">
					<label for="input-gender">Estado</label>
					<p-dropdown [options]="status" [(ngModel)]="filters.status" placeholder="Selecciona una opción"
						optionLabel="name" [appendTo]="'body'" [style]="{ width: '100%' }"></p-dropdown>
				</div>

				<div class="info-row">
					<label for="drop-date">Fecha de creación</label>
					<p>Desde:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.date.start" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'">
					</p-calendar>
					<p>Hasta:</p>
					<p-calendar id="birth-date" appUseUtc [(ngModel)]="filters.date.end" [locale]="es.calendar_es"
						dateFormat="dd/mm/yy" [showIcon]="true" [appendTo]="'body'" [placeholder]="'24/06/1998'">
					</p-calendar>
				</div>
			</div>
		</section>
	</div>
	<div class="centered">
		<button (click)="onSaveFilters()" class="app-btn orange">Aceptar</button>
		<button (click)="onClearFilters()" class="app-btn primary">Borrar filtros</button>
	</div>
</div>