import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

/**
 * ! THIS GUARD IS IRRELEVANT
 * The guard is meant to protect execution order of the guards but Angular does this natively.
 * */

@Injectable({
	providedIn: 'root',
})
export class CompositeGuard implements CanActivate {
	constructor(private injector: Injector) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const compositeCanActivate = true;
		const { routeGuards } = route.data;

		if (routeGuards) {
			for (const guard of routeGuards) {
				const routeGuard = this.injector.get(guard);
				const canActivateRes = await routeGuard.canActivate(route, state);
				if (!canActivateRes) {
					return canActivateRes;
				}
			}
		}
		return true;
	}

	async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const compositeCanActivate = true;
		const { routeGuards } = route.data;

		if (routeGuards) {
			for (const guard of routeGuards) {
				const routeGuard = this.injector.get(guard);
				const canActivateRes = await routeGuard.canActivate(route, state);
				if (!canActivateRes) {
					return canActivateRes;
				}
			}
		}
		return true;
	}
}
