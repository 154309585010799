import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'validationData',
})
export class ValidationDataPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		if (value.includes('.length')) {
			value = value.replace('.length', '');
			return `la longitud de ${value}`;
		}

		switch (value) {
			case '===': return 'igual';
			case '>=': return 'mayor o igual';
			case '<=': return 'menor o igual';
			case '<': return 'menor';
			case '>': return 'mayor';
		}
	}
}
